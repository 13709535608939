import styled, { css } from 'styled-components'
import Button from '../Button/Button'
import { DocumentIcon } from '../../design-tokens/icons'

export const FileList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const StyledFile = styled.li<{ isDisabled: boolean }>`
  ${({
    theme: {
      xyz: { color },
    },
    isDisabled,
  }) => css`
    border-bottom: 0.0625rem solid ${color.neutralGray5};
    color: ${isDisabled ? color.neutralGray5 : color.neutralNetworkGray};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0.15625rem 0;
    word-wrap: anywhere;
  `}
`

export const StyledButton = styled(Button)`
  ${({
    theme: {
      xyz: { color },
    },
  }) => css`
    flex-shrink: 0;
    color: ${color.neutralIconGray};
  `}
`

export const StyledDocumentIcon = styled(DocumentIcon)`
  ${({
    theme: {
      xyz: { iconSize, spacing },
    },
  }) => css`
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
    margin-right: ${spacing.space2}rem;
  `}
`
