import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Circle, SVG } from '../icons.styled'

export const DotsVerticalIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Circle cx="12" cy="5" r="2" transform="rotate(90 12 5)" fill={color} />
    <Circle cx="12" cy="12" r="2" transform="rotate(90 12 12)" fill={color} />
    <Circle cx="12" cy="19" r="2" transform="rotate(90 12 19)" fill={color} />
  </SVG>
)

DotsVerticalIcon.propTypes = defaultIconPropTypes

DotsVerticalIcon.defaultProps = defaultPictographIconProps
