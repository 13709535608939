import styled from 'styled-components'
import { IconProps, InvertibleColorScheme } from './icons.types'

export const SVG = styled.svg.attrs(({ viewBox, focusable }) => ({
  viewBox: viewBox || '0 0 24 24',
  focusable: focusable || false,
}))``

export const InboxIconSvg = styled.svg.attrs<{ size: string }>(({ viewBox, focusable, size }) => ({
  viewBox: '0 0 32 42',
  focusable: focusable || false,

  /**
   * Fixed width and height are intended to keep aspect ratio of icon
   */
  width: '1em',
  height: '1.3125em',
  /**
   * So we change only fontSize to update size of icon
   */
  style: {
    fontSize: size,
  },
}))<{ size: string }>``

export const LogoSVG = styled.svg.attrs(({ viewBox, focusable }) => ({
  viewBox: viewBox || '0 0 48 64',
  focusable: focusable || false,
}))``

export const LargeSVG = styled.svg.attrs(({ viewBox, focusable }) => ({
  viewBox: viewBox || '0 0 70 70',
  focusable: focusable || false,
}))``

export const Path = styled.path.attrs(
  ({
    theme: {
      xyz: { color },
    },
    fill,
    stroke,
    fillRule = 'evenodd',
    clipRule = 'evenodd',
  }) => ({
    fill: color[fill] || fill,
    stroke: color[stroke] || stroke,
    fillRule,
    clipRule,
  })
)``

export const G = styled.g.attrs(
  ({
    theme: {
      xyz: { color },
    },
    fill,
  }) => ({
    fill: color[fill] || fill,
  })
)``

export const Rect = styled.rect.attrs(
  ({
    theme: {
      xyz: { color },
    },
    fill,
  }) => ({
    fill: color[fill] || fill,
  })
)``

export const Circle = styled.circle.attrs(
  ({
    theme: {
      xyz: { color },
    },
    fill,
  }) => ({
    fill: color[fill] || fill,
  })
)``

export const Line = styled.line.attrs(
  ({
    theme: {
      xyz: { color },
    },
    stroke,
  }) => ({
    stroke: color[stroke] || stroke,
  })
)``

export const Text = styled.text.attrs(
  ({
    theme: {
      xyz: { color },
    },
    fill,
  }) => ({
    fill: color[fill] || fill,
  })
)``

export const iconAttrs = ({
  theme: {
    xyz: { iconSize, color },
  },
  disabled,
}: IconProps): Record<string, unknown> => ({
  width: `${iconSize.s}em`,
  height: `${iconSize.s}em`,
  'aria-hidden': true,
  color: disabled ? color.neutralPassiveGray : color.neutralIconGray,
})

export function useInvertedColors(colorScheme: InvertibleColorScheme, isInverted: boolean): InvertibleColorScheme {
  return {
    primary: isInverted ? colorScheme.secondary : colorScheme.primary,
    secondary: isInverted ? colorScheme.primary : colorScheme.secondary,
  }
}
