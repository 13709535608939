import { getWindow } from './helpers'

type PostMessage = typeof postMessage

type WindowWithOmaPostiMessageHandler = Window & {
  webkit?: {
    messageHandlers: {
      iosHandler: {
        postMessage: PostMessage
      }
    }
  }
  OmaPostiAndroid?: {
    postMessage: PostMessage
  }
}

const currentWindow = getWindow<WindowWithOmaPostiMessageHandler>()

enum Platform {
  WEB = 'WEB',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

const getPlatform = (): Platform => {
  const isIOS =
    currentWindow.webkit && currentWindow.webkit.messageHandlers && currentWindow.webkit.messageHandlers.iosHandler

  return isIOS ? Platform.IOS : currentWindow.OmaPostiAndroid ? Platform.ANDROID : Platform.WEB
}

/**
 * Send a message to OmaPosti client (iOS, Android or Web) using [PostMessage](https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage) specification.
 * The sent data should be a raw object and conform to [Structured clone algorithm](https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm)
 *
 * To see the supported messages and how to use them visit https://wiki.opuscapita.com/display/INF/Web+feature+development#Webfeaturedevelopment-MessagingAPI
 */
const postMessageToOmaPosti: PostMessage = (...args) => {
  const [message, ...rest] = args
  const platform = getPlatform()

  if (platform === Platform.IOS && currentWindow.webkit) {
    return currentWindow.webkit.messageHandlers.iosHandler.postMessage(message, ...rest)
  }

  if (platform === Platform.ANDROID && currentWindow.OmaPostiAndroid) {
    const [message, ...rest] = args
    // Android supports only primitive types through the js bridge
    const data = typeof message !== 'string' ? JSON.stringify(message) : message
    return currentWindow.OmaPostiAndroid.postMessage(data, ...rest)
  }

  // In the web we can only listen to events sent to the parent frame
  return currentWindow.parent.postMessage(message, ...rest)
}
/**
 * Redirect top-level view (top frame in the browser or web view on mobile) to a specified url.
 * Can be used when url does not allow iframe embedding, e.g. third-party payment service.
 */
const redirectTopView = (url: string): void => {
  if (getPlatform() === Platform.WEB) {
    postMessageToOmaPosti({ type: 'REDIRECT', payload: { url } }, '*')
  } else {
    // on mobile our web view is top-level already
    getWindow().location.assign(url)
  }
}

export { postMessageToOmaPosti, redirectTopView }
