import React, { useState } from 'react'

import TabsContext, { TabsContextValue } from './TabsContext'

const TabContext: React.FC = (props) => {
  const [selectedTab, setSelectedTab] = useState<string | number | boolean>('')
  const context: TabsContextValue = React.useMemo(
    () => ({
      selectedTab,
      setSelectedTab,
    }),
    [selectedTab, setSelectedTab]
  )

  return <TabsContext.Provider value={context} {...props} />
}

export default TabContext
