import React, { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'

import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { XyzTheme } from '@postidigital/posti-theme'
import { useTheme } from '../../utils/useTheme'

export interface ActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: React.ComponentType<DefaultIconProps>
  iconColor?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  pressBackgroundColor?: string
  'aria-label': string
}

const StyledButton = styled.button<Omit<ActionButtonProps, 'icon'>>`
  ${({
    theme: {
      xyz: { color, spacing },
    },
    backgroundColor,
    hoverBackgroundColor,
    pressBackgroundColor,
  }) => css`
    cursor: pointer;
    border-radius: 50%;
    border: none;
    transition: all 100ms ease-in;
    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    background-color: ${color[backgroundColor] || backgroundColor};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacing.space3}rem;

    &:hover {
      background-color: ${color[hoverBackgroundColor] || hoverBackgroundColor};
    }

    &:active {
      background-color: ${color[pressBackgroundColor] || pressBackgroundColor};
    }

    &:focus {
      outline: 0;
      filter: drop-shadow(0px 0px 3px ${color.signalBlue});
    }

    &:focus:not(:focus-visible) {
      filter: none;
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      color: ${color.neutralPassiveGray};
      background-color: ${color.neutralGray5};
    }
  `}
`

export const ActionButton: React.FC<ActionButtonProps> = ({ icon: Icon, iconColor, disabled, ...rest }) => {
  const theme = useTheme()
  return (
    <StyledButton {...rest} disabled={disabled} aria-disabled={disabled}>
      <Icon color={disabled ? theme.xyz.color.neutralPassiveGray : iconColor} />
    </StyledButton>
  )
}

ActionButton.propTypes = {
  icon: PropTypes.elementType.isRequired as Validator<React.ComponentType<DefaultIconProps>>,
  iconColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  pressBackgroundColor: PropTypes.string,
  'aria-label': PropTypes.string.isRequired,
}

ActionButton.defaultProps = {
  iconColor: XyzTheme.color.signalBlue,
  backgroundColor: XyzTheme.color.neutralWhite,
  hoverBackgroundColor: XyzTheme.color.neutralOnHoverGray,
  pressBackgroundColor: XyzTheme.color.neutralOnPressGray,
}
