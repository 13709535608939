import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const LinkedInIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M17.25 3H6.75A3.75 3.75 0 003 6.75v10.5A3.75 3.75 0 006.75 21h10.5A3.75 3.75 0 0021 17.25V6.75A3.75 3.75 0 0017.25 3zM9 17.25H6.75V9H9v8.25zm-1.13-9.2c-.72 0-1.3-.6-1.3-1.32 0-.73.58-1.33 1.3-1.33.73 0 1.32.6 1.32 1.33 0 .73-.59 1.32-1.31 1.32zM18 17.25h-2.25v-4.2c0-2.53-3-2.34-3 0v4.2H10.5V9h2.25v1.32A2.85 2.85 0 0118 12.18v5.07z"
      fill={color}
    />
  </SVG>
)

LinkedInIcon.propTypes = defaultIconPropTypes

LinkedInIcon.defaultProps = defaultPictographIconProps

export { LinkedInIcon }
