import { HTMLAttributes } from 'react'

export enum TableSize {
  lg = 'lg',
  sm = 'sm',
}

export interface TableProps extends HTMLAttributes<HTMLTableElement> {
  size?: keyof typeof TableSize
  stripes?: boolean
}
