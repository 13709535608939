import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const EditAltIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M12.36 15.04c-.19.19-.43.32-.7.38-.04.03-2.32.94-2.32.94a1.48 1.48 0 01-1.55-.31 1.42 1.42 0 01-.32-1.54s.93-2.26.96-2.3c.06-.27.19-.5.38-.7l4.5-4.46a.18.18 0 00-.13-.3H5.24a2.25 2.25 0 00-2.07 1.37 2.2 2.2 0 00-.17.85v9.8a2.2 2.2 0 001.38 2.06c.28.11.57.17.86.17h9.88a2.25 2.25 0 002.24-2.22V10.5a.18.18 0 00-.1-.17.18.18 0 00-.2.04l-4.7 4.66z"
      fill={color}
    />
    <Path
      d="M16.8 4.87l-.2-.19c-.07.04-.14.14-.19.19l-6.15 6.1-.19.2.2.18 2.3 2.3.19.18.19-.19 6.15-6.1.19-.2-.2-.18-2.3-2.3zM9.69 11.93l-.2-.19c-.05.06-.22.21-.25.3l-1.15 3.43a.27.27 0 00.2.35c.04.01.09 0 .14 0l3.46-1.15.27-.28-.17-.16-2.3-2.3zM20.45 3.53a1.95 1.95 0 00-2.7 0l-.38.39-.19.18.2.2 2.3 2.28.19.2.2-.2.38-.38a1.88 1.88 0 000-2.67z"
      fill={color}
    />
  </SVG>
)

EditAltIcon.propTypes = defaultIconPropTypes

EditAltIcon.defaultProps = defaultPictographIconProps

export { EditAltIcon }
