export const FADE_DURATION = 250
export const ANIMATION_TIME = 200

// Pass selected number as the first argument of callback function.
type RatingFunction = (n: number) => void

interface Text {
  mood: string
  thanksTitle?: string
  thanksMessage?: string
  thanksImage?: JSX.Element | Element
}

export interface FeedbackPanelProps {
  feedbackGiven?: number
  heading: string | Element | JSX.Element
  texts: Text[]
  onClick: RatingFunction
  delay?: number
  hideTexts?: boolean
  centered?: boolean
  thankYouMessage?: JSX.Element | Element | string
}

export interface FeedbackPanelSmilieProps {
  onClick: RatingFunction
  number: number
  rated: number
  text: string
  hideText?: boolean
}

export interface RatingTextProps {
  clicked: boolean
}

export interface HeadingTextProps {
  centered: boolean
}

export interface ThankYouMessageProps {
  rating: number
  title?: string
  body?: string
  image?: Element | JSX.Element
  centered: boolean
}

export interface CenteredProp {
  centered: boolean
}

export interface ItemImageContainerProps {
  clicked: boolean
}
