import styled, { css } from 'styled-components'

export const Container = styled.nav<{ zIndex: number }>(
  ({
    theme: {
      xyz: { breakpoint },
    },
    zIndex,
  }) => css`
    width: 100%;
    z-index: ${zIndex};
    @media screen and (min-height: ${breakpoint.xs}) {
      position: fixed;
      top: 0;
    }
    @media screen and (min-width: ${breakpoint.lg}) {
      position: relative;
    }
  `
)
