import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const ExpressFreightIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M7.5 7.88c0-.76.6-1.38 1.35-1.38h8.1v2.06h2.11c.66 0 1.22.49 1.33 1.15L21 12v2.75c0 .38-.3.69-.68.69 0 1.14-.9 2.06-2.02 2.06a2.04 2.04 0 01-2.03-2.06H12.9c0 1.14-.9 2.06-2.03 2.06a2.04 2.04 0 01-2.02-2.06H7.5V7.88zm10.8 6.53c.56 0 1.01.46 1.01 1.03 0 .57-.45 1.03-1.01 1.03s-1.01-.46-1.01-1.03c0-.57.45-1.03 1.01-1.03zm-8.44 1.03c0 .57.46 1.03 1.02 1.03.55 0 1-.46 1-1.03 0-.57-.45-1.03-1-1.03-.56 0-1.02.46-1.02 1.03zm9.53-5.56L20 12h-3.04V9.6h2.03c.16 0 .39.11.41.28zM4.96 9.25c-.22 0-.4.2-.4.46 0 .25.18.46.4.46H6.2c.21 0 .39-.2.39-.46 0-.25-.18-.46-.4-.46H4.97zM4.17 12c-.21 0-.39.2-.39.46 0 .25.18.46.4.46H6.2c.21 0 .39-.2.39-.46 0-.25-.18-.46-.4-.46H4.18zm2.04 3.66c.22 0 .39-.2.39-.46 0-.25-.18-.45-.4-.45H3.4c-.23 0-.4.2-.4.46 0 .25.18.46.4.46h2.8z"
      fill={color}
    />
  </SVG>
)

ExpressFreightIcon.propTypes = defaultIconPropTypes

ExpressFreightIcon.defaultProps = defaultPictographIconProps

export { ExpressFreightIcon }
