import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const TwitterIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M20.86 8.07a.4.4 0 00.13-.39.4.4 0 00-.29-.3l-.61-.15a.39.39 0 01-.26-.22.4.4 0 010-.34l.34-.7a.4.4 0 00-.23-.55.38.38 0 00-.22 0l-1.56.44a.38.38 0 01-.34-.07 3.85 3.85 0 00-5.09.37 4 4 0 00-1.14 2.8v.29a.2.2 0 01-.05.13.2.2 0 01-.12.06c-2.19.27-4.28-.87-6.54-3.51a.4.4 0 00-.4-.12.4.4 0 00-.23.33 6.1 6.1 0 00.38 4 .2.2 0 01-.12.17.2.2 0 01-.1.01l-.87-.17a.38.38 0 00-.34.12.4.4 0 00-.1.35 4.13 4.13 0 001.92 3.07.2.2 0 01-.08.28l-.41.17a.39.39 0 00-.22.22.4.4 0 00.01.32 3.45 3.45 0 002.5 1.97.2.2 0 01.12.18.2.2 0 01-.12.19 8.4 8.4 0 01-3.34.66.4.4 0 00-.3.06.41.41 0 00-.11.56.4.4 0 00.25.17A15.4 15.4 0 009.66 20a9.68 9.68 0 005.45-1.59 9.85 9.85 0 004.33-8.26v-.69a.4.4 0 01.14-.3l1.28-1.09z"
      fill={color}
    />
  </SVG>
)

TwitterIcon.propTypes = defaultIconPropTypes

TwitterIcon.defaultProps = defaultPictographIconProps

export { TwitterIcon }
