import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const WeightIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M5 8H19L22 21H2L5 8ZM15 14.5H17.35V14.612H17.3507V16.884C17.0468 17.1133 16.6947 17.2893 16.2948 17.412C15.8948 17.5347 15.4921 17.596 15.0868 17.596C14.5054 17.596 13.9827 17.4733 13.5188 17.228C13.0548 16.9773 12.6894 16.6333 12.4228 16.196C12.1614 15.7533 12.0308 15.2547 12.0308 14.7C12.0308 14.1453 12.1614 13.6493 12.4228 13.212C12.6894 12.7693 13.0574 12.4253 13.5268 12.18C13.9961 11.9293 14.5241 11.804 15.1108 11.804C15.6014 11.804 16.0468 11.8867 16.4468 12.052C16.8468 12.2173 17.1827 12.4573 17.4548 12.772L16.6228 13.54C16.2228 13.1187 15.7401 12.908 15.1748 12.908C14.8174 12.908 14.5001 12.9827 14.2227 13.132C13.9454 13.2813 13.7294 13.492 13.5747 13.764C13.4201 14.036 13.3428 14.348 13.3428 14.7C13.3428 15.0467 13.4201 15.356 13.5747 15.628C13.7294 15.9 13.9427 16.1133 14.2148 16.268C14.4921 16.4173 14.8068 16.492 15.1588 16.492C15.5321 16.492 15.8681 16.412 16.1667 16.252V15.5H15V14.5ZM7.952 16.084L8.704 15.3L10.496 17.5H12.008L9.552 14.396L11.872 11.9H10.432L7.952 14.516V11.9H6.664V17.5H7.952V16.084Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      d="M14.8293 8C14.9398 7.68722 15 7.35064 15 7C15 5.34315 13.6569 4 12 4C10.3431 4 9 5.34315 9 7C9 7.35064 9.06015 7.68722 9.17071 8H7.10002C7.03443 7.67689 7 7.34247 7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7C17 7.34247 16.9656 7.67689 16.9 8H14.8293Z"
      fill={color}
    />
  </SVG>
)

WeightIcon.propTypes = defaultIconPropTypes

WeightIcon.defaultProps = defaultPictographIconProps
