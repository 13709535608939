import styled, { css } from 'styled-components'
import { Headline } from '../../design-tokens/typography'
import { inputStyle, Content } from '../Input/Input.style'
import { StyledLabelProps } from '../Input/Input.types'
import { CounterProps, StyledTextareaProps } from './Textarea.types'

export const Container = styled(Content)<{ isOverLimit: boolean }>`
  ${({
    theme: {
      xyz: { spacing, color },
    },
    isOverLimit,
  }) => css`
    padding: calc(${spacing.space3}rem - 3px) calc(${spacing.space4}rem - 3px);
    position: relative;

    ${isOverLimit &&
    css`
      border-color: ${color.signalRed};
    `}
  `}
`

export const TopContent = styled.div(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    margin-bottom: ${spacing.space2}rem;
    min-height: ${spacing.space3}rem;
  `
)

export const StyledLabel = styled.label<StyledLabelProps>(({ theme, hasValue, focused, disabled, readOnly }) => {
  const {
    xyz: { spacing, color, fontSize, fontWeight, fontFamily },
  } = theme
  const notEmptyFocusedReadOnly = focused || hasValue || readOnly
  return css`
    position: absolute;
    margin: auto;
    overflow: hidden;
    color: ${disabled ? color.neutralPassiveGray : color.neutralBlack};
    font-size: ${notEmptyFocusedReadOnly ? fontSize.body.five : fontSize.body.three}rem;
    font-weight: ${notEmptyFocusedReadOnly ? fontWeight.normal : fontWeight.semiBold};
    font-family: ${fontFamily.Montserrat};
    cursor: ${focused ? 'default' : 'text'};
    height: auto;
    width: calc(100% - ${spacing.space7}rem - ${notEmptyFocusedReadOnly ? '3.125rem' : '0rem'});
    top: ${notEmptyFocusedReadOnly ? `calc(${spacing.space3}rem - 3px)` : `${spacing.space6}rem`};
    transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out, height 0.2s ease-in-out;
    text-overflow: ellipsis;
    white-space: ${notEmptyFocusedReadOnly ? 'nowrap' : 'normal'};
  `
})

export const CounterText = styled(Headline)<Omit<CounterProps, 'length' | 'maxLength'>>(
  ({
    theme: {
      xyz: { color, spacing },
    },
    disabled,
  }) => css`
    color: ${disabled ? color.neutralPassiveGray : color.neutralBlack};
    margin-right: -${spacing.space2}rem;
    margin-left: auto;
  `
)

export const StyledTextarea = styled.textarea<StyledTextareaProps>(
  ({
    theme: {
      xyz: { color, fontSize, fontWeight },
    },
    disabled,
  }) => css`
    ${inputStyle}

    color: ${disabled ? color.neutralPassiveGray : color.neutralBlack};
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.body.four}rem;
    line-height: ${fontSize.body.four}rem;
    min-height: 6.0625rem;

    ::placeholder {
      font-weight: ${fontWeight.medium};
      font-size: ${fontSize.body.four}rem;
      line-height: ${fontSize.body.four}rem;
    }
  `
)
