import React from 'react'

const Rating3Active: React.FC = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 10.3131C0 5.27304 2.79089 0 11.6353 0C20.4796 0 23.2705 5.27304 23.2705 10.3131C23.2705 15.3535 20.4796 20.6261 11.6353 20.6261C2.79089 20.6265 0 15.3922 0 10.3131Z"
      fill="#F7CC7A"
    />
    <path
      d="M7.93274 6.87611C7.93274 6.29193 8.40631 5.81836 8.99049 5.81836C9.57467 5.81836 10.0482 6.29193 10.0482 6.87611V8.99161C10.0482 9.57579 9.57467 10.0494 8.99049 10.0494C8.40631 10.0494 7.93274 9.57579 7.93274 8.99161V6.87611Z"
      fill="#5C2952"
    />
    <path
      d="M13.2217 6.87611C13.2217 6.29193 13.6953 5.81836 14.2794 5.81836C14.8636 5.81836 15.3372 6.29193 15.3372 6.87611V8.99161C15.3372 9.57579 14.8636 10.0494 14.2794 10.0494C13.6953 10.0494 13.2217 9.57579 13.2217 8.99161V6.87611Z"
      fill="#5C2952"
    />
    <rect x="6.875" y="13.2226" width="9.51976" height="1.58663" rx="0.793313" fill="#5C2952" />
  </svg>
)

export default Rating3Active
