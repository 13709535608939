import React from 'react'

const Rating4Inactive: React.FC = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 10.3131C0 5.27304 2.79089 0 11.6353 0C20.4796 0 23.2705 5.27304 23.2705 10.3131C23.2705 15.3535 20.4796 20.6261 11.6353 20.6261C2.79089 20.6265 0 15.3922 0 10.3131Z"
      fill="#C2C9D1"
    />
    <path
      d="M8.19718 6.87538C8.19718 6.43724 8.55236 6.08206 8.99049 6.08206C9.42862 6.08206 9.7838 6.43724 9.7838 6.87538V8.99088C9.7838 9.42901 9.42862 9.78419 8.99049 9.78419C8.55236 9.78419 8.19718 9.42901 8.19718 8.99088V6.87538Z"
      fill="white"
      stroke="white"
      strokeWidth="0.528875"
    />
    <path
      d="M13.4858 6.87538C13.4858 6.43724 13.8409 6.08206 14.2791 6.08206C14.7172 6.08206 15.0724 6.43724 15.0724 6.87538V8.99088C15.0724 9.42901 14.7172 9.78419 14.2791 9.78419C13.8409 9.78419 13.4858 9.42901 13.4858 8.99088V6.87538Z"
      fill="white"
      stroke="white"
      strokeWidth="0.528875"
    />
    <rect
      x="7.13944"
      y="13.4863"
      width="8.99088"
      height="1.05775"
      rx="0.528875"
      fill="white"
      stroke="white"
      strokeWidth="0.528875"
    />
  </svg>
)

export default Rating4Inactive
