import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const EnvelopeIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M11.3356 13.917C11.5116 14.0925 11.75 14.191 11.9986 14.191C12.2471 14.191 12.4855 14.0925 12.6616 13.917L19.9261 6.6525C20.0247 6.55422 20.5001 6.00033 20.5001 6.00033C20.5001 6.00033 19.7115 5.99996 19.5001 6H4.50006C4.28995 5.99974 3.40002 5.99983 3.40002 5.99983C3.40002 5.99983 3.97391 6.55422 4.07256 6.6525L11.3356 13.917Z"
      fill={color}
    />
    <Path
      d="M21 6.99976C21 6.99976 20.5385 7.46119 20.5 7.49976L16.116 12.0531C16.0809 12.0884 16 12.1859 16 12.1859C16 12.1859 16.0809 12.2834 16.116 12.3186L19.8982 16.1009C19.952 16.1528 19.9949 16.2148 20.0244 16.2834C20.0539 16.352 20.0694 16.4258 20.0701 16.5005C20.0708 16.5752 20.0566 16.6493 20.0284 16.7184C20.0001 16.7876 19.9584 16.8504 19.9056 16.9032C19.8528 16.9561 19.79 16.9979 19.7209 17.0262C19.6518 17.0545 19.5777 17.0688 19.503 17.0682C19.4284 17.0675 19.3545 17.0521 19.2859 17.0226C19.2172 16.9932 19.1552 16.9503 19.1032 16.8966L15.321 13.1144C15.2862 13.0795 15.1882 12.9998 15.1882 12.9998C15.1882 12.9998 15.0903 13.0795 15.0555 13.1144L13.4587 14.7111C13.072 15.0977 12.5476 15.3148 12.0008 15.3148C11.4539 15.3148 10.9295 15.0977 10.5427 14.7111L8.9445 13.1159C8.90923 13.0808 8.81175 12.9998 8.81175 12.9998C8.81175 12.9998 8.71427 13.0808 8.679 13.1159L4.89675 16.8981C4.79061 17.0006 4.6485 17.0572 4.50101 17.0558C4.35353 17.0545 4.21247 16.9953 4.10823 16.8909C4.00398 16.7866 3.94489 16.6455 3.94368 16.498C3.94247 16.3505 3.99924 16.2084 4.10175 16.1024L7.884 12.3201C7.91908 12.2849 8 12.1874 8 12.1874C8 12.1874 7.91908 12.0899 7.884 12.0546L3.5 7.49976C3.46176 7.46083 3 6.99976 3 6.99976C3 6.99976 3.00059 7.44651 3 7.4999V16.4999C3 16.8977 3.15804 17.2793 3.43934 17.5606C3.72064 17.8419 4.10218 17.9999 4.5 17.9999H19.5C19.8978 17.9999 20.2794 17.8419 20.5607 17.5606C20.842 17.2793 21 16.8977 21 16.4999V7.4999C21.0002 7.44595 21 6.99976 21 6.99976Z"
      fill={color}
    />
  </SVG>
)

EnvelopeIcon.propTypes = defaultIconPropTypes

EnvelopeIcon.defaultProps = defaultPictographIconProps

export { EnvelopeIcon }
