import React, { FC } from 'react'
import { inboxIconDefaultProps, InboxIconProps, inboxIconPropTypes, InvertibleColorScheme } from '../icons.types'
import { InboxIconSvg, Path, Rect, Text, useInvertedColors } from '../icons.styled'
import { XyzTheme } from '@postidigital/posti-theme'

const colorScheme: InvertibleColorScheme = {
  primary: XyzTheme.color.brandPurple,
  secondary: XyzTheme.color.brandPeach,
}

const ParcelInboxIcon: FC<InboxIconProps> = ({ letter, inverted, size, onClick, ...props }) => {
  const { primary, secondary } = useInvertedColors(colorScheme, inverted)
  return (
    <InboxIconSvg size={size} onClick={onClick} {...props}>
      <Rect y="10" width="32" height="32" rx="8" fill={secondary} />
      <Path
        d="M21.11 3.54c.33 1.89-.05 3.4-1.02 4.13-.65.5-1.5.57-2.16.18l-.09-.06a4.95 4.95 0 002.14-2.7c.73-1.87.75-4.15-.63-4.9A1.67 1.67 0 0017.9.15c-.7.27-1.5 1.1-1.9 2.16C15.57 1.24 14.82.4 14.1.14c-.5-.2-1-.18-1.44.06-1.38.74-1.35 3.02-.62 4.9.5 1.3 1.3 2.28 2.13 2.69l-.09.06c-.66.39-1.5.32-2.16-.18-.98-.74-1.34-2.24-1.02-4.13H9.09c-.34 2.49.3 4.55 1.79 5.68.68.51 1.47.78 2.27.78A3.47 3.47 0 0016 8.56 3.5 3.5 0 0018.85 10c.79 0 1.6-.27 2.27-.78 1.49-1.13 2.13-3.19 1.79-5.67h-1.8zM15.12 5.5v.1a1.2 1.2 0 01-.12.51c-.22-.05-.85-.57-1.3-1.73-.51-1.3-.37-2.28-.23-2.47.21.05.84.57 1.3 1.73a4.97 4.97 0 01.35 1.86zm1.92.6c-.07-.08-.12-.3-.13-.6v-.24c.01-.42.1-.98.36-1.62.45-1.14 1.06-1.67 1.3-1.73.14.23.27 1.19-.23 2.47-.45 1.14-1.08 1.67-1.3 1.73z"
        fill={secondary}
      />
      <Text fill={primary} fontSize="19" fontWeight="bold">
        <tspan x="50%" y="32.31" textAnchor="middle">
          {letter}
        </tspan>
      </Text>
    </InboxIconSvg>
  )
}

ParcelInboxIcon.propTypes = inboxIconPropTypes
ParcelInboxIcon.defaultProps = inboxIconDefaultProps

export { ParcelInboxIcon }
