import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  selected?: boolean
}

export const StyledTableRow = styled.tr(
  ({
    theme: {
      xyz: { color },
    },
  }) => css`
    border-bottom: 2px solid ${color.neutralGray5};

    &[data-selected='true'] {
      background-color: ${color.backgroundCTABlue} !important;
    }

    &:focus {
      position: relative;
      outline: none;
      box-shadow: inset 0px 0px 7px ${color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }
  `
)

export const TableRow: React.FC<TableRowProps> = ({ selected, children, ...rest }) => {
  return (
    <StyledTableRow tabIndex={0} data-selected={selected} {...rest}>
      {children}
    </StyledTableRow>
  )
}

TableRow.propTypes = {
  selected: PropTypes.bool,
}
