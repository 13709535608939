import React from 'react'
import PropTypes from 'prop-types'

import { TabsContext } from '../TabContext'

interface TabPanelProps {
  id?: string
  value: string | number | boolean
  tabId?: string
  children: string | JSX.Element | React.ReactChildren | React.ReactNode
}

const TabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>((props, ref) => {
  const { id, value, tabId, children, ...rest } = props
  const tabsContext = React.useContext(TabsContext)
  const { selectedTab } = tabsContext

  return (
    <div ref={ref} role="tabpanel" hidden={value !== selectedTab} id={id} aria-labelledby={tabId} {...rest}>
      {children}
    </div>
  )
})

TabPanel.displayName = 'TabPanel'

TabPanel.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  tabId: PropTypes.string,
}

export default TabPanel
