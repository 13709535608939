import { getWindow } from './helpers'

/**
 * Returns true if device has a touch screen.
 *
 * This works correctly for the common browsers and devices. Implementing 100% solution is next to impossible.
 * Depending on your use-case you might want to use this in combination with hover and screen-width media queries.
 */
const isTouchDevice = (): boolean => {
  return 'ontouchstart' in getWindow() || navigator.maxTouchPoints > 0
}

export default isTouchDevice
