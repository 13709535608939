import React from 'react'

const useEnhancedEffect = typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

const useEventCallback = (fn: () => void): CallableFunction => {
  const ref = React.useRef(fn)

  useEnhancedEffect(() => {
    ref.current = fn
  })

  return React.useCallback(() => {
    const fn = ref.current
    return fn()
  }, [ref])
}

export default useEventCallback
