import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path, Circle } from '../icons.styled'
import { IndicatorIconProps, defaultIndicatorIconProps, indicatorIconPropTypes } from '../icons.types'

const WarningIndicatorIcon: React.FC<IndicatorIconProps> = ({ color, inverted, ...props }) => {
  return inverted ? (
    <SVG {...props}>
      <Path
        d="M15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM9.25 4H6.75L7.16667 9H8.83333L9.25 4Z"
        fill={color}
      />
    </SVG>
  ) : (
    <SVG {...props} fill="none">
      <Circle cx="8" cy="8" r="7.25" stroke={color} />
      <Circle cx="8" cy="11" r="1" fill={color} />
      <Path fill={color} d="M6.75 4H9.25L8.83333 9H7.16667L6.75 4Z" />
    </SVG>
  )
}

WarningIndicatorIcon.defaultProps = {
  ...defaultIndicatorIconProps,
  color: XyzTheme.color.brandBrightYellow,
  width: `${XyzTheme.iconSize.xs}em`,
  height: `${XyzTheme.iconSize.xs}em`,
  viewBox: '0 0 16 16',
}

WarningIndicatorIcon.propTypes = indicatorIconPropTypes

export { WarningIndicatorIcon }
