import { NavigationItemProps } from '../TopBar/TopBar.types'

export const handleNavItemClick = (
  item: NavigationItemProps,
  event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
): void => {
  if (item.onClick) {
    event.preventDefault()
    item.onClick(event)
  }
}

export const checkAriaCurrent = (item: NavigationItemProps, currentPage: NavigationItemProps): 'page' | false => {
  return item.id === currentPage?.id ? 'page' : false
}
