import React, { ReactNode } from 'react'

import { HeadlineSize } from '../../design-tokens/typography'

interface CommonDialogProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose: () => void
  heroImage?: JSX.Element | Element
  headerContent?: string | ReactNode | JSX.Element
  headingSize?: HeadlineSize | keyof typeof HeadlineSize
  /*
   * By default dialog is centered into viewport. If ou want to overrife this give a value (e.g. 2rem)
   * how far away dialog should be rendered from top
   */
  topPosition?: string
  width?: DialogWidth | keyof typeof DialogWidth
  maxWidth?: string
  closeIconColor?: string
  scroll?: Scroll
  /*
   * Provides ID for an element where you want to render Dialog, if else than <body />
   */
  renderInto?: string
  disableOutsideClick?: boolean
  /*
   * Hides the title and the button controls of the Dialog and stretches the MainContent of the Dialog to
   * take all available space. Leaves the 'X' close button on the MainContent.
   */
  fullSizeMainContent?: boolean
  /**
   * Provides a container where bottom buttons can be placed
   */
  bottomButtons?: React.ReactNode
  /**
   * If Alert mode is selected, Modal will not go full width on mobile
   */
  alert?: boolean
}

interface DialogWithCloseButton extends CommonDialogProps {
  noCloseButton?: false
  closeText: string
}

interface DialogWithoutCloseButton extends CommonDialogProps {
  noCloseButton: true
}

export type DialogProps = DialogWithCloseButton | DialogWithoutCloseButton

export enum DialogScroll {
  body = 'body',
  content = 'content',
}

export type Scroll = DialogScroll | keyof typeof DialogScroll

export interface WrapperProps {
  topPosition?: string
  width?: DialogWidth | keyof typeof DialogWidth
  maxWidth?: string
  scroll: Scroll
}

export enum DialogWidth {
  'max-content' = 'max-content',
  'min-content' = 'min-content',
  'fit-content' = 'fit-content',
  'auto' = 'auto',
}
