import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { PostiLogoIconProps } from '../icons.types'
import { CompanyLogoBase } from './CompanyLogoBase'
import { PostiLogo } from './PostiLogo'

const PostiLogoIcon: React.FC<PostiLogoIconProps> = ({ backgroundColor, color, size, ...props }) => (
  <CompanyLogoBase size={size} backgroundColor={backgroundColor} icon={<PostiLogo color={color} {...props} />} />
)

PostiLogoIcon.defaultProps = {
  backgroundColor: XyzTheme.color.brandPurple,
  color: XyzTheme.color.brandPink,
}

export { PostiLogoIcon }
