import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const ImageIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M19.244 3H3v18h18V4.756 3h-1.756zM8.414 13.917a.878.878 0 011.517-.095l.729 1.094a.22.22 0 00.376-.018l2.14-3.975a.857.857 0 01.8-.46.879.879 0 01.77.505l3.142 6.673a.438.438 0 01-.398.626H6.951a.44.44 0 01-.392-.636l1.856-3.714zM6.733 8.485a1.756 1.756 0 113.512 0 1.756 1.756 0 01-3.512 0z"
      fill={color}
    />
  </SVG>
)

ImageIcon.propTypes = defaultIconPropTypes

ImageIcon.defaultProps = defaultPictographIconProps

export { ImageIcon }
