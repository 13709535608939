import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const CheckmarkIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M10.6707 14.1528L19.0281 6L21 7.92361L10.6707 18L3 10.534L4.97191 8.61039L10.6707 14.1528Z"
      fill={color}
    />
  </SVG>
)

CheckmarkIcon.propTypes = defaultIconPropTypes

CheckmarkIcon.defaultProps = defaultPictographIconProps
