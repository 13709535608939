import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const CopyIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M12.9 3l3.39 3.66v11H6.13c-.3 0-.59-.13-.8-.36a1.27 1.27 0 01-.33-.86V4.22c0-.32.12-.63.33-.86.21-.23.5-.36.8-.36H12.9zM8.5 6.36c0 .5-.4.91-.9.91s-.9-.4-.9-.91c0-.5.4-.92.9-.92s.9.41.9.92zm4.84 2.34H7.3a.6.6 0 00-.43.18.61.61 0 00.43 1.04h6.04a.6.6 0 00.43-.18.61.61 0 00-.43-1.04zM7.3 11.14h6.04a.6.6 0 01.43.18.61.61 0 01-.43 1.05H7.3a.6.6 0 01-.43-.18.61.61 0 01.43-1.05z"
      fill={color}
    />
    <Path
      d="M10.25 19.93c.14 0 .27.05.37.16a.54.54 0 01-.37.91h-.8c-.28 0-.55-.11-.75-.31-.2-.2-.3-.48-.3-.76v-.54c0-.14.05-.28.15-.38a.53.53 0 01.9.38v.54h.8zM17.94 21h-.8a.53.53 0 01-.37-.16.54.54 0 01.37-.91h.8v-.54c0-.14.06-.28.15-.38a.53.53 0 01.91.38v.54c0 .28-.11.55-.31.76-.2.2-.47.31-.75.31zM12.9 21h-.8a.53.53 0 01-.37-.16.54.54 0 01.38-.91h.8c.13 0 .27.05.37.15a.54.54 0 01-.38.92zM15.29 21h-.8a.53.53 0 01-.37-.16.54.54 0 01.37-.91h.8c.14 0 .27.05.37.15a.54.54 0 01-.37.92zM18.47 12.96a.53.53 0 01-.38-.15.54.54 0 01-.15-.38v-.58l-.38-.38a.54.54 0 01.38-.91c.14 0 .27.05.37.15l.38.38a1.07 1.07 0 01.31.76v.58c0 .14-.06.28-.16.38-.1.1-.23.15-.37.15zM18.47 15.37a.53.53 0 01-.38-.15.54.54 0 01-.15-.38v-.8c0-.15.06-.28.15-.38a.53.53 0 01.91.37v.8c0 .15-.06.29-.16.39-.1.1-.23.15-.37.15zM18.47 18.05a.53.53 0 01-.38-.15.54.54 0 01-.15-.38v-.8c0-.15.06-.28.15-.39a.53.53 0 01.91.38v.8c0 .15-.06.29-.16.39-.1.1-.23.15-.37.15z"
      fill={color}
    />
  </SVG>
)

CopyIcon.propTypes = defaultIconPropTypes

CopyIcon.defaultProps = defaultPictographIconProps

export { CopyIcon }
