import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const SearchDataIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M10.2736 14.5471C12.6338 14.5471 14.5471 12.6338 14.5471 10.2736C14.5471 7.91334 12.6338 6 10.2736 6C7.91334 6 6 7.91334 6 10.2736C6 12.6338 7.91334 14.5471 10.2736 14.5471ZM10.2736 17.5471C14.2906 17.5471 17.5471 14.2906 17.5471 10.2736C17.5471 6.25649 14.2906 3 10.2736 3C6.25649 3 3 6.25649 3 10.2736C3 14.2906 6.25649 17.5471 10.2736 17.5471Z"
      fill={color}
    />
    <Path
      d="M8.69646 12.2C8.23001 12.2 7.848 12.1083 7.55043 11.9249C7.25286 11.7416 7.03572 11.5009 6.899 11.2029C6.7663 10.9011 6.69995 10.5687 6.69995 10.2057C6.69995 9.84662 6.7663 9.51615 6.899 9.21434C7.03572 8.91252 7.25286 8.66802 7.55043 8.48081C7.85202 8.29361 8.23403 8.20001 8.69646 8.20001C9.1589 8.20001 9.5389 8.29361 9.83647 8.48081C10.1381 8.66802 10.3552 8.91443 10.4879 9.22007C10.6246 9.52188 10.693 9.85044 10.693 10.2057C10.693 10.5649 10.6246 10.8953 10.4879 11.1971C10.3552 11.499 10.1401 11.7416 9.8425 11.9249C9.54493 12.1083 9.16292 12.2 8.69646 12.2ZM7.8098 10.2057C7.8098 10.6069 7.87212 10.903 7.99678 11.094C8.12546 11.285 8.35869 11.3805 8.69646 11.3805C9.03424 11.3805 9.26546 11.285 9.39012 11.094C9.51879 10.903 9.58313 10.6069 9.58313 10.2057C9.58313 9.80078 9.51879 9.50278 9.39012 9.31176C9.26546 9.12074 9.03424 9.02523 8.69646 9.02523C8.35869 9.02523 8.12546 9.12074 7.99678 9.31176C7.87212 9.50278 7.8098 9.80078 7.8098 10.2057Z"
      fill={color}
    />
    <Path
      d="M11.8723 11.3232V9.08253H11.0641V8.29743H11.8904C12.2483 8.29743 12.5097 8.37193 12.6746 8.52093C12.8434 8.66993 12.9279 8.87432 12.9279 9.13411V11.3232H13.7V12.1083H10.9796V11.3232H11.8723Z"
      fill={color}
    />
    <Path
      d="M13.566 15.4948L15.4939 13.5668L20.036 18.1089C20.5684 18.6413 20.5684 19.5045 20.036 20.0368C19.5037 20.5692 18.6405 20.5692 18.1081 20.0368L13.566 15.4948Z"
      fill={color}
    />
  </SVG>
)

SearchDataIcon.propTypes = defaultIconPropTypes

SearchDataIcon.defaultProps = defaultPictographIconProps
