import React from 'react'

const ThankYouRating4: React.FC = () => (
  <svg width="153" height="153" viewBox="0 0 153 153" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M76.5 153C118.75 153 153 118.75 153 76.5C153 34.2502 118.75 0 76.5 0C34.2502 0 0 34.2502 0 76.5C0 118.75 34.2502 153 76.5 153Z"
      fill="#F7CC7A"
    />
    <path
      d="M49.7252 53.5484C49.7252 49.3235 53.1503 45.8984 57.3752 45.8984C61.6002 45.8984 65.0253 49.3235 65.0253 53.5484V68.8488C65.0253 73.0735 61.6002 76.4988 57.3752 76.4988C53.1503 76.4988 49.7252 73.0735 49.7252 68.8488V53.5484Z"
      fill="#5C2952"
    />
    <path
      d="M87.9752 53.5484C87.9752 49.3235 91.3999 45.8984 95.6252 45.8984C99.8499 45.8984 103.275 49.3235 103.275 53.5484V68.8488C103.275 73.0735 99.8499 76.4988 95.6252 76.4988C91.3999 76.4988 87.9752 73.0735 87.9752 68.8481V53.5484Z"
      fill="#5C2952"
    />
    <path
      d="M76.5 122.399C94.1415 122.399 109.117 109.147 114.445 96.3179C115.859 92.9111 112.068 90.5983 109.048 92.4629C102.256 96.6571 91.0771 101.682 76.5 101.682C61.9229 101.682 50.7439 96.6571 43.9527 92.4629C40.9322 90.5983 37.1408 92.9111 38.5554 96.3179C43.883 109.147 58.8585 122.399 76.5 122.399Z"
      fill="#5C2952"
    />
  </svg>
)

export default ThankYouRating4
