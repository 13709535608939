import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const EditIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M15.14 5.6224C15.0684 5.55448 14.9366 5.41895 14.8722 5.3598C14.7769 5.41942 14.6812 5.55448 14.6096 5.6224L6.04671 14.1933C5.97638 14.2637 5.87119 14.3687 5.78145 14.4584C5.86544 14.5429 5.97638 14.6538 6.04671 14.7242L9.25789 17.9384C9.32824 18.0088 9.46634 18.1438 9.52461 18.2021C9.60242 18.1243 9.71798 18.0088 9.78833 17.9384L18.3512 9.36901C18.4215 9.2986 18.5601 9.16006 18.6163 9.10381C18.5525 9.0349 18.4215 8.90849 18.3512 8.83807L15.14 5.6224Z"
      fill={color}
    />
    <Path
      d="M5.24616 15.5347C5.15081 15.4399 5.07195 15.3604 4.98153 15.2707C4.89928 15.3529 4.66497 15.5617 4.62493 15.6849L3.01934 20.5062C2.99718 20.5723 2.99387 20.6433 3.00978 20.7112C3.02568 20.7792 3.06017 20.8413 3.10937 20.8907C3.15902 20.9395 3.22106 20.9738 3.28878 20.9898C3.35649 21.0058 3.4273 21.0029 3.49351 20.9816L8.31028 19.3752C8.4264 19.2591 8.59697 19.0885 8.69658 18.9889C8.62157 18.9139 8.55105 18.8436 8.46033 18.7527L5.24616 15.5347Z"
      fill={color}
    />
    <Path
      d="M20.2276 3.75012C19.7234 3.26863 19.0533 3 18.3564 3C17.6596 3 16.9895 3.26863 16.4853 3.75012L15.9511 4.28556C15.8807 4.35598 15.7743 4.46098 15.6847 4.55058C15.736 4.60146 15.8807 4.74609 15.9511 4.8165L19.1615 8.03067C19.2318 8.10107 19.3333 8.20246 19.4267 8.29589C19.5168 8.20304 19.6216 8.10107 19.6919 8.03067L20.2276 7.49222C20.7222 6.99536 20.9999 6.32256 20.9999 5.62117C20.9999 4.91978 20.7222 4.24698 20.2276 3.75012Z"
      fill={color}
    />
  </SVG>
)

EditIcon.propTypes = defaultIconPropTypes

EditIcon.defaultProps = defaultPictographIconProps
