/* eslint-disable import/named */
import React, { HTMLAttributes } from 'react'
import { VariableSizeList, VariableSizeListProps } from 'react-window'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const LIST_CLASS_NAME = 'v-list'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  flex-grow: 1;

  .${LIST_CLASS_NAME} {
    -webkit-overflow-scrolling: touch;
  }
`

export interface ListProps extends VariableSizeListProps {
  /**
   * Content which will be rendered to the inside of the wrapper after the list
   * Examples: no items message
   */
  bottomTemplate?: JSX.Element
  /**
   * Content which will be rendered to the top of the list but still inside the wrapper
   * Examples: loading indicator, error message
   */
  topTemplate?: JSX.Element
}

// The onScroll will be passed only to the VariableSizeList to keep things more simple
type Props = ListProps & Omit<HTMLAttributes<HTMLElement>, 'onScroll'>

const VirtualizedList = React.forwardRef<VariableSizeList, Props>((props, ref) => {
  const { topTemplate, bottomTemplate, onScroll, children, width, height, className, onItemsRendered, ...rest } = props
  return (
    <Wrapper role="list" className={className} {...rest}>
      {topTemplate}
      <VariableSizeList
        width={width}
        height={height}
        onScroll={onScroll}
        ref={ref}
        className={LIST_CLASS_NAME}
        onItemsRendered={onItemsRendered}
        {...rest}
      >
        {children}
      </VariableSizeList>
      {bottomTemplate}
    </Wrapper>
  )
})

VirtualizedList.displayName = 'VirtualizedList'

VirtualizedList.propTypes = {
  bottomTemplate: PropTypes.element,
  topTemplate: PropTypes.element,
}

VirtualizedList.defaultProps = {
  bottomTemplate: null,
  topTemplate: null,
}

export default VirtualizedList
