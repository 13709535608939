import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const PhoneIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M8 3C6.89543 3 6 3.89543 6 5V19C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19V5C18 3.89543 17.1046 3 16 3H8ZM7.66736 5.74725C7.66736 5.19497 8.11508 4.74725 8.66736 4.74725H15.2388C15.7911 4.74725 16.2388 5.19497 16.2388 5.74725V14.5473C16.2388 15.0995 15.7911 15.5473 15.2388 15.5473H8.66736C8.11508 15.5473 7.66736 15.0995 7.66736 14.5473V5.74725ZM10.2857 18.2596C10.2857 17.7625 10.6887 17.3596 11.1857 17.3596H12.8143C13.3113 17.3596 13.7143 17.7625 13.7143 18.2596C13.7143 18.7566 13.3113 19.1596 12.8143 19.1596H11.1857C10.6887 19.1596 10.2857 18.7566 10.2857 18.2596Z"
      fill={color}
    />
  </SVG>
)

PhoneIcon.propTypes = defaultIconPropTypes

PhoneIcon.defaultProps = defaultPictographIconProps

export { PhoneIcon }
