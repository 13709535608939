import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Theme } from '@postidigital/posti-theme'
import { ThemeColor } from '../../utils/helpers'

export interface Props extends HTMLAttributes<HTMLSpanElement> {
  color?: ThemeColor | string
  size?: string
  backgroundImageUrl?: string
}

const CircleElement = styled.span<Props>(
  ({ theme, color, size, backgroundImageUrl }) => css`
    display: flex;
    position: relative;
    background-color: ${theme.color[color] || color};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    user-select: none;
    width: ${size};
    height: ${size};
    ${backgroundImageUrl && backgroundImageUrl.length > 0
      ? `background-image: url(${backgroundImageUrl}); background-size: 100% 100%; background-repeat: no-repeat;`
      : undefined}
  `
)

/**
 * Circle Component
 * @deprecated Please use Square component instead
 */
const Circle: React.FC<Props> = ({ children, ...props }) => <CircleElement {...props}>{children}</CircleElement>

Circle.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
}

Circle.defaultProps = {
  color: Theme.color.signalBlue,
  size: `${Theme.size.icon.m}rem`,
}

/** @component */
export default Circle
