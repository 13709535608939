import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const PersonWheelchairIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M8.06 20.91a4.45 4.45 0 004.54-1.85.8.8 0 00-.67-1.26.77.77 0 00-.64.32 2.8 2.8 0 01-3.53 1.03 2.8 2.8 0 01-1.54-3.31 2.79 2.79 0 011.76-1.86.82.82 0 00.55-.77.8.8 0 00-.71-.8.82.82 0 00-.39.05 4.42 4.42 0 00-2.92 4.46 4.38 4.38 0 003.55 4zM10.95 7a2 2 0 002-2 2 2 0 00-2-2 2 2 0 00-2.02 2c0 1.1.9 2 2.02 2z"
      fill={color}
    />
    <Path
      d="M9.74 15.4a1.2 1.2 0 001.2 1.2h3.86l.11.18 1.86 2.85a1.2 1.2 0 001.02.56 1.22 1.22 0 001.06-.62 1.2 1.2 0 00-.04-1.22l-1.85-2.85a2.8 2.8 0 00-2.39-1.3h-2.01a.4.4 0 01-.4-.4v-1-.2H14.98a1.21 1.21 0 001.2-1.2 1.2 1.2 0 00-1.2-1.2H12.15V9a1.2 1.2 0 00-1.21-1.2A1.21 1.21 0 009.74 9v6.4z"
      fill={color}
    />
  </SVG>
)

PersonWheelchairIcon.propTypes = defaultIconPropTypes

PersonWheelchairIcon.defaultProps = defaultPictographIconProps

export { PersonWheelchairIcon }
