import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path, G } from '../icons.styled'

const FaceIdIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <G fill={color}>
      <Path d="M9 2.75H3v6h3v-3h3v-3zM15 2.75h6v6h-3v-3h-3v-3zM9 20.75H3v-6h3v3h3v3zM15 20.75h6v-6h-3v3h-3v3z" />
      <Path d="M11.96 12.22a2.46 2.46 0 100-4.92 2.46 2.46 0 000 4.92zM12 12.8c-1.2 0-2.34.35-3.18.98a2.97 2.97 0 00-1.32 2.35v.17h9v-.18c0-.88-.48-1.72-1.32-2.34A5.41 5.41 0 0012 12.8z" />
    </G>
  </SVG>
)

FaceIdIcon.propTypes = defaultIconPropTypes

FaceIdIcon.defaultProps = defaultPictographIconProps

export { FaceIdIcon }
