import React from 'react'

import { DefaultIconProps, defaultPictographIconProps, defaultIconPropTypes } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const CheckmarkCircledIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props} fill="none">
    <Path
      d="M12 21a9 9 0 100-18 9 9 0 000 18zm4.57-12.6l-5.43 5.5-3.7-3.74-1.29 1.3 4.99 5.04 6.71-6.8-1.28-1.3z"
      fill={color}
    />
  </SVG>
)

CheckmarkCircledIcon.propTypes = defaultIconPropTypes

CheckmarkCircledIcon.defaultProps = defaultPictographIconProps

export { CheckmarkCircledIcon }
