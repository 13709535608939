import styled, { css } from 'styled-components'
import { Body, BodySize } from '../../design-tokens/typography'
import { UploadIcon } from '../../design-tokens/icons'
import { DropdownContainerProps } from './FileUpload'
import { mediaQuery } from '../../utils/mediaQuery'

export const DropdownContainer = styled.div<DropdownContainerProps>`
  ${({
    theme: {
      xyz: { color, borderRadius },
    },
    isDragActive,
    isDisabled,
  }) => css`
    background: ${color.neutralWhite};
    border: 0.125rem dashed ${color.neutralPassiveGray};
    border-radius: ${borderRadius.sm}rem;
    padding: 3.125rem 2.5rem;
    cursor: pointer;

    ${!isDisabled &&
    css`
      &:hover,
      &:focus {
        border: 0.125rem solid ${color.signalBlue};
        outline: none;
      }

      ${isDragActive &&
      css`
        border: 0.125rem solid ${color.signalBlue};
      `}
    `}

    ${isDisabled &&
    css`
      cursor: default;
      border: 0.125rem dashed ${color.neutralGray5};
    `}
  `}
`

export const Placeholder = styled.div<{ centerContent: boolean }>`
  ${({ centerContent }) => css`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;

    ${!centerContent &&
    css`
      ${mediaQuery.sm`
          flex-direction: row;
        `}
    `}
  `}
`

export const Text = styled(Body).attrs({ size: BodySize.Three })<{ isDisabled?: boolean; centerContent: boolean }>`
  ${({
    theme: {
      xyz: { color },
    },
    isDisabled,
    centerContent,
  }) => css`
    margin: 0;
    color: ${isDisabled ? color.neutralGray5 : color.neutralNetworkGray};
    text-align: center;

    ${isDisabled &&
    css`
      & > * {
        color: ${color.neutralGray5} !important;
      }
    `}

    ${!centerContent &&
    css`
      ${mediaQuery.sm`
          text-align: left;
        `}
    `}
  `}
`

export const StyledUploadIcon = styled(UploadIcon)<{ centerContent: boolean }>`
  ${({
    theme: {
      xyz: { spacing },
    },
    centerContent,
  }) => css`
    flex-shrink: 0;
    margin-bottom: ${spacing.space1}rem;

    ${!centerContent &&
    css`
      ${mediaQuery.sm`
          margin: 0 ${spacing.space4}rem ${spacing.space1}rem 0;
        `}
    `}
  `}
`

export const StyledWrapper = styled.div<{ maxWidth: string }>`
  ${({ maxWidth }) => css`
    ${maxWidth && `max-width: ${maxWidth};`}
  `}
`
