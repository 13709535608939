import React from 'react'

const Rating2Active: React.FC = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 10.3131C0 5.27304 2.79089 0 11.6353 0C20.4796 0 23.2705 5.27304 23.2705 10.3131C23.2705 15.3535 20.4796 20.6261 11.6353 20.6261C2.79089 20.6265 0 15.3922 0 10.3131Z"
      fill="#EDBDBD"
    />
    <path
      d="M7.93237 6.34681C7.93237 5.76263 8.40594 5.28906 8.99012 5.28906C9.5743 5.28906 10.0479 5.76263 10.0479 6.34681V8.46231C10.0479 9.04649 9.5743 9.52007 8.99012 9.52007C8.40594 9.52007 7.93237 9.04649 7.93237 8.46231V6.34681Z"
      fill="#7D2332"
    />
    <path
      d="M13.2211 6.34681C13.2211 5.76263 13.6946 5.28906 14.2788 5.28906C14.863 5.28906 15.3366 5.76263 15.3366 6.34681V8.46231C15.3366 9.04649 14.863 9.52007 14.2788 9.52007C13.6946 9.52007 13.2211 9.04649 13.2211 8.46231V6.34681Z"
      fill="#7D2332"
    />
    <path
      d="M11.6345 11.6356C9.19521 11.6356 7.12457 13.4679 6.38793 15.2418C6.19234 15.7129 6.71657 16.0327 7.13421 15.7748C8.07321 15.1949 9.61892 14.5001 11.6345 14.5001C13.65 14.5001 15.1957 15.1949 16.1347 15.7748C16.5524 16.0327 17.0766 15.7129 16.881 15.2418C16.1444 13.4679 14.0737 11.6356 11.6345 11.6356Z"
      fill="#7D2332"
    />
  </svg>
)

export default Rating2Active
