import React, { FC } from 'react'
import { inboxIconDefaultProps, InboxIconProps, inboxIconPropTypes, InvertibleColorScheme } from '../icons.types'
import { InboxIconSvg, Path, Text, useInvertedColors } from '../icons.styled'
import { XyzTheme } from '@postidigital/posti-theme'

const colorScheme: InvertibleColorScheme = {
  primary: XyzTheme.color.brandNavy,
  secondary: XyzTheme.color.brandSky,
}

const InvoiceInboxIcon: FC<InboxIconProps> = ({ letter, inverted, size, onClick, ...props }) => {
  const { primary, secondary } = useInvertedColors(colorScheme, inverted)

  return (
    <InboxIconSvg size={size} onClick={onClick} {...props}>
      <Path
        d="M0 11a8 8 0 018-8h16a8 8 0 018 8v28l-3.84-2.57L24.32 39l-3.84-2.57L16.64 39l-4.16-2.57L8.32 39l-4.16-2.57L0 39V11z"
        fill={secondary}
      />
      <Path opacity=".3" fill={primary} d="M4 26h24v2H4zM4 30h24v2H4z" />
      <Text fill={primary} fontSize="19" fontWeight="bold">
        <tspan x="50%" y="22.31" textAnchor="middle">
          {letter}
        </tspan>
      </Text>
    </InboxIconSvg>
  )
}

InvoiceInboxIcon.propTypes = inboxIconPropTypes
InvoiceInboxIcon.defaultProps = inboxIconDefaultProps

export { InvoiceInboxIcon }
