import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const ParcelsIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M7.3 10.4a.15.15 0 00-.19.06.16.16 0 00-.02.07v1.25c0 .12-.04.24-.13.32a.45.45 0 01-.65 0 .47.47 0 01-.13-.32v-1.76a.16.16 0 00-.1-.15L3.22 8.66 3 8.58v7.12a.63.63 0 00.37.56l6.25 2.65.21.1v-7.44-.1l-.09-.04-2.44-1.04zM12.36 6.3a.15.15 0 00.09-.14.16.16 0 00-.1-.14l-2.12-.94a.6.6 0 00-.48 0L3.9 7.66a.15.15 0 00-.1.15.16.16 0 00.1.14l2.55 1.08a.15.15 0 00.13 0l5.77-2.73zM10.22 10.63a.15.15 0 00.12 0l5.76-2.67a.16.16 0 000-.28l-2.26-1a.15.15 0 00-.12 0L7.98 9.39a.15.15 0 00-.1.15.16.16 0 00.1.13l2.24.96zM16.5 19.34v.17l.16-.07 4.08-1.89a.44.44 0 00.26-.4v-4.98V12l-.16.07-4.27 1.98-.07.03V19.35zM14.01 13.3a.1.1 0 00-.14.06l-.01.05v.9c0 .09-.03.17-.1.23a.33.33 0 01-.46 0 .34.34 0 01-.1-.23v-1.27a.11.11 0 00-.07-.1l-2.07-.88a6.51 6.51 0 00-.16-.06V17.14a.45.45 0 00.27.4l4.52 1.92.15.07v-5.37-.08l-.07-.02-1.76-.75zM17.66 10.35a.11.11 0 000-.2l-1.53-.68a.43.43 0 00-.35 0l-4.22 1.87a.11.11 0 000 .2l1.85.79a.1.1 0 00.08 0l4.17-1.98zM16.12 13.48a.1.1 0 00.09 0l4.16-1.93c.02 0 .03-.02.05-.04a.11.11 0 00-.05-.16l-1.63-.72a.1.1 0 00-.1 0l-4.14 1.96a.11.11 0 00-.06.1.11.11 0 00.06.1l1.62.69z"
      fill={color}
    />
  </SVG>
)

ParcelsIcon.propTypes = defaultIconPropTypes

ParcelsIcon.defaultProps = defaultPictographIconProps

export { ParcelsIcon }
