import React from 'react'
import PropTypes from 'prop-types'

import { StyledTab } from './Tab.style'

export interface TabProps {
  value?: string | number | boolean
  disabled?: boolean
  id?: string
  panelId?: string
  selected?: boolean
  tabRef?: React.RefObject<HTMLDivElement>
  onChange?: (value: string | number | boolean) => void
  children: string | JSX.Element | React.ReactChildren | React.ReactNode
}

const Tab = React.forwardRef<HTMLButtonElement, TabProps>((props, ref) => {
  const { children, disabled, id, panelId, selected, tabRef, value, onChange, ...rest } = props

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <StyledTab
      ref={ref}
      role="tab"
      id={id}
      selected={selected}
      disabled={disabled}
      aria-selected={selected}
      aria-disabled={disabled}
      aria-controls={panelId}
      tabIndex={selected ? 0 : -1}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </StyledTab>
  )
})

Tab.displayName = 'Tab'

Tab.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  panelId: PropTypes.string,
}

export default Tab
