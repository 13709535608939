import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const FeedbackIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M3 12c0-3.91 2.16-8 9-8s9 4.09 9 8-2.16 8-9 8-9-4.06-9-8zm12.4-2.15a.64.64 0 00-.84.33.64.64 0 11-1.18-.52 1.92 1.92 0 013.59.18c.1.34-.08.7-.41.81a.64.64 0 01-.8-.4.64.64 0 00-.36-.4zm-6.27-.02a.64.64 0 00-.78.45.64.64 0 11-1.24-.33 1.92 1.92 0 013.57-.37.64.64 0 11-1.14.58.64.64 0 00-.4-.33zm7.1 3.67A4.85 4.85 0 0112 16.42a4.85 4.85 0 01-4.23-2.92c-.16-.38.26-.64.6-.43a6.9 6.9 0 007.26 0c.34-.2.76.05.6.43z"
      fill={color}
    />
  </SVG>
)

FeedbackIcon.propTypes = defaultIconPropTypes

FeedbackIcon.defaultProps = defaultPictographIconProps

export { FeedbackIcon }
