import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const FacebookIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M17.66 13.92c0 .1-.04.33-.04.33h-1.87a.38.38 0 00-.38.38V21h4.88a.75.75 0 00.75-.75V3.75a.75.75 0 00-.75-.75H3.75a.75.75 0 00-.75.75v16.5a.75.75 0 00.75.75h8.63v-6.38a.38.38 0 00-.38-.37h-1.88v-2.62-.38H12a.38.38 0 00.38-.38V9.9a4.27 4.27 0 014.26-4.27H18v3.01H16.64a1.27 1.27 0 00-1.27 1.26v.98a.38.38 0 00.38.38H18l-.05.42-.29 2.25z"
      fill={color}
    />
  </SVG>
)

FacebookIcon.propTypes = defaultIconPropTypes

FacebookIcon.defaultProps = defaultPictographIconProps

export { FacebookIcon }
