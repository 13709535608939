import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, Rect, SVG } from '../icons.styled'

export const KnobsIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M12.6633 8C12.6633 9.10457 11.7679 10 10.6633 10C9.55876 10 8.66333 9.10457 8.66333 8C8.66333 6.89543 9.55876 6 10.6633 6C11.7679 6 12.6633 6.89543 12.6633 8Z"
      fill={color}
    />
    <Rect x="2.19995" y="6.4707" width="6" height="3" rx="1.5" fill={color} />
    <Rect x="13.2" y="6.4707" width="8.5" height="3" rx="1.5" fill={color} />
    <Path
      d="M11.2366 16C11.2366 14.8954 12.132 14 13.2366 14C14.3411 14 15.2366 14.8954 15.2366 16C15.2366 17.1046 14.3411 18 13.2366 18C12.132 18 11.2366 17.1046 11.2366 16Z"
      fill={color}
    />
    <Rect x="21.7" y="17.5293" width="6" height="3" rx="1.5" transform="rotate(-180 21.7 17.5293)" fill={color} />
    <Rect x="10.7" y="17.5293" width="8.5" height="3" rx="1.5" transform="rotate(-180 10.7 17.5293)" fill={color} />
  </SVG>
)

KnobsIcon.propTypes = defaultIconPropTypes

KnobsIcon.defaultProps = defaultPictographIconProps
