import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const ShieldIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M5.62859 3H18.3714C18.6713 3.00178 18.9584 3.1211 19.1698 3.33188C19.3812 3.54267 19.4999 3.8278 19.4999 4.125V8.82225C19.5086 11.3135 18.8313 13.7598 17.5411 15.898C16.2509 18.0361 14.3966 19.7851 12.1779 20.9565C12.1231 20.9852 12.062 21.0002 12 21C11.9382 21 11.8773 20.9848 11.8229 20.9557C9.60382 19.7848 7.74921 18.0361 6.45883 15.898C5.16846 13.7599 4.49122 11.3135 4.50009 8.82225V4.125C4.50008 3.8278 4.61876 3.54267 4.8302 3.33188C5.04164 3.1211 5.32867 3.00178 5.62859 3ZM15.6236 8L11.9092 11.397L9.37641 9.08766L8.5 9.88917L11.9092 13L16.5 8.80151L15.6236 8Z"
      fill={color}
    />
  </SVG>
)

ShieldIcon.propTypes = defaultIconPropTypes

ShieldIcon.defaultProps = defaultPictographIconProps
