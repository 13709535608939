import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  & > div {
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`

/**
 * @deprecated Component is unrelated to posti-web-components and should be moved to its own application.
 */
const ProductCardContainer: React.FC = ({ children, ...rest }) => (
  <StyledContainer {...rest}>{children}</StyledContainer>
)

export default ProductCardContainer
