import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { SpeechBubbleSVG, SpeechBubbleProps } from './SpeechBubbleSVG'

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  imageHref?: string
  imageJSX?: React.ReactElement
  imageWidth: number
  imageHeight: number
  bubbles: Array<SpeechBubbleProps>
}

const StyledSVG = styled.svg`
  width: 100%;
`

export const SpeechBubbledImage: React.FC<Props> = ({ imageHref, imageJSX, imageWidth, imageHeight, bubbles }) => {
  const ImageComponent = imageHref ? (
    <image href={imageHref} x={0} y={0} width={imageWidth} height={imageHeight} />
  ) : (
    imageJSX
  )

  return (
    <StyledSVG viewBox={`0 0 ${imageWidth} ${imageHeight}`} xmlns="http://www.w3.org/2000/svg">
      {ImageComponent}
      {bubbles.map((bubble, index) => (
        <SpeechBubbleSVG key={`speech-bubble-${index}`} {...bubble} />
      ))}
    </StyledSVG>
  )
}

SpeechBubbledImage.propTypes = {
  imageHref: PropTypes.string,
  imageJSX: PropTypes.element,
  imageWidth: PropTypes.number.isRequired,
  imageHeight: PropTypes.number.isRequired,
  bubbles: PropTypes.array.isRequired,
}

export default SpeechBubbledImage
