import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const ShelfIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8 3H3V8.4V10.2V17.4V19.2V21H4.8V19.2L19.2 19.2V21H21V19.2V17.4V10.2V8.4V3H19.2V8.4H17.4V4C17.4 3.44772 16.9523 3 16.4 3H13.9C13.3477 3 12.9 3.44772 12.9 4V8.4L11.1 8.4V5.8C11.1 5.24772 10.6523 4.8 10.1 4.8L7.6 4.8C7.04772 4.8 6.6 5.24772 6.6 5.8L6.6 8.4L4.8 8.4V3ZM19.2 17.4V10.2L4.8 10.2V17.4L6.6 17.4L6.6 13.9C6.6 13.3477 7.04772 12.9 7.6 12.9H10.1C10.6523 12.9 11.1 13.3477 11.1 13.9V17.4H12.9V15.7C12.9 15.1477 13.3477 14.7 13.9 14.7H16.4C16.9523 14.7 17.4 15.1477 17.4 15.7V17.4H19.2Z"
      fill={color}
    />
  </SVG>
)

ShelfIcon.propTypes = defaultIconPropTypes

ShelfIcon.defaultProps = defaultPictographIconProps
