import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'
import { useTheme } from '../../../utils/useTheme'

const ParcelSmallIcon: React.FC<DefaultIconProps> = (props) => {
  const theme = useTheme()
  return (
    <SVG {...props}>
      <Path
        d="M0 19.5C0 9.97029 5.27703 0 22 0C38.723 0 44 9.97029 44 19.5C44 29.0304 38.723 39 22 39C5.27703 39.0007 0 29.1036 0 19.5Z"
        fill={theme.xyz.color.brandNavy}
      />
      <Path
        d="M13.287 22.736C13.49 26.187 15.694 29.493 22.045 29.493C28.309 29.493 30.687 26.941 30.687 23.142C30.687 19.662 28.773 17.661 24.539 17.255L20.856 16.878C19.377 16.704 18.797 15.979 18.797 14.993C18.797 13.717 19.957 12.934 21.929 12.934C24.104 12.934 25.264 13.949 25.467 15.486H30.513C30.252 12.064 27.874 9.222 21.929 9.222C16.361 9.222 13.635 11.716 13.635 15.341C13.635 18.618 15.636 20.59 19.116 20.996L23.321 21.46C24.771 21.663 25.525 22.301 25.525 23.577C25.525 24.911 24.597 25.781 22.132 25.781C19.435 25.781 18.391 24.331 18.246 22.736H13.287Z"
        fill={theme.xyz.color.brandSky}
      />
    </SVG>
  )
}

ParcelSmallIcon.defaultProps = {
  viewBox: '0 0 44 39',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

export { ParcelSmallIcon }
