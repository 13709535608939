import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const ReceiveParcelIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M15.2058 15.7545C15.2061 15.8024 15.2058 15.9754 15.2058 15.9754C15.2058 15.9754 15.3622 15.9051 15.4053 15.8856L20.6676 13.452C20.7663 13.4064 20.8501 13.3327 20.9088 13.2399C20.9675 13.1471 20.9988 13.0391 20.9988 12.9288V6.5206C20.9988 6.51751 21.0013 6.30038 20.9988 6.2987C20.9962 6.29702 20.8021 6.38821 20.7993 6.38949L15.2906 8.93803C15.2658 8.94928 15.2075 8.98065 15.2075 8.98065C15.2076 9.01435 15.2075 9.04096 15.2075 9.06857L15.2058 15.7545Z"
      fill={color}
    />
    <Path
      d="M12.002 7.98766C11.9805 7.97859 11.9572 7.97504 11.9341 7.97731C11.911 7.97958 11.8888 7.9876 11.8695 8.00068C11.8501 8.01375 11.8343 8.03147 11.8233 8.05227C11.8123 8.07307 11.8065 8.0963 11.8064 8.11992V9.27401C11.8064 9.38839 11.7617 9.49809 11.6823 9.57897C11.6028 9.65985 11.4949 9.70529 11.3825 9.70529C11.2701 9.70529 11.1623 9.65985 11.0828 9.57897C11.0033 9.49809 10.9587 9.38839 10.9587 9.27401V7.64092C10.9591 7.6122 10.951 7.584 10.9355 7.56001C10.92 7.53601 10.8977 7.51732 10.8716 7.50636L8.19555 6.37412C8.15524 6.35676 8.00002 6.29867 8.00002 6.29867C8.00002 6.23443 8.00001 6.50442 8 6.50638V12.9157C7.99999 13.0292 8.03299 13.1401 8.09484 13.2346C8.15669 13.329 8.24463 13.4028 8.34758 13.4464L14.1603 15.9116C14.2033 15.9298 14.3559 16.0003 14.3559 16.0003C14.3559 16.0003 14.3559 15.8261 14.3559 15.7788V9.08195C14.3558 9.05355 14.3559 8.98526 14.3559 8.98526C14.3559 8.98526 14.2946 8.96009 14.2688 8.94912L12.002 7.98766Z"
      fill={color}
    />
    <Path
      d="M16.7035 4.1837C16.7283 4.17233 16.7494 4.15392 16.7642 4.13066C16.779 4.1074 16.7869 4.08029 16.7869 4.05259C16.7869 4.02489 16.779 3.99778 16.7642 3.97452C16.7494 3.95126 16.7283 3.93284 16.7035 3.92148L14.7254 3.04801C14.6541 3.01634 14.5772 3 14.4994 3C14.4216 3 14.3446 3.01634 14.2733 3.04801L8.84373 5.45049C8.81824 5.46154 8.79651 5.47997 8.78123 5.50348C8.76596 5.527 8.75781 5.55456 8.75781 5.58275C8.75781 5.61094 8.76596 5.6385 8.78123 5.66202C8.79651 5.68553 8.81824 5.70396 8.84373 5.71501L11.2214 6.72074C11.2392 6.72876 11.2584 6.7329 11.2779 6.7329C11.2974 6.7329 11.3166 6.72876 11.3344 6.72074L16.7035 4.1837Z"
      fill={color}
    />
    <Path
      d="M14.7175 8.20379C14.7354 8.2116 14.7546 8.21563 14.774 8.21563C14.7935 8.21563 14.8127 8.2116 14.8306 8.20379L20.1861 5.7231C20.2109 5.71174 20.232 5.69332 20.2468 5.67006C20.2616 5.6468 20.2694 5.61969 20.2694 5.59199C20.2694 5.56429 20.2616 5.53718 20.2468 5.51392C20.232 5.49066 20.2109 5.47225 20.1861 5.46088L18.0854 4.53393C18.0675 4.52608 18.0483 4.52203 18.0288 4.52203C18.0094 4.52203 17.9902 4.52608 17.9723 4.53393L12.6281 7.05717C12.6033 7.06901 12.5825 7.0879 12.5681 7.11155C12.5537 7.13521 12.5463 7.16261 12.5469 7.19044C12.5475 7.21827 12.556 7.24532 12.5714 7.26831C12.5869 7.2913 12.6085 7.30924 12.6338 7.31996L14.7175 8.20379Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      d="M4.41438 16.8917L7.59875 20.0761L6.18454 21.4903L1.58595 16.8917L6.18454 12.2931L7.59875 13.7073L4.41438 16.8917Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      d="M3.24441 15.8908L12.0002 15.8908L12.0002 17.8908L3.24441 17.8908L3.24441 15.8908Z"
      fill={color}
    />
  </SVG>
)

ReceiveParcelIcon.propTypes = defaultIconPropTypes

ReceiveParcelIcon.defaultProps = defaultPictographIconProps
