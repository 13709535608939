import React from 'react'

const Rating4Active: React.FC = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 10.3131C0 5.27304 2.79089 0 11.6353 0C20.4796 0 23.2705 5.27304 23.2705 10.3131C23.2705 15.3535 20.4796 20.6261 11.6353 20.6261C2.79089 20.6265 0 15.3922 0 10.3131Z"
      fill="#FFC226"
    />
    <path
      d="M7.93433 6.34681C7.93433 5.76263 8.4079 5.28906 8.99208 5.28906C9.57626 5.28906 10.0498 5.76263 10.0498 6.34681V8.46231C10.0498 9.04649 9.57626 9.52007 8.99208 9.52007C8.4079 9.52007 7.93433 9.04649 7.93433 8.46231V6.34681Z"
      fill="#5C2952"
    />
    <path
      d="M13.223 6.34681C13.223 5.76263 13.6966 5.28906 14.2808 5.28906C14.865 5.28906 15.3385 5.76263 15.3385 6.34681V8.46231C15.3385 9.04649 14.865 9.52007 14.2808 9.52007C13.6966 9.52007 13.223 9.04649 13.223 8.46231V6.34681Z"
      fill="#5C2952"
    />
    <path
      d="M11.6364 15.8666C14.0757 15.8666 16.1463 14.0342 16.8829 12.2603C17.0785 11.7893 16.5543 11.4695 16.1367 11.7274C15.1977 12.3072 13.652 13.0021 11.6364 13.0021C9.62087 13.0021 8.07515 12.3072 7.13616 11.7274C6.71852 11.4695 6.19428 11.7893 6.38988 12.2603C7.12651 14.0342 9.19716 15.8666 11.6364 15.8666Z"
      fill="#5C2952"
    />
  </svg>
)

export default Rating4Active
