import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const CardIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M4 4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4H4ZM16 8C16 7.44772 16.4477 7 17 7H19C19.5523 7 20 7.44772 20 8V9C20 9.55228 19.5523 10 19 10H17C16.4477 10 16 9.55228 16 9V8ZM4 15C4 14.4477 4.44772 14 5 14H8C8.55228 14 9 14.4477 9 15C9 15.5523 8.55228 16 8 16H5C4.44772 16 4 15.5523 4 15ZM11 14C10.4477 14 10 14.4477 10 15C10 15.5523 10.4477 16 11 16H16C16.5523 16 17 15.5523 17 15C17 14.4477 16.5523 14 16 14H11ZM18 15C18 14.4477 18.4477 14 19 14C19.5523 14 20 14.4477 20 15C20 15.5523 19.5523 16 19 16C18.4477 16 18 15.5523 18 15Z"
      fill={color}
    />
  </SVG>
)

CardIcon.propTypes = defaultIconPropTypes

CardIcon.defaultProps = defaultPictographIconProps
