import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const LockIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M17.53 10.13h-.56V8.05a5.1 5.1 0 00-1.45-3.58 4.93 4.93 0 00-7.04 0 5.1 5.1 0 00-1.45 3.58v2.06H5V19.5c0 .4.16.78.43 1.06.28.28.65.44 1.04.44h11.06c.39 0 .76-.16 1.04-.44.27-.28.43-.66.43-1.06v-7.88-1.5h-1.47zm-7 4.5a1.52 1.52 0 01.73-1.3 1.46 1.46 0 012.01.54 1.52 1.52 0 01-.53 2.05v1.7c0 .2-.08.4-.22.54a.73.73 0 01-1.04 0 .76.76 0 01-.22-.54v-1.7a1.48 1.48 0 01-.73-1.3zM8.87 8.05c0-.84.33-1.65.92-2.25a3.1 3.1 0 014.42 0c.6.6.92 1.4.92 2.25v2.07H8.87V8.05z"
      fill={color}
    />
  </SVG>
)

LockIcon.propTypes = defaultIconPropTypes

LockIcon.defaultProps = defaultPictographIconProps

export { LockIcon }
