import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'

import { CheckmarkIcon } from '../../design-tokens/icons'
import { nextItem, previousItem, ownerDocument, moveFocus, KeyboardKeys } from '../../utils/keyboardNavigation'
import { useTheme } from '../../utils/useTheme'

const FilterTagDropdownList = styled.ul<{ width?: string }>`
  position: absolute;
  overflow: hidden;
  width: ${({ width }) => width};
  z-index: 1;
  margin: 0;
  padding: 0;
  ${({ theme }) => css`
    box-shadow: 0 0 0.5rem 0 ${theme.color.neutralPassiveGray};
    font-weight: ${theme.fontWeight.medium};
    color: ${theme.color.neutralNetworkGray};
    background-color: ${theme.color.neutralWhite};
  `}

  &:focus {
    outline: 0;
  }
`

const FilterTagDropdownListItem = styled.li`
  height: 100%;
  width: 100%;
  list-style: none;
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${({ theme }) => css`
    background-color: ${theme.color.neutralWhite};
    &:focus,
    &:hover {
      background-color: ${theme.color.neutralGray5};
      color: ${theme.color.signalBlue};
      outline: 0;
    }
  `}
`

const Label = styled.span`
  width: calc(100% - 1rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSize.text.sm}rem;
  `}
`

export interface OptionObject extends React.HtmlHTMLAttributes<HTMLLIElement> {
  value: any
  label: string
}

interface TagDropdownItemProps extends React.HtmlHTMLAttributes<HTMLLIElement> {
  item: OptionObject
  onItemClick: (item: OptionObject) => void
  selected: boolean
}

/**
 *
 * @deprecated component is currently deprecated.
 */
const FilterTagDropdownItem: React.FC<TagDropdownItemProps> = (props) => {
  const { item, onItemClick, selected } = props
  const { label, ...rest } = item
  const theme = useTheme()
  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space) {
      e.preventDefault()
      onItemClick(item)
    }
  }

  const handleClick = () => {
    onItemClick(item)
  }

  return (
    <FilterTagDropdownListItem
      onClick={handleClick}
      onKeyPress={onKeyPress}
      role="option"
      aria-selected={selected}
      tabIndex={1}
      {...rest}
    >
      <Label>{label}</Label>
      {selected && (
        <CheckmarkIcon
          width={`${theme.size.icon.xs}em`}
          height={`${theme.size.icon.xs}em`}
          color={theme.color.signalBlue}
        />
      )}
    </FilterTagDropdownListItem>
  )
}

export interface TagDropdownChildrenProps {
  options: OptionObject[]
  onItemClick: (item: OptionObject) => void
  onEscKeyPressed: () => void
  selectedItem: OptionObject
  width?: string
}

/**
 *
 * @deprecated component is currently deprecated.
 */
const FilterTagDropdownChildren: React.FC<TagDropdownChildrenProps> = (props) => {
  const { options, selectedItem, onItemClick, onEscKeyPressed, width, ...rest } = props
  const optionsRef: React.RefObject<any> = React.createRef()
  const createDropdownItems = options.map((item, index: number) => {
    return (
      <FilterTagDropdownItem
        key={index}
        item={item}
        selected={item.label === selectedItem.label}
        onItemClick={onItemClick}
      />
    )
  })

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const key = e.key
    const list = optionsRef.current
    const currentFocus = ownerDocument(list).activeElement

    if (key === KeyboardKeys.ArrowDown || key === KeyboardKeys.Home) {
      e.preventDefault()
      moveFocus(list, currentFocus, true, nextItem)
    } else if (key === KeyboardKeys.ArrowUp || key === KeyboardKeys.End) {
      e.preventDefault()
      moveFocus(list, currentFocus, true, previousItem)
    } else if (key === KeyboardKeys.Tab) {
      e.preventDefault()
    } else if (key === KeyboardKeys.Escape) {
      e.preventDefault()
      onEscKeyPressed()
    }
  }

  setTimeout(() => {
    optionsRef.current.focus()
  }, 50)

  return (
    <FilterTagDropdownList
      width={width}
      onKeyDown={handleKeyDown}
      role="listbox"
      ref={optionsRef}
      tabIndex={0}
      {...rest}
    >
      {createDropdownItems}
    </FilterTagDropdownList>
  )
}

FilterTagDropdownChildren.defaultProps = {
  width: '100%',
}

FilterTagDropdownChildren.propTypes = {
  selectedItem: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }) as Validator<OptionObject>,
  onItemClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }) as Validator<OptionObject>
  ).isRequired,
  width: PropTypes.string,
}

export default FilterTagDropdownChildren
