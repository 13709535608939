import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const CameraIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M14.0616 15.7273C15.6142 15.7273 16.8728 14.4409 16.8728 12.8539C16.8728 11.267 15.6142 9.98053 14.0616 9.98053C12.509 9.98053 11.2504 11.267 11.2504 12.8539C11.2504 14.4409 12.509 15.7273 14.0616 15.7273Z"
      fill={color}
    />
    <Path
      d="M19.0798 7.49025H18.0933C18.0143 7.48738 17.8684 7.49025 17.8684 7.49025L15.7483 5.19153H12C11.6251 5.47887 10.5006 6.62823 9.60457 7.49025H4.87596C3.32941 7.49025 3.00406 8.41663 3.00406 9.19359V17.1533C2.98666 17.4095 3.02538 17.6664 3.11738 17.9053C3.20939 18.1443 3.35237 18.3593 3.53592 18.5346C3.71947 18.71 3.93897 18.8413 4.17843 18.919C4.41789 18.9967 4.6713 19.0189 4.92019 18.9838H19.0798C19.3287 19.0189 19.5821 18.9967 19.8216 18.919C20.061 18.8413 20.2805 18.71 20.4641 18.5346C20.6476 18.3593 20.7906 18.1443 20.8826 17.9053C20.9746 17.6664 21.0133 17.4095 20.9959 17.1533V9.19359C20.9959 8.41663 20.6638 7.49025 19.0798 7.49025ZM18.3721 12.8539C18.3721 13.7253 18.1193 14.5771 17.6457 15.3017C17.172 16.0262 16.4988 16.5909 15.7112 16.9244C14.9235 17.2579 14.0568 17.3451 13.2206 17.1751C12.3845 17.0051 11.6164 16.5855 11.0135 15.9693C10.4107 15.3532 10.0002 14.5681 9.83384 13.7135C9.66752 12.8588 9.75288 11.9729 10.0791 11.1679C10.4054 10.3628 10.9579 9.67469 11.6668 9.19057C12.3756 8.70644 13.209 8.44804 14.0616 8.44804C15.2044 8.44926 16.3002 8.91384 17.1083 9.73984C17.9164 10.5658 18.3709 11.6858 18.3721 12.8539ZM6.75237 10.3636C6.75237 10.5531 6.69741 10.7383 6.59444 10.8958C6.49147 11.0533 6.34512 11.176 6.17389 11.2485C6.00266 11.321 5.81425 11.34 5.63247 11.303C5.4507 11.2661 5.28373 11.1749 5.15267 11.0409C5.02162 10.907 4.93237 10.7363 4.89622 10.5505C4.86006 10.3647 4.87862 10.1721 4.94954 9.99711C5.02047 9.82209 5.14057 9.6725 5.29468 9.56726C5.44878 9.46202 5.62995 9.40584 5.81529 9.40584C6.06382 9.40584 6.30217 9.50675 6.4779 9.68638C6.65364 9.866 6.75237 10.1096 6.75237 10.3636Z"
      fill={color}
    />
    <Path
      d="M4.87813 6.71178L7.12711 6.72404C7.22652 6.72404 7.38774 6.72404 7.50194 6.72404C7.50194 6.61182 7.50194 6.44253 7.50194 6.34092V6.14936C7.50194 5.84453 7.38347 5.55219 7.17259 5.33664C6.9617 5.12109 6.67569 5 6.37745 5H5.62779C5.32955 5 5.04354 5.12109 4.83265 5.33664C4.62177 5.55219 4.5033 5.84453 4.5033 6.14936V6.32866C4.5033 6.43027 4.50322 6.62826 4.50332 6.71178C4.57658 6.71178 4.77872 6.71178 4.87813 6.71178Z"
      fill={color}
    />
  </SVG>
)

CameraIcon.propTypes = defaultIconPropTypes

CameraIcon.defaultProps = defaultPictographIconProps
