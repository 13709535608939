import React, { HTMLAttributes } from 'react'
import PropTypes, { Validator } from 'prop-types'
import styled, { css } from 'styled-components'

export enum ColorScheme {
  gray = 'gray',
  white = 'white',
}

export interface AllProps extends HTMLAttributes<HTMLDivElement> {
  scheme: keyof typeof ColorScheme
  selected: boolean
  highLightBorder?: boolean
  classNames?: string
}

const StyledContainer = styled.div<{
  scheme: keyof typeof ColorScheme
  selected: boolean
  highLightBorder?: boolean
  classNames?: string
}>(
  ({ theme, scheme, selected, highLightBorder, classNames }) => css`
    ${selected &&
    css`
      background-color: ${scheme === ColorScheme.gray ? theme.color.neutralWhite : theme.color.neutralGray2};

      border: 0.125rem solid
        ${highLightBorder
          ? theme.color.signalBlue
          : scheme === ColorScheme.gray
          ? theme.color.neutralGray2
          : theme.color.neutralWhite};
    `}

    ${!selected &&
    css`
      background-color: ${scheme === ColorScheme.gray ? theme.color.neutralGray2 : theme.color.neutralWhite};
      border: 0.125rem solid ${scheme === ColorScheme.gray ? theme.color.neutralGray2 : theme.color.neutralWhite};
      border-bottom-color: ${theme.color.neutralPassiveGray};
    `}

    border-radius: 0.25rem;
    transition: all 200ms ease-in;

    ${classNames}
  `
)

/**
 *
 * @deprecated please use Expander component instead.
 */
const Selection: React.FC<AllProps> = (props) => {
  const { children, ...rest } = props

  return <StyledContainer {...rest}>{children}</StyledContainer>
}

Selection.propTypes = {
  scheme: PropTypes.oneOf(Object.keys(ColorScheme)) as Validator<ColorScheme>,
  selected: PropTypes.bool.isRequired,
  highLightBorder: PropTypes.bool,
  classNames: PropTypes.string,
}

Selection.defaultProps = {
  scheme: ColorScheme.gray,
  selected: false,
  highLightBorder: true,
  classNames: '',
}

export default Selection
