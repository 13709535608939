import styled, { css } from 'styled-components'
import Button from '../Button'

export const StyledContainer = styled.div`
  ${({
    theme: {
      xyz: { zIndex },
    },
  }) => css`
    position: relative;
    display: block;
    width: 100%;
    z-index: ${zIndex.zIndexDefault};

    & > div {
      position: relative;
    }
  `}
`

export const StyledFilterList = styled.div`
  ${({
    theme: {
      xyz: { color, spacing, borderRadius },
    },
  }) => css`
    background-color: ${color.neutralWhite};
    list-style: none;
    margin: 0;
    padding: ${spacing.space3}rem;
    box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    border-radius: ${borderRadius.sm}rem;
    position: absolute;
    outline: none;
    min-width: 17.4375rem;
  `}
`

export const StyledGroupLabel = styled.div`
  ${({
    theme: {
      xyz: { color, spacing },
    },
  }) => css`
    padding-bottom: ${spacing.space3}rem;
    color: ${color.neutralBlack};

    &:not(:first-of-type) {
      margin-top: ${spacing.space3}rem;
    }
  `}
`

export const StyledButton = styled(Button)`
  margin-left: auto;
`
