import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Circle } from '../icons.styled'

export const ServicesIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Circle cx="19" cy="12" r="3" fill={color} />
    <Circle cx="12" cy="5" r="3" fill={color} />
    <Circle cx="5" cy="12" r="3" fill={color} />
    <Circle cx="12" cy="19" r="3" fill={color} />
  </SVG>
)

ServicesIcon.propTypes = defaultIconPropTypes

ServicesIcon.defaultProps = defaultPictographIconProps
