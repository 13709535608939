import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'
import { useTheme } from '../../../utils/useTheme'

const ParcelXXLIcon: React.FC<DefaultIconProps> = (props) => {
  const theme = useTheme()
  return (
    <SVG {...props}>
      <Path
        d="M0 19.5C0 9.97029 5.27703 0 22 0C38.723 0 44 9.97029 44 19.5C44 29.0304 38.723 39 22 39C5.27703 39.0007 0 29.1036 0 19.5Z"
        fill={theme.xyz.color.brandForest}
      />
      <Path
        d="M13.2937 25L10.9097 21.992L8.78169 25H4.87769L9.05369 19.672L4.82969 14.36H8.76569L11.1177 17.304L13.2137 14.36H17.0697L12.9737 19.624L17.2777 25H13.2937ZM26.4656 25L24.0816 21.992L21.9536 25H18.0496L22.2256 19.672L18.0016 14.36H21.9376L24.2896 17.304L26.3856 14.36H30.2416L26.1456 19.624L30.4496 25H26.4656ZM39.0849 22.472V25H31.2609V14.36H34.5089V22.472H39.0849Z"
        fill={theme.xyz.color.brandCardboard}
      />
    </SVG>
  )
}

ParcelXXLIcon.defaultProps = {
  viewBox: '0 0 44 39',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

export { ParcelXXLIcon }
