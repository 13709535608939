import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const ClockIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M2 12C2 6.48 6.47 2 11.99 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 11.99 22C6.47 22 2 17.52 2 12ZM11 7H12.5V12.25L17 14.92L16.25 16.15L11 13V7Z"
      fill={color}
    />
  </SVG>
)

ClockIcon.propTypes = defaultIconPropTypes

ClockIcon.defaultProps = defaultPictographIconProps

export { ClockIcon }
