import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import styled, { css } from 'styled-components'
import { XyzThemeColor } from '../../../utils/helpers'
import { XyzTheme } from '@postidigital/posti-theme'

export enum BodySize {
  /**
   * 20px
   */
  One = 'One',
  /**
   * 18px
   */
  Two = 'Two',
  /**
   * 16px
   */
  Three = 'Three',
  /**
   * 14px
   */
  Four = 'Four',
  /**
   * 13px
   */
  Five = 'Five',
}

export interface BodyProps extends React.HtmlHTMLAttributes<HTMLParagraphElement> {
  /**
   * The size of the body text. Use the exported enum BodySize for the values or just a
   * plain string matching one of the keys
   */
  size: BodySize | keyof typeof BodySize
  color?: XyzThemeColor | string
}

export const Body = styled.p<BodyProps>(
  ({ theme, size, color }) => css`
    ${size === BodySize.One &&
    `
      font-family: ${theme.xyz.fontFamily.Montserrat};
      font-weight: ${theme.xyz.fontWeight.normal};
      font-size: ${theme.xyz.fontSize.body.one}rem;
      line-height: ${theme.xyz.lineHeight.body.one};
      color: ${theme.xyz[color] || color};
      `}
    ${size === BodySize.Two &&
    `
      font-family: ${theme.xyz.fontFamily.Montserrat};
      font-weight: ${theme.xyz.fontWeight.normal};
      font-size: ${theme.xyz.fontSize.body.two}rem;
      line-height: ${theme.xyz.lineHeight.body.two};
      color: ${theme.xyz[color] || color};
      `}
    
    ${size === BodySize.Three &&
    `
      font-family: ${theme.xyz.fontFamily.Montserrat};
      font-weight: ${theme.xyz.fontWeight.normal};
      font-size: ${theme.xyz.fontSize.body.three}rem;
      line-height: ${theme.xyz.lineHeight.body.three};
      color: ${theme.xyz[color] || color};
      `}
    
    ${size === BodySize.Four &&
    `
      font-family: ${theme.xyz.fontFamily.Montserrat};
      font-weight: ${theme.xyz.fontWeight.normal};
      font-size: ${theme.xyz.fontSize.body.four}rem;
      line-height: ${theme.xyz.lineHeight.body.four};
      color: ${theme.xyz[color] || color};
      `}    
    
    ${size === BodySize.Five &&
    `
      font-family: ${theme.xyz.fontFamily.Montserrat};
      font-weight: ${theme.xyz.fontWeight.normal};
      font-size: ${theme.xyz.fontSize.body.five}rem;
      line-height: ${theme.xyz.lineHeight.body.five};
      color: ${theme.xyz[color] || color};
      `}
  `
)

Body.propTypes = {
  size: PropTypes.oneOf(Object.keys(BodySize)).isRequired as Validator<BodySize>,
  color: PropTypes.string,
}

Body.defaultProps = {
  color: XyzTheme.color.neutralNetworkGray,
}
