import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const ArrowRightIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M17.2966 12L11.2394 5.87299L13.091 4L20.9999 12L13.091 20L11.2394 18.127L17.2966 12Z"
      fill={color}
    />
    <Path fillRule="evenodd" d="M18.7119 13.3245L3 13.3245L3 10.6757L18.7119 10.6757L18.7119 13.3245Z" fill={color} />
  </SVG>
)

ArrowRightIcon.propTypes = defaultIconPropTypes

ArrowRightIcon.defaultProps = defaultPictographIconProps
