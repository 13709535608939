import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const LogoutIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M8.53567 13.9565C8.18412 13.9568 7.8369 13.8798 7.51912 13.731C7.20134 13.5822 6.92096 13.3653 6.69823 13.0962C6.4755 12.827 6.31601 12.5122 6.23128 12.1746C6.14655 11.8369 6.13871 11.4848 6.20831 11.1438C6.3297 10.6025 6.63645 10.1193 7.07659 9.77615C7.51673 9.43299 8.06321 9.25095 8.62345 9.26087H14.0713C14.1762 9.26087 14.4667 9.26087 14.4667 9.26087C14.4667 9.26087 14.4667 8.97335 14.4667 8.86957V3.78261C14.4667 3.57505 14.4667 3 14.4667 3C14.4667 3 13.8857 3 13.6759 3H3.79081C3.58107 3 3.37993 3.08245 3.23162 3.22922C3.08332 3.37599 3 3.57505 3 3.78261V20.2174C3 20.425 3.08332 20.624 3.23162 20.7708C3.37993 20.9175 3.58107 21 3.79081 21H13.6759C13.8857 21 14.4667 21 14.4667 21C14.4667 21 14.4667 20.425 14.4667 20.2174V14.3478C14.4667 14.244 14.4667 13.9565 14.4667 13.9565C14.4667 13.9565 14.1762 13.9565 14.0713 13.9565H8.53567Z"
      fill={color}
    />
    <Path
      fillRule="nonzero"
      d="M20 11.6084L20.7034 12.3192L21.4216 11.6084L20.7034 10.8976L20 11.6084ZM15.5349 8.59652L19.2966 12.3192L20.7034 10.8976L16.9417 7.17496L15.5349 8.59652ZM19.2966 10.8976L15.5349 14.6203L16.9417 16.0419L20.7034 12.3192L19.2966 10.8976Z"
      fill={color}
    />
    <Path
      d="M8.34705 10.6084C7.79476 10.6084 7.34705 11.0561 7.34705 11.6084C7.34705 12.1607 7.79476 12.6084 8.34705 12.6084L8.34705 10.6084ZM8.34705 12.6084L18.967 12.6084L18.967 10.6084L8.34705 10.6084L8.34705 12.6084Z"
      fill={color}
    />
  </SVG>
)

LogoutIcon.propTypes = defaultIconPropTypes

LogoutIcon.defaultProps = defaultPictographIconProps
