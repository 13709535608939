import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const BusinessIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M17.447 9.755c-.292-.015-1.322-.05-1.322-1.255V7.125a4.125 4.125 0 00-8.25 0V8.5c0 1.189-1.033 1.24-1.322 1.255A3.754 3.754 0 003 13.5v4.875A2.625 2.625 0 005.625 21h12.75A2.625 2.625 0 0021 18.375V13.5a3.755 3.755 0 00-3.553-3.745zm-8.072-2.63a2.625 2.625 0 015.25 0v2.438a.188.188 0 01-.188.187H9.564a.187.187 0 01-.188-.188V7.126zm9 6.55a.562.562 0 110 1.125H9a.188.188 0 00-.188.188v.762a.562.562 0 11-1.124 0v-.762A.188.188 0 007.5 14.8H5.625a.563.563 0 010-1.125h12.75z"
      fill={color}
    />
  </SVG>
)

BusinessIcon.propTypes = defaultIconPropTypes

BusinessIcon.defaultProps = defaultPictographIconProps

export { BusinessIcon }
