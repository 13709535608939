import styled, { css } from 'styled-components'

export const StyledTab = styled.button<{ selected: boolean; disabled: boolean }>(
  ({
    theme: {
      xyz: { color, fontWeight, spacing },
    },
    selected,
    disabled,
  }) => css`
    padding: ${spacing.space2}rem;
    border-radius: 0.25rem;
    outline: none;
    border: 0;
    background-color: transparent;

    &:focus {
      outline: none;
      box-shadow: 0px 0px 7px ${color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    ${selected &&
    css`
      font-weight: ${fontWeight.semiBold};
    `}

    ${disabled &&
    css`
      color: ${color.neutralPassiveGray};
    `}
  `
)
