import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const UpdateIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M7.03 11.14L8.9 9.88l.88 1.3-3.84 2.58L3 10.21l1.21-1 1.29 1.56a6.56 6.56 0 0111.84-2.43l-1.3.88a4.98 4.98 0 00-9.01 1.92zm11.12-.9L21 13.77l-1.22.98-1.38-1.7a6.57 6.57 0 01-12 2.43l1.33-.83a5 5 0 009.09-1.67l-1.73 1.12-.86-1.3 3.92-2.54z"
      fill={color}
    />
  </SVG>
)

UpdateIcon.propTypes = defaultIconPropTypes

UpdateIcon.defaultProps = defaultPictographIconProps

export { UpdateIcon }
