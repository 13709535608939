import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const FilterTagDropdownContainer = styled.div`
  display: flex;
`
export interface Props {
  children: JSX.Element
  isOpen: boolean
}

/**
 *
 * @deprecated component is currently deprecated.
 */
const FilterTagDropdown: React.FC<Props> = (props) => {
  const { isOpen, children } = props

  return <FilterTagDropdownContainer aria-expanded={isOpen}>{isOpen && children}</FilterTagDropdownContainer>
}

FilterTagDropdown.propTypes = {
  children: PropTypes.any.isRequired,
}

export default FilterTagDropdown
