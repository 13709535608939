import React from 'react'

const Rating5Active: React.FC = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 10.3131C0 5.27304 2.79089 0 11.6353 0C20.4796 0 23.2705 5.27304 23.2705 10.3131C23.2705 15.3535 20.4796 20.6261 11.6353 20.6261C2.79089 20.6265 0 15.3922 0 10.3131Z"
      fill="#FFC226"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0303 7.54436C15.612 7.36018 15.1235 7.55 14.9394 7.96832C14.7552 8.38665 14.2668 8.57646 13.8484 8.39228C13.4301 8.2081 13.2403 7.71968 13.4245 7.30135C13.977 6.04638 15.4423 5.47694 16.6972 6.02947C17.3703 6.3258 17.8465 6.88597 18.0574 7.53236C18.1991 7.9669 17.9618 8.43407 17.5273 8.57582C17.0927 8.71757 16.6255 8.48021 16.4838 8.04567C16.4128 7.82797 16.2545 7.6431 16.0303 7.54436ZM7.92989 7.51399C7.48839 7.39569 7.03458 7.65769 6.91628 8.09919C6.79798 8.54069 6.34417 8.8027 5.90267 8.6844C5.46117 8.5661 5.19917 8.11229 5.31747 7.67079C5.67237 6.34629 7.03379 5.56027 8.35829 5.91517C9.06864 6.10551 9.62465 6.58659 9.93151 7.19331C10.1378 7.60118 9.9744 8.09906 9.56652 8.30535C9.15865 8.51165 8.66077 8.34824 8.45448 7.94036C8.35112 7.73601 8.16657 7.57741 7.92989 7.51399Z"
      fill="#5C2952"
    />
    <path
      d="M11.6349 16.0115C14.1796 16.0115 16.3398 14.1 17.1083 12.2494C17.3123 11.758 16.7654 11.4243 16.3297 11.6934C15.3501 12.2983 13.7376 13.0232 11.6349 13.0232C9.53226 13.0232 7.91973 12.2983 6.94014 11.6934C6.50445 11.4243 5.95755 11.758 6.1616 12.2494C6.93008 14.1 9.09023 16.0115 11.6349 16.0115Z"
      fill="#5C2952"
    />
  </svg>
)

export default Rating5Active
