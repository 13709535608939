import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const ChevronDownIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M12 13.8787L17.9393 7.93933L20.0607 10.0607L12 18.1213L3.93935 10.0607L6.06067 7.93933L12 13.8787Z"
      fill={color}
    />
  </SVG>
)

ChevronDownIcon.propTypes = defaultIconPropTypes

ChevronDownIcon.defaultProps = defaultPictographIconProps
