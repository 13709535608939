export const THUMB_WIDTH = 32
export const TOOLTIP_OFFSET_Y = 8

export const isValidNumber = (value: number): boolean => {
  return typeof value === 'number' && isFinite(value)
}

export const clamp = (value: number, min: number, max: number): number => {
  return Math.min(Math.max(value, min), max)
}
