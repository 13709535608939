import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const InboxIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M16.1344 16.0002C15.7181 16.6685 15.1164 17.2241 14.3903 17.6105C13.6642 17.9969 12.8396 18.2004 12 18.2004C11.1604 18.2004 10.3358 17.9969 9.60967 17.6105C8.88358 17.2241 8.28188 16.6685 7.8656 16.0002H4.2C3.88174 16.0002 3 16 3 16C3 16 3 16.8084 3 17.1002V20.4C3 20.6918 3.12643 20.9715 3.35147 21.1778C3.57652 21.3841 3.88174 21.5 4.2 21.5H19.8C20.1183 21.5 20.4235 21.3841 20.6485 21.1778C20.8736 20.9715 21 20.6918 21 20.4V17.1002C21 16.8084 21 16.3928 21 16C21 16 20.1183 16.0002 19.8 16.0002H16.1344Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      d="M21 2.5H3V12C3 13.1046 3.89543 14 5 14H19C20.1046 14 21 13.1046 21 12V2.5ZM5 4.34H12H19V8.48H16.4652C16.1884 10.2953 14.2948 11.7 12 11.7C9.70521 11.7 7.81162 10.2953 7.53482 8.48H5V4.34Z"
      fill={color}
    />
  </SVG>
)

InboxIcon.propTypes = defaultIconPropTypes

InboxIcon.defaultProps = defaultPictographIconProps
