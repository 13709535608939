import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const DiamondIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M16.6 9.44h-.14l-.04.14-2.6 9.22-.37 1.22s.66-.99.72-1.06l6.6-9.22.23-.3H16.6zM15.2 9.68l.05-.24H8.73l.07.24 3.01 10.73.2.59.17-.6L15.2 9.69zM14.25 8.3h.3l-.13-.28-2.29-4.78A4.7 4.7 0 0012 3l-.13.23-2.3 4.8-.12.26h4.8zM9.83 18.96l.71 1.01-.37-1.18-2.6-9.21c0-.04-.06-.14-.06-.14h-4.5l.21.3 6.61 9.22zM16.85 8.05l-.07.24H21l-.24-.3-2.6-3.5-.21-.27-.12.34-.98 3.5zM6.97 8.3h.24l-.06-.25-.98-3.49-.1-.4-.24.34-2.6 3.49-.23.3h3.97zM8.19 7.5l.14.45.21-.42 1.96-4.1.12-.28H6.96l.07.24 1.16 4.1zM15.45 7.53l.23.47.13-.5 1.15-4.11.08-.24H13.36l.13.27 1.96 4.1z"
      fill={color}
    />
  </SVG>
)

DiamondIcon.propTypes = defaultIconPropTypes

DiamondIcon.defaultProps = defaultPictographIconProps

export { DiamondIcon }
