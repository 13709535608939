import styled, { css } from 'styled-components'
import { CheckboxProps, CheckboxLabelPosition } from './Checkbox'

export const StyledBox = styled.div<Partial<CheckboxProps>>`
  display: inline-block;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  vertical-align: middle;
  margin-right: ${({
    theme: {
      xyz: { spacing },
    },
  }) => spacing.space3}rem;

  ${({ large }) =>
    large
      ? `
    width: 2rem;
    height: 2rem;
  `
      : `
    width: 1.5rem;
    height: 1.5rem;
  `}
`

export const StyledCheck = styled.div<Partial<CheckboxProps>>(
  ({
    theme: {
      xyz: { spacing, color, borderRadius },
    },
    defaultColor,
    disabled,
  }) => css`
    width: 100%;
    height: 100%;
    border: 3px solid ${disabled ? color.neutralGray5 : defaultColor};
    border-radius: ${borderRadius.xs}rem;
    transition: all 200ms ease-in;
    background-color: ${color.neutralWhite};
    display: flex;
    align-items: center;
    justify-content: center;
  `
)

export const StyledInput = styled.input<Partial<CheckboxProps>>(
  ({
    hoverColor,
    selectedColor,
    disabled,
    theme: {
      xyz: { zIndex, color },
    },
  }) => css`
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: ${zIndex.zIndexDefault};
    position: absolute;

    &:hover + ${StyledCheck} {
      border-color: ${disabled ? color.neutralGray5 : hoverColor};
    }

    &:checked + ${StyledCheck} {
      border-color: ${disabled ? color.neutralGray5 : selectedColor};
      background-color: ${disabled ? color.neutralGray5 : selectedColor};
    }

    &:focus + ${StyledCheck} {
      border-color: ${selectedColor};
      filter: drop-shadow(0px 0px 7px ${selectedColor});
    }

    &:focus:not(:focus-visible) + ${StyledCheck} {
      outline: none;
      filter: none;
    }
  `
)

export const StyledLabel = styled.label<{ labelPosition: CheckboxLabelPosition | keyof typeof CheckboxLabelPosition }>(
  ({
    theme: {
      xyz: { spacing },
    },
    labelPosition,
  }) => css`
    ${labelPosition === CheckboxLabelPosition.left &&
    `
    ${StyledBox} {
      margin-left: 3.875rem;
    }
  `}

    ${labelPosition === CheckboxLabelPosition.right &&
    `
    ${StyledBox} {
      margin-right: ${spacing.space3}rem;
    }
  `}
  `
)

export const StyledRoot = styled.div`
  display: inline-block;
`
