import React from 'react'
import { DefaultIconProps, defaultIconPropTypes } from '../icons.types'
import { SVG, Circle } from '../icons.styled'
import { XyzTheme } from '@postidigital/posti-theme'

const DotIcon: React.FC<DefaultIconProps> = ({ color, ...rest }) => (
  <SVG {...rest}>
    <Circle cx="4" cy="4" r="4" fill={color} />
  </SVG>
)

DotIcon.defaultProps = {
  viewBox: '0 0 8 8',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
  color: XyzTheme.color.signalBlue,
}

DotIcon.propTypes = defaultIconPropTypes

export { DotIcon }
