import React, { FC } from 'react'
import { inboxIconDefaultProps, InboxIconProps, inboxIconPropTypes, InvertibleColorScheme } from '../icons.types'
import { InboxIconSvg, Path, Rect, useInvertedColors } from '../icons.styled'
import { XyzTheme } from '@postidigital/posti-theme'

const colorScheme: InvertibleColorScheme = {
  primary: XyzTheme.color.neutralIconGray,
  secondary: '#F1F1F2',
}

const ParcelIncomingInboxIcon: FC<InboxIconProps> = ({ letter, inverted, size, onClick, ...props }) => {
  const { primary, secondary } = useInvertedColors(colorScheme, inverted)

  return (
    <InboxIconSvg size={size} onClick={onClick} {...props}>
      <Rect
        x="1"
        y="10"
        width="30"
        height="31"
        rx="8"
        fill={secondary}
        stroke={primary}
        strokeWidth="2"
        strokeMiterlimit="1.24"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeDasharray="1 3"
      />
      <text fill={primary} fontSize="19" fontWeight="bold">
        <tspan x="50%" y="32.31" textAnchor="middle">
          {letter}
        </tspan>
      </text>
      <Path
        d="M20.38 2.83c.28 1.51-.04 2.72-.87 3.31-.56.4-1.29.45-1.86.14l-.07-.05a4.03 4.03 0 001.83-2.15c.62-1.5.65-3.33-.54-3.92-.37-.2-.8-.21-1.24-.05-.6.22-1.3.88-1.62 1.73A3.27 3.27 0 0014.38.1a1.53 1.53 0 00-1.24.05c-1.18.59-1.16 2.42-.54 3.92a4 4 0 001.83 2.15l-.07.05c-.57.31-1.3.26-1.86-.14-.83-.6-1.15-1.8-.87-3.3h-1.55c-.3 1.98.25 3.63 1.53 4.53.58.42 1.27.63 1.95.63A3.14 3.14 0 0016 6.85 3.03 3.03 0 0018.44 8a3.4 3.4 0 001.95-.63c1.28-.9 1.83-2.54 1.53-4.53h-1.54zM15.25 4.4v.09a.9.9 0 01-.1.4c-.2-.04-.73-.46-1.12-1.38-.44-1.05-.32-1.83-.2-1.98.19.04.73.46 1.12 1.39.21.5.28.95.3 1.28v.2zm1.64.49c-.06-.08-.1-.25-.1-.5V4.2c0-.34.08-.78.3-1.3.38-.91.91-1.33 1.1-1.38.13.18.24.95-.18 1.98-.4.9-.93 1.33-1.12 1.38z"
        fill={primary}
      />
    </InboxIconSvg>
  )
}

ParcelIncomingInboxIcon.propTypes = inboxIconPropTypes
ParcelIncomingInboxIcon.defaultProps = inboxIconDefaultProps

export { ParcelIncomingInboxIcon }
