import React from 'react'

const Rating1Active: React.FC = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 10.3131C0 5.27304 2.79089 0 11.6353 0C20.4796 0 23.2705 5.27304 23.2705 10.3131C23.2705 15.3535 20.4796 20.6261 11.6353 20.6261C2.79089 20.6265 0 15.3922 0 10.3131Z"
      fill="#FFA894"
    />
    <path
      d="M11.6359 11.9004C9.19668 11.9004 7.12603 13.7328 6.3894 15.5067C6.1938 15.9777 6.71804 16.2975 7.13568 16.0396C8.07467 15.4598 9.62039 14.7649 11.6359 14.7649C13.6515 14.7649 15.1972 15.4598 16.1362 16.0396C16.5538 16.2975 17.0781 15.9777 16.8825 15.5067C16.1458 13.7328 14.0752 11.9004 11.6359 11.9004Z"
      fill="#7D2332"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0494 8.19866C10.0494 8.47732 9.90323 8.73555 9.66428 8.87892L7.0199 10.4655C6.64421 10.691 6.15691 10.5691 5.93149 10.1934C5.70607 9.81774 5.8279 9.33044 6.20359 9.10502L7.7142 8.19866L6.20359 7.29229C5.8279 7.06687 5.70607 6.57957 5.93149 6.20387C6.15691 5.82818 6.64421 5.70635 7.01991 5.93177L9.66428 7.51839C9.90323 7.66176 10.0494 7.91999 10.0494 8.19866Z"
      fill="#7D2332"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2228 8.19842C13.2228 7.91976 13.369 7.66153 13.6079 7.51816L16.2523 5.93153C16.628 5.70611 17.1153 5.82794 17.3407 6.20363C17.5661 6.57933 17.4443 7.06663 17.0686 7.29205L15.558 8.19842L17.0686 9.10478C17.4443 9.3302 17.5661 9.8175 17.3407 10.1932C17.1153 10.5689 16.628 10.6907 16.2523 10.4653L13.6079 8.87868C13.369 8.73531 13.2228 8.47708 13.2228 8.19842Z"
      fill="#7D2332"
    />
  </svg>
)

export default Rating1Active
