import styled, { css } from 'styled-components'

export const CardContainer = styled.svg`
  ${({
    theme: {
      xyz: { fontFamily, fontWeight, color, fontSize },
    },
  }) =>
    css`
      text {
        font-family: ${fontFamily.Montserrat};
        font-weight: ${fontWeight.medium};
        fill: ${color.neutralNetworkGray};
        font-size: ${fontSize.body.four}rem;
      }
    `}
`
