import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const DocumentIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M14.8 3L19 7.49999V7.81054V19.5V20.9999L17.6 21H6.4C6.0287 21 5.6726 20.842 5.41005 20.5607C5.1475 20.2794 5 19.8978 5 19.5V4.50005C5 4.10223 5.1475 3.7207 5.41005 3.43939C5.6726 3.15809 6.0287 3.00005 6.4 3.00005H14.5102L14.8 3ZM9.34965 7.12345C9.34965 7.74477 8.84598 8.24845 8.22466 8.24845C7.60334 8.24845 7.09966 7.74477 7.09966 7.12345C7.09966 6.50214 7.60334 5.99846 8.22466 5.99846C8.84598 5.99846 9.34965 6.50214 9.34965 7.12345ZM15.3496 10H7.84961C7.6507 10 7.45993 10.079 7.31928 10.2197C7.17863 10.3603 7.09961 10.5511 7.09961 10.75C7.09961 10.9489 7.17863 11.1397 7.31928 11.2803C7.45993 11.421 7.6507 11.5 7.84961 11.5H15.3496C15.5485 11.5 15.7393 11.421 15.8799 11.2803C16.0206 11.1397 16.0996 10.9489 16.0996 10.75C16.0996 10.5511 16.0206 10.3603 15.8799 10.2197C15.7393 10.079 15.5485 10 15.3496 10ZM7.84961 13H15.3496C15.5485 13 15.7393 13.079 15.8799 13.2197C16.0206 13.3603 16.0996 13.5511 16.0996 13.75C16.0996 13.9489 16.0206 14.1397 15.8799 14.2803C15.7393 14.421 15.5485 14.5 15.3496 14.5H7.84961C7.6507 14.5 7.45993 14.421 7.31928 14.2803C7.17863 14.1397 7.09961 13.9489 7.09961 13.75C7.09961 13.5511 7.17863 13.3603 7.31928 13.2197C7.45993 13.079 7.6507 13 7.84961 13Z"
      fill={color}
    />
  </SVG>
)

DocumentIcon.propTypes = defaultIconPropTypes

DocumentIcon.defaultProps = defaultPictographIconProps
