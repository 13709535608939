import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

const MasterCardIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path
      d="M19.65 6.5H3.9c-.45 0-.9.44-.9.88v8.8c0 .88.45 1.32 1.35 1.32h15.3c.9 0 1.35-.44 1.35-1.32V7.82c0-.88-.45-1.32-1.35-1.32z"
      fill="#265697"
    />
    <Path
      d="M19.82 12.06c0 2.55-2.1 4.62-4.68 4.62a4.66 4.66 0 01-4.68-4.62c0-2.56 2.1-4.63 4.68-4.63a4.66 4.66 0 014.68 4.62z"
      fill="#DFAC16"
    />
    <Path
      d="M8.83 7.43a4.66 4.66 0 00-4.65 4.63A4.7 4.7 0 0012 15.47c.17-.16.33-.32.48-.5h-.96a4.54 4.54 0 01-.35-.48h1.65c.1-.16.2-.32.28-.5h-2.2a4.52 4.52 0 01-.2-.48h2.6c.26-.8.3-1.64.12-2.46h-2.86c.04-.16.08-.32.14-.48h2.59a4.59 4.59 0 00-.2-.5h-2.2c.08-.17.17-.33.27-.48h1.66a4.63 4.63 0 00-.37-.5h-.92c.14-.17.3-.32.47-.47a4.7 4.7 0 00-3.15-1.2h-.03z"
      fill="#BF3126"
    />
    <Path
      d="M10.54 13.23l.07-.42-.13.02c-.18 0-.2-.1-.18-.16l.14-.85h.26l.06-.47h-.25l.06-.29h-.5c-.01.01-.3 1.62-.3 1.82 0 .3.17.42.4.42.18 0 .32-.05.37-.07-.05.02 0 0 0 0z"
      fill="#fff"
    />
    <Path
      d="M10.7 12.43c0 .7.47.87.86.87.37 0 .53-.08.53-.08l.09-.48s-.28.12-.53.12c-.54 0-.44-.4-.44-.4h1s.07-.31.07-.44c0-.33-.16-.72-.7-.72-.5 0-.88.53-.88 1.13 0 0 0-.6 0 0zm.88-.7c.28 0 .23.32.23.35h-.56c0-.04.05-.34.33-.34 0 0-.28 0 0 0z"
      fill="#fff"
    />
    <Path
      d="M14.73 13.23l.1-.53s-.25.12-.42.12c-.35 0-.5-.27-.5-.56 0-.58.3-.9.65-.9.25 0 .45.14.45.14l.09-.52s-.3-.12-.57-.12c-.57 0-1.13.5-1.13 1.42 0 .61.3 1.02.9 1.02.16 0 .43-.07.43-.07z"
      fill="#fff"
    />
    <Path
      d="M7.8 11.3c-.34 0-.6.11-.6.11l-.08.43s.22-.09.55-.09c.18 0 .32.02.32.17 0 .1-.02.13-.02.13l-.21-.02c-.43 0-.89.19-.89.73 0 .44.3.53.49.53.35 0 .5-.22.5-.22l-.01.19h.44l.2-1.36c0-.58-.51-.6-.69-.6zm.1 1.11c.02.08-.05.47-.35.47-.15 0-.19-.12-.19-.18 0-.14.07-.3.44-.3l.1.01z"
      fill="#fff"
    />
    <Path
      d="M8.95 13.28c.11 0 .76.03.76-.62 0-.61-.6-.5-.6-.74 0-.12.1-.16.28-.16l.34.02.06-.43s-.18-.04-.46-.04c-.37 0-.74.14-.74.64 0 .57.62.5.62.75 0 .16-.17.17-.3.17-.24 0-.45-.08-.45-.08l-.07.43c.02 0 .14.06.56.06 0 0-.42 0 0 0z"
      fill="#fff"
    />
    <Path
      d="M18.86 10.91l-.1.66s-.2-.25-.49-.25c-.45 0-.83.54-.83 1.17 0 .4.2.8.61.8a.63.63 0 00.47-.2l-.02.17h.48l.38-2.35h-.5zm-.23 1.3c0 .25-.13.6-.4.6-.18 0-.26-.15-.26-.38 0-.39.17-.64.39-.64.18 0 .27.12.27.41 0 0 0-.29 0 0z"
      fill="#fff"
    />
    <Path
      d="M5.1 13.26l.3-1.77.05 1.77h.34l.64-1.77-.28 1.77h.51l.4-2.35h-.8l-.49 1.44-.02-1.44h-.73l-.4 2.35h.48zm7.68 0c.15-.81.18-1.48.52-1.35.06-.32.12-.44.19-.58h-.1c-.22 0-.39.3-.39.3l.05-.28h-.47l-.3 1.91h.5z"
      fill="#fff"
    />
    <Path
      d="M15.88 11.3c-.34 0-.6.11-.6.11l-.07.43s.21-.09.54-.09c.19 0 .32.02.32.17l-.01.13-.22-.02c-.43 0-.88.19-.88.73 0 .44.3.53.48.53.35 0 .5-.22.51-.22l-.02.19h.44l.2-1.36c0-.58-.51-.6-.69-.6zm.11 1.11c.01.08-.05.47-.35.47-.16 0-.2-.12-.2-.18 0-.14.08-.3.44-.3l.11.01s-.01 0 0 0z"
      fill="#fff"
    />
    <Path
      d="M16.97 13.26c.14-.81.17-1.48.52-1.35.06-.32.12-.44.18-.58h-.1c-.22 0-.38.3-.38.3l.04-.28h-.46l-.31 1.91h.5z"
      fill="#fff"
    />
  </SVG>
)

MasterCardIcon.propTypes = defaultIconPropTypes

MasterCardIcon.defaultProps = defaultPictographIconProps

export { MasterCardIcon }
