/* eslint-disable import/named */
import React, { MouseEvent } from 'react'
import PropTypes, { Validator } from 'prop-types'
import { XyzTheme } from '@postidigital/posti-theme'
import { XyzThemeColor } from '../../utils/helpers'
import { DefaultTheme } from 'styled-components'

export enum IconSize {
  lg = 'lg',
  md = 'md',
  sm = 'sm',
}

export interface CommonCompanyLogoIconProps {
  /**
   * Possible values are lg, md or sm. Default is md.
   */
  size?: keyof typeof IconSize
  backgroundColor?: string
  /**
   * Custom logo icon component
   */
  icon?: React.ReactNode
}
export interface PostiLogoIconProps extends CommonCompanyLogoIconProps {
  /**
   * Icon background color
   */
  backgroundColor?: XyzThemeColor | string
  /**
   * Logo color
   */
  color?: XyzThemeColor | string
}

export interface CompanyLogoIconProps extends CommonCompanyLogoIconProps {
  /**
   * First letter of string will be shown in uppercase
   */
  initial?: string
  /**
   * Background color of the logo icon square
   */
  backgroundColor?: string
  /**
   * Custom logo icon component
   */
  icon?: React.ReactNode
}

export interface DefaultIconProps extends React.SVGAttributes<SVGElement> {
  /**
   * The width of the icon. Prefer em units as Firefox can't render rem svgs properly
   */

  width?: string
  /**
   * The height of the icon. Prefer em units as Firefox can't render rem svgs properly
   */
  height?: string
  /**
   * viewBox = '<min-x> <min-y> <width> <height>'. The first two numbers define the position of the viewBox.
   * The last two numbers define the dimensions of the viewBox.
   */
  viewBox?: string
  /**
   * The color of the icon in as a string value or the name of the color in the Theme
   */
  color?: XyzThemeColor | string
}

export interface IconWithContentColorProps extends DefaultIconProps {
  contentColor?: XyzThemeColor | string
}

export interface IndicatorIconProps extends DefaultIconProps {
  inverted?: boolean
}

export interface InboxIconProps {
  /**
   * The letter to be shown inside icon
   */
  letter?: string

  /**
   * Swap colors of icon, invert
   */
  inverted?: boolean

  /**
   * Multiplier of default size of icon,
   * pixels / ems / rems
   */
  size?: string

  /**
   * Mouse click event listener
   */
  onClick?: (ev: MouseEvent<SVGElement>) => void
}

export const defaultIconPropTypes = {
  /**
   * The color of the icon in as a string value or the name of the color in the Theme
   */
  color: PropTypes.string,
  /**
   * The height of the icon. Prefer em units as Firefox can't render rem svgs properly
   */
  height: PropTypes.string,
  /**
   * The width of the icon. Prefer em units as Firefox can't render rem svgs properly
   */
  width: PropTypes.string,
}

export const iconWithContentColorPropTypes = {
  ...defaultIconPropTypes,
  contentColor: PropTypes.string,
}

export const inboxIconPropTypes = {
  letter: PropTypes.string.isRequired,
  size: PropTypes.string,
  inverted: PropTypes.bool,
  onClick: PropTypes.func,
}

export const defaultIconProps = {
  color: XyzTheme.color.neutralIconGray,
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

export const defaultPictographIconProps = {
  ...defaultIconProps,
  viewBox: '0 0 24 24',
}

export const iconWithContentColorProps = {
  ...defaultIconProps,
  contentColor: XyzTheme.color.neutralWhite,
}

export const defaultIndicatorIconProps = {
  inverted: false,
}

export const indicatorIconPropTypes = {
  /**
   * The color of the icon in as a string value or the name of the color in the Theme
   */
  color: PropTypes.string,
  inverted: PropTypes.bool,
}

export const inboxIconDefaultProps = {
  inverted: false,
  size: `${XyzTheme.iconSize.m}em`,
}

export interface IconProps {
  theme?: DefaultTheme
  disabled?: boolean
}

export type InvertibleColorScheme = {
  primary: XyzThemeColor | string
  secondary: XyzThemeColor | string
}

export interface InfoGlyphIconProps extends DefaultIconProps {
  iconColor?: string
  icon?: React.ComponentType<DefaultIconProps>
  backgroundColor?: string
  disabled?: boolean
}

export const InfoGlyphIconPropsTypes = {
  icon: PropTypes.elementType.isRequired as Validator<React.ComponentType<DefaultIconProps>>,
  iconColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
}

export const InfoGlyphIconDefaultProps = {
  viewBox: '0 0 32 32',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
  iconColor: XyzTheme.color.brandNavy,
}
