import React from 'react'

import { DefaultIconProps, defaultPictographIconProps, defaultIconPropTypes } from '../icons.types'
import { Path, SVG } from '../icons.styled'

const ExternalLinkIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props} fill="none">
    <Path
      d="M5.98462 5.66979L8.19703 5.6698V3.11475L5.98465 3.11475C4.33728 3.11473 3.00279 4.44975 3.00279 6.09579L3.00279 15.8045H3.00001L3 18.0161C2.99999 18.7629 3.27467 19.4454 3.72849 19.9683C4.27519 20.6002 5.08306 21 5.98456 21H8.19703V20.9968H17.9101C19.5568 20.9968 20.8921 19.6627 20.8921 18.0162V15.8045H18.3361V18.0162C18.3361 18.2511 18.1456 18.4417 17.9101 18.4417H5.98219C5.8536 18.4417 5.73847 18.385 5.6604 18.2952C5.59706 18.2209 5.55881 18.1245 5.55881 18.0191L5.55881 6.09579C5.55881 5.86017 5.74961 5.66979 5.98462 5.66979Z"
      fill={color}
    />
    <Path
      d="M12.7342 3H18.7051H20.9994H21.0001V11.262H18.7051L18.7051 6.94347L12.3492 13.2993L10.7261 11.6762L17.1073 5.29501H12.7342V3Z"
      fill={color}
    />
  </SVG>
)

ExternalLinkIcon.propTypes = defaultIconPropTypes

ExternalLinkIcon.defaultProps = defaultPictographIconProps

export { ExternalLinkIcon }
