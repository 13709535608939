import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const CalendarIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M19.4348 4.875H17.2826C17.2307 4.875 17.087 4.875 17.087 4.875C17.087 4.875 17.087 4.72957 17.087 4.67708V3.29167C17.087 3.0817 17.0045 2.88034 16.8577 2.73187C16.711 2.58341 16.5119 2.5 16.3043 2.5C16.0968 2.5 15.8977 2.58341 15.751 2.73187C15.6042 2.88034 15.5217 3.0817 15.5217 3.29167V7.05208C15.5217 7.20956 15.4599 7.36058 15.3498 7.47193C15.2397 7.58328 15.0905 7.64583 14.9348 7.64583C14.7791 7.64583 14.6298 7.58328 14.5197 7.47193C14.4097 7.36058 14.3478 7.20956 14.3478 7.05208V5.27083C14.3478 5.16585 14.3478 4.875 14.3478 4.875C14.3478 4.875 14.0603 4.875 13.9565 4.875H9.06522C9.01346 4.875 8.86957 4.875 8.86957 4.875C8.86957 4.875 8.86977 4.73023 8.86957 4.67788V3.29167C8.86957 3.0817 8.78711 2.88034 8.64034 2.73187C8.49358 2.58341 8.29452 2.5 8.08696 2.5C7.8794 2.5 7.68034 2.58341 7.53357 2.73187C7.3868 2.88034 7.30435 3.0817 7.30435 3.29167V7.05208C7.30435 7.20956 7.24251 7.36058 7.13243 7.47193C7.02236 7.58328 6.87306 7.64583 6.71739 7.64583C6.56172 7.64583 6.41243 7.58328 6.30235 7.47193C6.19227 7.36058 6.13043 7.20956 6.13043 7.05208V5.27083C6.13043 5.16585 6.13043 4.875 6.13043 4.875C6.13043 4.875 5.84291 4.875 5.73913 4.875H4.56522C4.1501 4.875 3.75198 5.04182 3.45844 5.33875C3.16491 5.63568 3 6.03841 3 6.45833V19.9167C3 20.3366 3.16491 20.7393 3.45844 21.0363C3.75198 21.3332 4.1501 21.5 4.56522 21.5H19.4348C19.8499 21.5 20.248 21.3332 20.5416 21.0363C20.8351 20.7393 21 20.3366 21 19.9167V6.45833C21 6.03841 20.8351 5.63568 20.5416 5.33875C20.248 5.04182 19.8499 4.875 19.4348 4.875ZM19.0435 19.9167H4.95652C4.85274 19.9167 4.56522 19.9167 4.56522 19.9167C4.56522 19.9167 4.56522 19.6258 4.56522 19.5208V10.0208C4.56522 9.91585 4.56522 9.625 4.56522 9.625C4.56522 9.625 4.85274 9.625 4.95652 9.625H19.0435C19.1473 9.625 19.4348 9.625 19.4348 9.625C19.4348 9.625 19.4348 9.91585 19.4348 10.0208V19.5208C19.4348 19.6258 19.4348 19.9167 19.4348 19.9167C19.4348 19.9167 19.1473 19.9167 19.0435 19.9167Z"
      fill={color}
    />
  </SVG>
)

CalendarIcon.propTypes = defaultIconPropTypes

CalendarIcon.defaultProps = defaultPictographIconProps
