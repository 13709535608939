import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const MicrophoneIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M12 15C12.8948 14.999 13.7527 14.6431 14.3854 14.0104C15.0181 13.3777 15.374 12.5198 15.375 11.625V6.375C15.375 5.47989 15.0194 4.62145 14.3865 3.98851C13.7536 3.35558 12.8951 3 12 3C11.1049 3 10.2465 3.35558 9.61351 3.98851C8.98058 4.62145 8.625 5.47989 8.625 6.375V11.625C8.62599 12.5198 8.98189 13.3777 9.61461 14.0104C10.2473 14.6431 11.1052 14.999 12 15Z"
      fill={color}
    />
    <Path
      d="M17.25 9C17.0511 9 16.8603 9.07902 16.7197 9.21967C16.579 9.36032 16.5 9.55109 16.5 9.75V11.625C16.5 12.8185 16.0259 13.9631 15.182 14.807C14.3381 15.6509 13.1935 16.125 12 16.125C10.8065 16.125 9.66193 15.6509 8.81802 14.807C7.97411 13.9631 7.5 12.8185 7.5 11.625V9.75C7.5 9.55109 7.42098 9.36032 7.28033 9.21967C7.13968 9.07902 6.94891 9 6.75 9C6.55109 9 6.36032 9.07902 6.21967 9.21967C6.07902 9.36032 6 9.55109 6 9.75V11.625C6.00193 13.0583 6.51604 14.4437 7.44957 15.5312C8.3831 16.6188 9.67458 17.3369 11.091 17.556C11.1353 17.5628 11.1756 17.5852 11.2048 17.6192C11.2339 17.6532 11.25 17.6965 11.25 17.7413V20.25C11.25 20.4489 11.25 21 11.25 21C11.25 21 11.8011 21 12 21C12.1989 21 12.75 21 12.75 21C12.75 21 12.75 20.4489 12.75 20.25V17.7413C12.75 17.6965 12.7661 17.6532 12.7952 17.6192C12.8244 17.5852 12.8647 17.5628 12.909 17.556C14.3254 17.3369 15.6169 16.6188 16.5504 15.5312C17.484 14.4437 17.9981 13.0583 18 11.625V9.75C18 9.55109 17.921 9.36032 17.7803 9.21967C17.6397 9.07902 17.4489 9 17.25 9Z"
      fill={color}
    />
  </SVG>
)

MicrophoneIcon.propTypes = defaultIconPropTypes

MicrophoneIcon.defaultProps = defaultPictographIconProps
