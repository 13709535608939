import React from 'react'

const Rating4Inactive: React.FC = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 10.3131C0 5.27304 2.79089 0 11.6353 0C20.4796 0 23.2705 5.27304 23.2705 10.3131C23.2705 15.3535 20.4796 20.6261 11.6353 20.6261C2.79089 20.6265 0 15.3922 0 10.3131Z"
      fill="#C2C9D1"
    />
    <path
      d="M8.19571 6.34645C8.19571 5.90831 8.55089 5.55313 8.98903 5.55313C9.42716 5.55313 9.78234 5.90831 9.78234 6.34645V8.46195C9.78234 8.90008 9.42716 9.25526 8.98903 9.25526C8.55089 9.25526 8.19571 8.90008 8.19571 8.46195V6.34645Z"
      fill="white"
      stroke="white"
      strokeWidth="0.528875"
    />
    <path
      d="M13.4844 6.34645C13.4844 5.90831 13.8396 5.55313 14.2777 5.55313C14.7159 5.55313 15.071 5.90831 15.071 6.34645V8.46195C15.071 8.90008 14.7159 9.25526 14.2777 9.25526C13.8396 9.25526 13.4844 8.90008 13.4844 8.46195V6.34645Z"
      fill="white"
      stroke="white"
      strokeWidth="0.528875"
    />
    <path
      d="M16.6357 12.1585C15.9315 13.8543 13.9451 15.6018 11.6334 15.6018C9.32159 15.6018 7.33522 13.8543 6.63105 12.1585C6.60301 12.091 6.60682 12.046 6.61508 12.0189C6.6238 11.9904 6.64347 11.9624 6.67737 11.9397C6.74706 11.8929 6.87046 11.8756 6.99417 11.952C7.96216 12.5498 9.55565 13.2661 11.6334 13.2661C13.7111 13.2661 15.3046 12.5498 16.2725 11.952C16.3963 11.8756 16.5197 11.8929 16.5893 11.9397C16.6233 11.9624 16.6429 11.9904 16.6516 12.0189C16.6599 12.046 16.6637 12.091 16.6357 12.1585Z"
      fill="white"
      stroke="white"
      strokeWidth="0.528875"
    />
  </svg>
)

export default Rating4Inactive
