import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Circle, SVG } from '../icons.styled'

export const DotsHorizontalIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Circle cx="5" cy="12" r="2" fill={color} />
    <Circle cx="12" cy="12" r="2" fill={color} />
    <Circle cx="19" cy="12" r="2" fill={color} />
  </SVG>
)

DotsHorizontalIcon.propTypes = defaultIconPropTypes

DotsHorizontalIcon.defaultProps = defaultPictographIconProps
