import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const SimpleThumbsDownIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M18.4284 13.6535L19.7142 13.6535C20.4243 13.6535 21 13.2144 21 12.6728L21 6.78838C21 6.24674 20.4243 5.80765 19.7142 5.80765L18.4284 5.80765C17.7183 5.80765 17.1426 6.24674 17.1426 6.78838L17.1426 12.6728C17.1426 13.2144 17.7183 13.6535 18.4284 13.6535Z"
      fill={color}
    />
    <Path
      d="M3.37428 12.8689C3.55394 13.1125 3.78689 13.3102 4.0547 13.4464C4.32251 13.5826 4.61782 13.6535 4.91724 13.6535L8.04173 13.6535C8.13808 13.6541 8.23305 13.6768 8.31963 13.7198C8.40621 13.7628 8.48217 13.8251 8.54191 13.9019C8.66576 14.1383 8.70225 14.412 8.64477 14.6735L8.09059 17.3293C8.02091 17.5964 8.0143 17.8764 8.07128 18.1466C8.12826 18.4168 8.24722 18.6695 8.41847 18.8841C8.59469 19.087 8.81318 19.2475 9.05806 19.3538C9.30293 19.4601 9.56803 19.5095 9.83405 19.4985C10.1001 19.4875 10.3603 19.4163 10.5959 19.2901C10.8315 19.1639 11.0364 18.9859 11.1958 18.769L14.2971 13.9307C14.3552 13.8468 14.4322 13.7783 14.5217 13.731C14.6112 13.6837 14.7106 13.6589 14.8115 13.6587L15.2139 13.6587C15.3844 13.6587 15.5479 13.5898 15.6685 13.4672C15.7891 13.3446 15.8568 13.1783 15.8568 13.0049L15.8568 6.85375C15.8569 6.73307 15.8242 6.6147 15.7622 6.51177C15.7003 6.40883 15.6115 6.32534 15.5058 6.27055C12.56 4.74061 11.8978 4.5 10.3047 4.5C9.93828 4.5 8.68206 4.53661 7.93244 4.53661C6.14261 4.53661 4.95582 5.58273 4.30906 7.70372L3.08241 11.1467C2.99586 11.4371 2.97717 11.744 3.02784 12.043C3.07852 12.342 3.19715 12.6248 3.37428 12.8689Z"
      fill={color}
    />
  </SVG>
)

SimpleThumbsDownIcon.propTypes = defaultIconPropTypes

SimpleThumbsDownIcon.defaultProps = defaultPictographIconProps

export { SimpleThumbsDownIcon }
