import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const NotificationIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M13.677 18.75H10.323C10.277 18.7501 10.1951 18.7492 10.1251 18.7497C10.125 18.8707 10.1247 19.0048 10.125 19.125C10.125 19.6223 10.3226 20.0992 10.6742 20.4508C11.0258 20.8025 11.5027 21 12 21C12.4973 21 12.9742 20.8025 13.3258 20.4508C13.6775 20.0992 13.875 19.6223 13.875 19.125C13.8753 19.0207 13.875 18.863 13.875 18.751C13.8282 18.7496 13.7231 18.7498 13.677 18.75Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      d="M17.9545 15.7956C18.1655 16.0066 18.4517 16.1251 18.75 16.1251C18.9489 16.1251 19.1397 16.2041 19.2803 16.3448C19.421 16.4854 19.5 16.6762 19.5 16.8751V17.6251H18.75H5.25002H4.5L4.50002 16.8751C4.50002 16.6762 4.57904 16.4854 4.71969 16.3448C4.86034 16.2041 5.05111 16.1251 5.25002 16.1251C5.54839 16.1251 5.83454 16.0066 6.04552 15.7956C6.25649 15.5846 6.37502 15.2985 6.37502 15.0001V11.3536C6.33171 9.92944 6.79939 8.53676 7.6935 7.42738C8.58762 6.31799 9.84915 5.56512 11.25 5.30487V3.75013V3H12H12.75V3.75013V5.30487C13.1239 5.37434 13.4879 5.4789 13.8376 5.61597C13.6219 6.03006 13.5 6.50079 13.5 7C13.5 8.65685 14.8431 10 16.5 10C16.8419 10 17.1705 9.9428 17.4766 9.83745C17.5898 10.3309 17.6406 10.8402 17.625 11.3536V15.0001C17.625 15.2985 17.7435 15.5846 17.9545 15.7956Z"
      fill={color}
    />
    <Path
      d="M18.5 7C18.5 8.10457 17.6046 9 16.5 9C15.3954 9 14.5 8.10457 14.5 7C14.5 5.89543 15.3954 5 16.5 5C17.6046 5 18.5 5.89543 18.5 7Z"
      fill={color}
    />
  </SVG>
)

NotificationIcon.propTypes = defaultIconPropTypes
NotificationIcon.defaultProps = defaultPictographIconProps
