import { ProgressButtonProps } from './ProgressButton'
import { XyzTheme } from '@postidigital/posti-theme'
import { Size } from '../Button/Button'

export const getPadding = ({ size }: Pick<ProgressButtonProps, 'size'>): string => {
  if (size === Size.md) {
    return `${XyzTheme.spacing.space3}rem ${XyzTheme.spacing.space5}rem ${XyzTheme.spacing.space3}rem ${XyzTheme.spacing.space5}rem`
  }
  if (size === Size.sm) {
    return `0.75rem ${XyzTheme.spacing.space4}rem 0.75rem ${XyzTheme.spacing.space4}rem`
  }
  return `${XyzTheme.spacing.space4}rem ${XyzTheme.spacing.space6}rem ${XyzTheme.spacing.space4}rem ${XyzTheme.spacing.space6}rem`
}
