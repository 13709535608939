import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'

import {
  StyledRoot,
  StyledSwitch,
  StyledInput,
  StyledLabel,
  StyledBackground,
  StyledCircle,
  getMainSwitchColor,
  StyledCheckmarkIcon,
  StyledMinusIcon,
} from './Switch.style'

type ChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => void

export enum SwitchLabelPosition {
  left = 'left',
  right = 'right',
}

export interface SwitchProps extends HTMLAttributes<HTMLInputElement> {
  checked?: boolean
  disabled?: boolean
  isInvalid?: boolean
  label?: string | number | JSX.Element
  labelPosition?: SwitchLabelPosition | keyof typeof SwitchLabelPosition
  spacing?: number
  inputProps?: React.HTMLAttributes<HTMLInputElement>
  onChange: ChangeEvent
}

export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>((props, ref): JSX.Element => {
  const { checked, isInvalid, disabled, label, spacing, labelPosition, onChange, id, inputProps, ...rest } = props
  const showInvalid = !isInvalid && disabled
  const showChecked = !isInvalid && checked
  const iconColor = getMainSwitchColor({ disabled: showInvalid, isInvalid, checked: showChecked })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  const renderSwitch = () => (
    <StyledSwitch isInvalid={isInvalid}>
      <StyledInput
        type="checkbox"
        ref={ref}
        checked={showChecked}
        onChange={handleChange}
        tabIndex={disabled ? -1 : 0}
        aria-disabled={showInvalid}
        disabled={showInvalid}
        {...inputProps}
      />

      <StyledBackground checked={showChecked} disabled={disabled} isInvalid={isInvalid} />
      <StyledCircle checked={showChecked} disabled={disabled} isInvalid={isInvalid}>
        {showChecked ? (
          <StyledCheckmarkIcon width="1.5em" height="1.5em" color={iconColor} />
        ) : (
          <StyledMinusIcon width="1em" height="1em" color={iconColor} />
        )}
      </StyledCircle>
    </StyledSwitch>
  )

  return (
    <StyledRoot {...rest}>
      {label ? (
        <StyledLabel labelPosition={labelPosition as SwitchLabelPosition} spacing={spacing}>
          {labelPosition === SwitchLabelPosition.left && label}
          {renderSwitch()}
          {labelPosition === SwitchLabelPosition.right && label}
        </StyledLabel>
      ) : (
        renderSwitch()
      )}
    </StyledRoot>
  )
})

Switch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  spacing: PropTypes.number,
  labelPosition: PropTypes.oneOf(Object.values(SwitchLabelPosition)),
  inputProps: PropTypes.object,
}

Switch.defaultProps = {
  checked: false,
  disabled: false,
  isInvalid: false,
  labelPosition: SwitchLabelPosition.left,
}

Switch.displayName = 'Switch'
