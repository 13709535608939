import { XyzTheme } from '@postidigital/posti-theme'
import { MenuItemProps } from './MenuItem.types'

export const getColor = ({ disabled, selected }: Pick<MenuItemProps, 'selected' | 'disabled'>): string => {
  if (disabled) {
    return XyzTheme.color.neutralPassiveGray
  }
  if (selected) {
    return XyzTheme.color.neutralBlack
  }
  return XyzTheme.color.neutralNetworkGray
}
