import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const PostcardIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props} fill="none">
    <Path
      d="M3 5.5h18V18H3V5.5zm13.5 1.79h2.7v3.12h-2.7V7.3zm-2.25 4.9a.45.45 0 100 .9h4.5a.45.45 0 100-.9h-4.5zm-.45 2.69c0-.25.2-.45.45-.45h4.5a.45.45 0 110 .9h-4.5a.45.45 0 01-.45-.46zM12 7.28c-.25 0-.45.2-.45.45v8.04c0 .24.2.44.45.44s.45-.2.45-.44V7.73c0-.24-.2-.44-.45-.44z"
      fill={color}
    />
  </SVG>
)

PostcardIcon.propTypes = defaultIconPropTypes

PostcardIcon.defaultProps = defaultPictographIconProps

export { PostcardIcon }
