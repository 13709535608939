import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const VehicleIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M4 5C2.89543 5 2 5.89543 2 7V18H4C4 19.6569 5.34315 21 7 21C8.65685 21 10 19.6569 10 18H14H15C15 19.6569 16.3431 21 18 21C19.6569 21 21 19.6569 21 18C21.5523 18 22 17.5523 22 17V13L21.0996 9.66705C20.9371 8.70458 20.1036 8 19.1275 8H16V5H4ZM19.5 18C19.5 17.1716 18.8284 16.5 18 16.5C17.1716 16.5 16.5 17.1716 16.5 18C16.5 18.8284 17.1716 19.5 18 19.5C18.8284 19.5 19.5 18.8284 19.5 18ZM7 19.5C6.17157 19.5 5.5 18.8284 5.5 18C5.5 17.1716 6.17157 16.5 7 16.5C7.82843 16.5 8.5 17.1716 8.5 18C8.5 18.8284 7.82843 19.5 7 19.5ZM20.5 13L19.6206 9.91676C19.5799 9.67615 19.244 9.5 19 9.5H16V13H20.5Z"
      fill={color}
    />
  </SVG>
)

VehicleIcon.propTypes = defaultIconPropTypes

VehicleIcon.defaultProps = defaultPictographIconProps
