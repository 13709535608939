import styled, { css } from 'styled-components'
import { Label } from '../../design-tokens/typography'
import { Placement } from './Tooltip'

export const StyledTooltip = styled.div<{ placement: Placement; open: boolean }>(
  ({
    theme: {
      xyz: { color, spacing, zIndex, borderRadius },
    },
    placement,
    open,
  }) => css`
    background-color: ${color.neutralNetworkGray};
    padding: ${spacing.space2}rem;
    border-radius: ${borderRadius.xs}rem;
    position: absolute;
    display: ${open ? 'block' : 'none'};
    z-index: ${zIndex.zIndexTop};
    top: 0;
    left: 0;

    ${String(placement).includes('bottom') &&
    `
    margin-top: ${spacing.space2}rem;
  `}

    ${String(placement).includes('top') &&
    `
    margin-top: -${spacing.space2}rem;
  `}

  ${(placement === Placement['bottom-end'] || placement === Placement['top-end']) &&
    `
    margin-left: ${spacing.space3}rem;
  `}

  ${(placement === Placement['bottom-start'] || placement === Placement['top-start']) &&
    `
    margin-right: ${spacing.space3}rem;
  `}
  ${placement === Placement.start &&
    `
    padding-right: ${spacing.space3}rem;
    margin-left: -${spacing.space2}rem;
  `}
  ${placement === Placement.end &&
    `
    padding-left: ${spacing.space3}rem;
    margin-left: ${spacing.space2}rem;
  `}

  &:before {
      content: '';
      position: absolute;
      margin: auto;
      display: block;
      width: ${spacing.space3}rem;
      height: ${spacing.space3}rem;
      background-color: ${color.neutralNetworkGray};
      transform: rotate(45deg);

      ${String(placement).includes('bottom') &&
      `
      top: -${spacing.space2}rem;
    `}

      ${String(placement).includes('top') &&
      `
      bottom: -${spacing.space2}rem;
    `}

    ${(placement === Placement['bottom-end'] || placement === Placement['top-end']) &&
      `
      right: ${spacing.space3}rem;
    `}

    ${(placement === Placement['bottom-start'] || placement === Placement['top-start']) &&
      `
      left: ${spacing.space3}rem;
    `}

    ${placement === Placement.start &&
      `
      transform: translateY(-50%) rotate(45deg);
      top: 50%;
      right: -${spacing.space2}rem;
    `}

    ${placement === Placement.end &&
      `
      transform: translateY(-50%) rotate(45deg);
      top: 50%;
      left: -${spacing.space2}rem;
    `}

    ${(placement === Placement.top || placement === Placement.bottom) &&
      `
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    `}
    }
  `
)

export const StyledLabel = styled(Label)`
  color: ${({
    theme: {
      xyz: { color },
    },
  }) => color.neutralWhite};
`
