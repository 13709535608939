import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const QRCodeIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path fillRule="evenodd" d="M11.1 3H3V11.1H11.1V3ZM8.4 5.69998H5.7V8.39998H8.4V5.69998Z" fill={color} />
    <Path fillRule="evenodd" d="M11.1 12.9H3V21H11.1V12.9ZM8.4 15.6H5.7V18.3H8.4V15.6Z" fill={color} />
    <Path fillRule="evenodd" d="M21 3H12.9V11.1H21V3ZM18.3 5.69998H15.6V8.39998H18.3V5.69998Z" fill={color} />
    <Path
      fillRule="evenodd"
      d="M15.6 12.9H12.9V15.6H15.6V18.3H12.9V21H15.6V18.3H18.3V21H21V18.3H18.3H15.6V15.6V15.6V12.9ZM18.3 12.9V15.6H21V12.9H18.3Z"
      fill={color}
    />
  </SVG>
)

QRCodeIcon.propTypes = defaultIconPropTypes

QRCodeIcon.defaultProps = defaultPictographIconProps
