import React from 'react'
import { SVG, Path, Circle } from '../icons.styled'
import { defaultIndicatorIconProps, IndicatorIconProps, indicatorIconPropTypes } from '../icons.types'
import { XyzTheme } from '@postidigital/posti-theme'

const AlertIndicatorIcon: React.FC<IndicatorIconProps> = ({ color, inverted, ...props }) => {
  return inverted ? (
    <SVG {...props}>
      <Path
        fillRule="evenodd"
        d="M10.8394 0.25C11.1018 0.25 11.353 0.356474 11.5355 0.54506L15.4774 4.61831C15.6522 4.79899 15.75 5.04057 15.75 5.292V10.708C15.75 10.9594 15.6522 11.201 15.4774 11.3817L11.5355 15.4549C11.353 15.6435 11.1018 15.75 10.8394 15.75H5.1606C4.89817 15.75 4.64696 15.6435 4.46446 15.4549L0.522604 11.3817C0.347754 11.201 0.25 10.9594 0.25 10.708V5.292C0.25 5.04057 0.347754 4.79899 0.522604 4.61831L4.46446 0.54506C4.64696 0.356473 4.89817 0.25 5.1606 0.25H10.8394ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM9.25 4H6.75L7.16667 9H8.83333L9.25 4Z"
        fill={color}
      />
    </SVG>
  ) : (
    <SVG {...props}>
      <Path
        fill={color}
        d="M10.8394 1.21875H5.1606L1.21875 5.292V10.708L5.1606 14.7812H10.8394L14.7812 10.708V5.292L10.8394 1.21875ZM10.8394 0.25C11.1018 0.25 11.353 0.356474 11.5355 0.54506L15.4774 4.61831C15.6522 4.79899 15.75 5.04057 15.75 5.292V10.708C15.75 10.9594 15.6522 11.201 15.4774 11.3817L11.5355 15.4549C11.353 15.6435 11.1018 15.75 10.8394 15.75H5.1606C4.89817 15.75 4.64696 15.6435 4.46446 15.4549L0.522604 11.3817C0.347754 11.201 0.25 10.9594 0.25 10.708V5.292C0.25 5.04057 0.347754 4.79899 0.522604 4.61831L4.46446 0.54506C4.64696 0.356473 4.89817 0.25 5.1606 0.25H10.8394Z"
      />
      <Circle cx="8" cy="11" r="1" fill={color} />
      <Path fill={color} d="M6.75 4H9.25L8.83333 9H7.16667L6.75 4Z" />
    </SVG>
  )
}

AlertIndicatorIcon.defaultProps = {
  ...defaultIndicatorIconProps,
  color: XyzTheme.color.signalRed,
  width: `${XyzTheme.iconSize.xs}em`,
  height: `${XyzTheme.iconSize.xs}em`,
  viewBox: '0 0 16 16',
}

AlertIndicatorIcon.propTypes = indicatorIconPropTypes

export { AlertIndicatorIcon }
