import React, { FC } from 'react'
import { inboxIconDefaultProps, InboxIconProps, inboxIconPropTypes, InvertibleColorScheme } from '../icons.types'
import { Path, InboxIconSvg, Text, useInvertedColors } from '../icons.styled'
import { XyzTheme } from '@postidigital/posti-theme'

const colorScheme: InvertibleColorScheme = {
  primary: XyzTheme.color.brandPetrol,
  secondary: XyzTheme.color.brandBrightYellow,
}

const LetterInboxIcon: FC<InboxIconProps> = ({ letter, inverted, size, onClick, ...props }) => {
  const { primary, secondary } = useInvertedColors(colorScheme, inverted)

  return (
    <InboxIconSvg size={size} onClick={onClick} {...props}>
      <Path
        d="M.2 9.4A8 8 0 000 11v21a8 8 0 008 8h16a8 8 0 008-8V11a8 8 0 00-.2-1.6l-15.4 5a1 1 0 01-.8 0L.2 9.4zm.6-1.8L16 12.5l15.2-5A8 8 0 0024 3H8A8 8 0 00.8 7.6z"
        fill={secondary}
      />
      <Text fill={primary} fontSize="19" fontWeight="bold">
        <tspan x="50%" y="32" textAnchor="middle">
          {letter}
        </tspan>
      </Text>
    </InboxIconSvg>
  )
}

LetterInboxIcon.propTypes = inboxIconPropTypes
LetterInboxIcon.defaultProps = inboxIconDefaultProps

export { LetterInboxIcon }
