import { KeyboardEvent, SyntheticEvent } from 'react'

export const handleKeyActivation = (
  callback: (event: SyntheticEvent) => any
): ((event: KeyboardEvent<HTMLElement>) => void) => {
  return (event: KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault()
        callback(event)
        return
    }
  }
}
