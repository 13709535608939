import * as React from 'react'

export interface TabsContextValue {
  selectedTab: string | number | boolean
  setSelectedTab: (tab: string | number | boolean) => void
}

export const TabsContext = React.createContext<TabsContextValue>(null)

export default TabsContext
