import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const SignatureIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M16.65 4.93l-.2-.2c-.07.05-.14.15-.19.2l-6.35 6.3-.2.2.2.2 2.38 2.35.2.2.2-.2 6.35-6.3.2-.2c-.06-.04-.15-.14-.2-.19l-2.39-2.36zM9.31 12.22l-.2-.2c-.05.06-.23.22-.26.3l-1.19 3.55a.27.27 0 00.2.36h.15l3.58-1.19.28-.28-.17-.18-2.39-2.36zM20.43 3.55a2.02 2.02 0 00-2.78 0l-.4.4-.2.19.2.2 2.39 2.36.2.2.19-.2.4-.4a1.94 1.94 0 000-2.75zM3.3 19.24a1.03 1.03 0 101.46 1.46l1.33-1.33a.73.73 0 011.22.34 1.4 1.4 0 002.14.82l1-.67a.74.74 0 01.93.1l.74.73c.2.2.46.3.73.3h4.1a1.03 1.03 0 100-2.06h-3.56a.26.26 0 01-.18-.07l-.37-.36a2.8 2.8 0 00-3.53-.35l-.14.1a.26.26 0 01-.35-.07 2.8 2.8 0 00-4.2-.27L3.3 19.24z"
      fill={color}
    />
  </SVG>
)

SignatureIcon.propTypes = defaultIconPropTypes

SignatureIcon.defaultProps = defaultPictographIconProps

export { SignatureIcon }
