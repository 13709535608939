export enum ContainerWidth {
  sideMenu = '18.5rem',
}

export enum EventSource {
  KEYBOARD = 'keyboard',
  MOUSE = 'mouse',
}

export enum SIDE_MENU_ITEM_ICON_POSITION {
  right = 'right',
  left = 'left',
}
