import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path, Circle } from '../icons.styled'
import { IndicatorIconProps, defaultIndicatorIconProps, indicatorIconPropTypes } from '../icons.types'

const CheckmarkIndicatorIcon: React.FC<IndicatorIconProps> = ({ color, inverted, ...props }) => {
  return inverted ? (
    <SVG {...props}>
      <Path
        d="M8 15.75C12.2802 15.75 15.75 12.2802 15.75 8C15.75 3.71979 12.2802 0.25 8 0.25C3.71979 0.25 0.25 3.71979 0.25 8C0.25 12.2802 3.71979 15.75 8 15.75ZM7.2289 11.2893L12.2577 5.70172L11.1428 4.69827L7.17161 9.11069L4.73058 6.66967L3.66992 7.73033L7.2289 11.2893Z"
        fill={color}
      />
    </SVG>
  ) : (
    <SVG {...props} fill="none">
      <Circle cx="8" cy="8" r="7.25" stroke={color} />
      <Path
        fill={color}
        fillRule="inherit"
        d="M7.2002 10.2L6.66987 10.7303L7.22884 11.2893L7.75767 10.7017L7.2002 10.2ZM3.66987 7.73033L6.66987 10.7303L7.73053 9.66967L4.73053 6.66967L3.66987 7.73033ZM7.75767 10.7017L12.2577 5.70172L11.1427 4.69827L6.64272 9.69827L7.75767 10.7017Z"
      />
    </SVG>
  )
}

CheckmarkIndicatorIcon.propTypes = indicatorIconPropTypes

CheckmarkIndicatorIcon.defaultProps = {
  ...defaultIndicatorIconProps,
  color: XyzTheme.color.signalGreen,
  width: `${XyzTheme.iconSize.xs}em`,
  height: `${XyzTheme.iconSize.xs}em`,
  viewBox: '0 0 16 16',
}

export { CheckmarkIndicatorIcon }
