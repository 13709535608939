import React from 'react'

const ThankYouRating5: React.FC = () => (
  <svg width="186" height="123" viewBox="0 0 186 123" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 676">
      <g id="Component 38">
        <circle id="Ellipse 100" cx="92.9194" cy="74.9155" r="48" fill="#F7CC7A" />
        <g id="24 / feedback_v2">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112.042 64.485C110.222 63.6838 108.098 64.5095 107.297 66.3291C106.495 68.1488 104.371 68.9745 102.551 68.1733C100.731 67.3722 99.9058 65.2476 100.707 63.4279C103.11 57.9689 109.484 55.4919 114.943 57.8954C117.871 59.1844 119.943 61.621 120.86 64.4328C121.476 66.323 120.444 68.3551 118.554 68.9717C116.663 69.5883 114.631 68.5558 114.015 66.6656C113.706 65.7186 113.017 64.9145 112.042 64.485ZM76.8062 64.3528C74.8857 63.8383 72.9117 64.978 72.3971 66.8984C71.8825 68.8189 69.9085 69.9586 67.988 69.444C66.0675 68.9294 64.9278 66.9554 65.4424 65.0349C66.9862 59.2735 72.9082 55.8544 78.6697 57.3982C81.7596 58.2261 84.1782 60.3188 85.513 62.9579C86.4104 64.7321 85.6995 66.8979 83.9253 67.7952C82.1511 68.6926 79.9854 67.9817 79.0881 66.2075C78.6385 65.3186 77.8357 64.6287 76.8062 64.3528Z"
            fill="#5C2952"
          />
          <path
            id="Ellipse 102"
            d="M92.9194 101.315C103.989 101.315 113.385 93.0004 116.728 84.9505C117.615 82.813 115.236 81.3617 113.341 82.5321C109.08 85.1634 102.066 88.3166 92.9194 88.3166C83.773 88.3166 76.7587 85.1634 72.4976 82.5321C70.6024 81.3617 68.2234 82.813 69.111 84.9505C72.4538 93.0004 81.8503 101.315 92.9194 101.315Z"
            fill="#5C2952"
          />
        </g>
      </g>
      <rect
        id="Rectangle 860"
        x="161.871"
        y="40.9595"
        width="10.2648"
        height="17.1079"
        transform="rotate(34.5437 161.871 40.9595)"
        fill="#EDBDBD"
      />
      <rect
        id="Rectangle 864"
        x="126.125"
        y="9.07739"
        width="10.2648"
        height="17.1079"
        transform="rotate(98.305 126.125 9.07739)"
        fill="#EDBDBD"
      />
      <rect
        id="Rectangle 863"
        x="44.6069"
        y="24.5381"
        width="10.2648"
        height="17.1079"
        transform="rotate(100.461 44.6069 24.5381)"
        fill="#EDBDBD"
      />
      <rect
        id="Rectangle 862"
        x="15.5547"
        y="80.7751"
        width="10.2648"
        height="17.1079"
        transform="rotate(42.2555 15.5547 80.7751)"
        fill="#EDBDBD"
      />
      <circle id="Ellipse 106" cx="144.065" cy="28.032" r="5.13238" fill="#FFC226" />
      <circle id="Ellipse 108" cx="84.2872" cy="5.33624" r="5.13238" fill="#FFC226" />
      <circle id="Ellipse 107" cx="31.0513" cy="59.2874" r="5.13238" fill="#FFC226" />
      <circle id="Ellipse 109" cx="180.449" cy="96.848" r="5.13238" fill="#FFC226" />
      <path id="Polygon 6" d="M161.85 93.4222L155.955 83.1397L167.807 83.1752L161.85 93.4222Z" fill="#FFA894" />
      <path id="Polygon 4" d="M15.1648 50.3829L3.68931 53.3496L6.85786 41.9282L15.1648 50.3829Z" fill="#FFA894" />
      <path id="Polygon 7" d="M183.752 69.5402L172.277 72.5068L175.445 61.0854L183.752 69.5402Z" fill="#FFA894" />
      <path id="Polygon 5" d="M64.4817 24.9212L57.2846 15.5038L69.0389 13.9796L64.4817 24.9212Z" fill="#FFA894" />
    </g>
  </svg>
)

export default ThankYouRating5
