import { HTMLAttributes } from 'react'
import PropTypes, { Validator } from 'prop-types'
import { ContainerWidth, SIDE_MENU_ITEM_ICON_POSITION } from './consts'

export interface SideMenuPropsItem extends Omit<HTMLAttributes<HTMLElement>, 'onClick'> {
  label: string
  route: string
  icon?: JSX.Element
  iconPosition?: keyof typeof SIDE_MENU_ITEM_ICON_POSITION
  isOpenByDefault?: boolean
  activeBackgroundColor?: string
  activeTextColor?: string
  isActive: (route: string) => boolean
  onClick: (route: string) => void
  subItems?: SideMenuPropsItem[]
  subtitle?: string
  expandWithClick?: boolean
  /**
   * Used for accessibility purposes, to give context to the expand button
   */
  id?: string
}

/**
 * For internal props that are not part of structure
 */
export interface InternalSideMenuProps {
  isChild?: boolean
  togglingDisabled?: boolean
  isParentExpanded?: boolean
  expandSubMenuAriaLabel?: string
}

export interface SideMenuProps extends HTMLAttributes<HTMLElement> {
  togglingDisabled?: boolean
  structure: SideMenuPropsItem[]
  containerWidth?: string
  expandSubMenuAriaLabel?: string
}

const subItemShape = {
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  isActive: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  subItems: PropTypes.array,
  subtitle: PropTypes.string,
  activeBackgroundColor: PropTypes.string,
  activeTextColor: PropTypes.string,
  isOpenByDefault: PropTypes.bool,
  iconPosition: PropTypes.oneOf(Object.keys(SIDE_MENU_ITEM_ICON_POSITION)),
  icon: PropTypes.element,
  id: PropTypes.string,
}

subItemShape.subItems = PropTypes.arrayOf(PropTypes.shape(subItemShape) as Validator<SideMenuPropsItem>)

export const SideMenuPropTypes = {
  togglingDisabled: PropTypes.bool,
  expandSubMenuAriaLabel: PropTypes.string,
  containerWidth: PropTypes.string,
  structure: PropTypes.arrayOf(PropTypes.shape(subItemShape) as Validator<SideMenuPropsItem>).isRequired,
}

export const SideMenuDefaultProps = {
  togglingDisabled: false,
  expandSubMenuAriaLabel: 'Toggle sub menu',
  containerWidth: ContainerWidth.sideMenu,
}
