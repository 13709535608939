import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const CogIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M19.04 10.3l1.3.16a.75.75 0 01.66.75v1.58a.75.75 0 01-.66.75l-1.3.16a.19.19 0 00-.15.14c-.17.6-.4 1.18-.72 1.72a.19.19 0 00.01.21l.8 1.04a.75.75 0 01-.05 1l-1.13 1.12a.75.75 0 01-.99.06l-1.04-.8a.19.19 0 00-.2-.02c-.55.31-1.13.55-1.73.72a.19.19 0 00-.14.15l-.16 1.3a.75.75 0 01-.74.66h-1.6a.75.75 0 01-.74-.66l-.16-1.3a.19.19 0 00-.14-.15c-.6-.17-1.18-.4-1.72-.72a.19.19 0 00-.21.01l-1.04.8a.75.75 0 01-.99-.05L5.08 17.8a.75.75 0 01-.07-.99l.81-1.04a.19.19 0 00.01-.2 7.05 7.05 0 01-.71-1.73.19.19 0 00-.16-.14l-1.3-.16a.75.75 0 01-.66-.75v-1.58a.75.75 0 01.66-.75l1.3-.16a.19.19 0 00.16-.14c.16-.6.4-1.18.71-1.73a.19.19 0 00-.01-.2l-.8-1.04a.75.75 0 01.05-.99L6.2 5.07a.75.75 0 01.99-.06l1.04.81a.19.19 0 00.2.01 7.04 7.04 0 011.73-.71.19.19 0 00.14-.16l.16-1.3a.75.75 0 01.75-.66h1.58a.75.75 0 01.75.66l.16 1.3a.19.19 0 00.14.16c.6.16 1.19.4 1.73.71a.19.19 0 00.2-.01l1.04-.8a.75.75 0 01.99.05l1.12 1.13a.75.75 0 01.07.99l-.81 1.04a.19.19 0 00-.01.2c.31.55.55 1.13.71 1.73a.19.19 0 00.16.14zM12 15a3 3 0 100-6 3 3 0 000 6z"
      fill={color}
    />
  </SVG>
)

CogIcon.propTypes = defaultIconPropTypes

CogIcon.defaultProps = defaultPictographIconProps

export { CogIcon }
