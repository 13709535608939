import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const GraphIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M6 13H4a1 1 0 00-1 1v6h4v-6c0-.6-.4-1-1-1zM13 4h-2a1 1 0 00-1 1v15h4V5c0-.6-.4-1-1-1zM20 8h-2a1 1 0 00-1 1v11h4V9c0-.6-.4-1-1-1z"
      fill={color}
    />
  </SVG>
)

GraphIcon.propTypes = defaultIconPropTypes

GraphIcon.defaultProps = defaultPictographIconProps

export { GraphIcon }
