import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { UIMessage } from '../UIMessage'

interface ErrorMessagesProps {
  errorMessages: string[]
}

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledNotification = styled(UIMessage)`
  ${({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    margin: ${spacing.space2}rem 0;
  `}
`

const ErrorMessages: React.FC<ErrorMessagesProps> = ({ errorMessages }) => {
  return (
    <ErrorContainer aria-live="polite" aria-hidden={!errorMessages.length}>
      {!!errorMessages.length && errorMessages.map((msg) => <StyledNotification key={msg} message={msg} />)}
    </ErrorContainer>
  )
}

ErrorMessages.propTypes = {
  errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export { ErrorMessages }
