import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { UIMessage } from '../UIMessage'
import { InputContent, LabelAndInputContainer, StyledLabel, StyledInput, StyledBody } from './Input.style'
import { InputProps } from './Input.types'
import { BodySize } from '../../design-tokens/typography/Body/Body'

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      isInvalid,
      label,
      message,
      helpText,
      leftTemplate,
      rightTemplate,
      className,
      lightBackground,
      messageProps,
      'aria-describedby': ariaDescribedBy,
      noBorderChangeOnFocus,
      isDark,
      ...inputProps
    },
    forwardRef
  ) => {
    const localRef = useRef<HTMLInputElement>()
    const inputRef = (forwardRef as React.MutableRefObject<HTMLInputElement>) || localRef
    const [focused, setFocused] = useState(false)
    const [autocompleteDone, setAutocompleteDone] = useState(false)

    const messageId = `${id}-message`
    const describedByElements = [ariaDescribedBy, messageId].filter(Boolean)

    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true)
      inputProps.onFocus?.(event)
    }

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false)
      inputProps.onBlur?.(event)
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (autocompleteDone && !event.currentTarget.value) {
        setAutocompleteDone(false)
      }
      inputProps.onChange?.(event)
    }

    const focus = () => {
      inputRef.current?.select()
    }

    const onClick = (event: React.MouseEvent<HTMLInputElement>) => {
      // prevent click event to propagate to the container, causing double focus.
      event.stopPropagation()
      inputProps.onClick?.(event)
    }

    /**
     * Prevent default when clicking on anything else except input-tag
     * Input element would otherwise lose focus
     * @param event
     */
    const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
      const targetElem = event.target as HTMLElement
      if (targetElem.tagName && targetElem.tagName !== 'INPUT') {
        event.preventDefault()
      }
    }

    const onAnimationStart = (event: React.AnimationEvent<HTMLInputElement>) => {
      // The autofill doesn't always go through the normal onChange event, make sure that the input field behaves correctly in these cases
      if (event.animationName === 'onAutoFillStart') {
        // The value might be seemingly "" although it is the actual autofilled value in the UI
        setAutocompleteDone(true)
      }
      inputProps.onAnimationStart?.(event)
    }

    return (
      <div onMouseDown={onMouseDown} className={className} role="none">
        <InputContent
          onClick={focus}
          invalid={isInvalid}
          focused={focused}
          hasMessage={Boolean(message)}
          readOnly={inputProps.readOnly}
          disabled={inputProps.disabled}
          lightBackground={lightBackground}
          noBorderChangeOnFocus={noBorderChangeOnFocus}
          isDark={isDark}
        >
          {leftTemplate}
          <LabelAndInputContainer
            hasLeftElement={Boolean(leftTemplate)}
            hasRightElement={Boolean(rightTemplate)}
            hasValue={Boolean(inputProps.value)}
            focused={focused}
          >
            <StyledLabel
              htmlFor={id}
              focused={focused}
              readOnly={inputProps.readOnly}
              hasValue={Boolean(inputProps.value)}
              disabled={inputProps.disabled}
            >
              {label}
            </StyledLabel>
            <StyledInput
              id={id}
              ref={inputRef}
              aria-disabled={inputProps.disabled}
              aria-readonly={inputProps.readOnly}
              aria-invalid={isInvalid}
              aria-describedby={describedByElements.length ? describedByElements.join(' ') : undefined}
              aria-label={label}
              {...inputProps}
              value={inputProps.value ?? ''}
              onAnimationStart={onAnimationStart}
              onFocus={onFocus}
              onChange={onChange}
              onBlur={onBlur}
              onClick={onClick}
            />
          </LabelAndInputContainer>
          {rightTemplate}
        </InputContent>
        {!!helpText && <StyledBody size={BodySize.Five}>{helpText}</StyledBody>}
        <div id={messageId} aria-live="assertive">
          {message && <UIMessage success={!isInvalid} message={message} {...messageProps} />}
        </div>
      </div>
    )
  }
)

export const inputDefaultProps = {
  lightBackground: true,
}

export const inputPropTypes = {
  isInvalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  message: PropTypes.string,
  helpText: PropTypes.string,
  messageProps: PropTypes.object,
  leftTemplate: PropTypes.element,
  rightTemplate: PropTypes.element,
  lightBackground: PropTypes.bool,
  noBorderChangeOnFocus: PropTypes.bool,
  isDark: PropTypes.bool,
}

Input.displayName = 'Input'

Input.defaultProps = inputDefaultProps

Input.propTypes = inputPropTypes
