import * as React from 'react'

export interface RadioGroupContextValue {
  value: string | number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioGroupContext = React.createContext<RadioGroupContextValue | null>(null)

export default RadioGroupContext
