import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const OptionsAndroidIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path d="M10 3h4v4h-4V3zm0 7h4v4h-4v-4zm4 7h-4v4h4v-4z" fill={color} />
  </SVG>
)

OptionsAndroidIcon.propTypes = defaultIconPropTypes

OptionsAndroidIcon.defaultProps = defaultPictographIconProps

export { OptionsAndroidIcon }
