import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const QuietDeliveryIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M21 11.9899C21 16.9575 16.9604 21 12.0009 21C7.26144 21 3.32186 17.2994 3.00189 12.5732C2.98189 12.3318 3.12188 12.0905 3.36185 11.9899C3.58183 11.8894 3.8618 11.9296 4.04178 12.1106C5.22166 13.2972 6.66151 13.9408 8.10135 13.9408C11.301 13.9408 13.8807 11.3263 13.8807 8.12849C13.8807 6.66034 13.2408 5.21229 12.0609 4.0257C11.881 3.84469 11.841 3.58324 11.941 3.36201C12.0409 3.14078 12.2809 3 12.5209 3C17.2804 3.26145 21 7.22346 21 11.9899Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.9375 3C8.125 6.4375 7.4375 5.75 10.875 6.9375C7.4375 8.125 8.125 7.4375 6.9375 10.875C5.75 7.4375 6.4375 8.125 3 6.9375C6.4375 5.75 5.75 6.4375 6.9375 3Z"
      fill={color}
    />
  </SVG>
)

QuietDeliveryIcon.propTypes = defaultIconPropTypes

QuietDeliveryIcon.defaultProps = defaultPictographIconProps
