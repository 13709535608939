import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { SearchProps } from './Search.types'
import { Input, inputDefaultProps, inputPropTypes } from '../Input/Input'
import { IconButton } from '../IconButton'
import { useDebounce } from '../../utils/useDebounce'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { SearchIcon, CloseIcon } from '../../design-tokens/icons'
import { IconProps } from '../../design-tokens/icons/icons.types'
import { iconAttrs } from '../../design-tokens/icons/icons.styled'

export const StyledSearchIcon = styled(SearchIcon).attrs<IconProps>(iconAttrs)<IconProps>`
  flex-shrink: 0;
`

export const Search = React.forwardRef<HTMLInputElement, SearchProps>(
  (
    {
      onChangeWithDebounce,
      onEnter,
      debounceTime,
      clearButtonProps,
      leftTemplate,
      rightTemplate,
      size,
      noBorderChangeOnFocus,
      ...props
    },
    forwardRef
  ) => {
    const localRef = useRef<HTMLInputElement>()
    const inputRef = (forwardRef as React.MutableRefObject<HTMLInputElement>) || localRef
    const debouncedSearchTerm = useDebounce<string>(props?.value as string, debounceTime)

    useEffect(() => {
      onChangeWithDebounce?.(debouncedSearchTerm || '')
    }, [debouncedSearchTerm, onChangeWithDebounce])

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange?.(event)
    }

    const clear = (event: any) => {
      onChange({
        ...event,
        currentTarget: {
          ...event.currentTarget,
          id: props.id,
          value: '',
        },
      } as React.ChangeEvent<HTMLInputElement>)
    }

    const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
      if (onEnter && e.key === KeyboardKeys.Enter) {
        onEnter(inputRef.current.value)
      }
    }

    const optionalCloseButton = Boolean(props.value) ? (
      <IconButton
        type="button"
        aria-label={clearButtonProps?.['aria-label']}
        disabled={props.disabled}
        onClick={clear}
        icon={CloseIcon}
        {...clearButtonProps}
      />
    ) : undefined

    return (
      <Input
        {...props}
        ref={inputRef}
        type="search"
        leftTemplate={leftTemplate || <StyledSearchIcon disabled={props.disabled} />}
        rightTemplate={rightTemplate || optionalCloseButton}
        onChange={onChange}
        onKeyPress={onKeyPress}
        size={size}
        noBorderChangeOnFocus={noBorderChangeOnFocus}
      />
    )
  }
)

Search.displayName = 'Search'

Search.defaultProps = inputDefaultProps

Search.propTypes = {
  ...inputPropTypes,
  clearButtonProps: PropTypes.object,
}
