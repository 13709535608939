import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const BellIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M18.75 16.125C18.4517 16.125 18.1655 16.0065 17.9545 15.7955C17.7435 15.5845 17.625 15.2984 17.625 15V11.3535C17.6683 9.92932 17.2006 8.53664 16.3065 7.42725C15.4124 6.31787 14.1509 5.565 12.75 5.30475V3.75C12.75 3.55109 12.75 3.19089 12.75 2.99988C12.5571 2.99984 12.1989 2.99988 12 2.99988C11.8011 2.99988 11.4106 2.99986 11.25 2.99988C11.25 3.13249 11.25 3.55109 11.25 3.75V5.30475C9.84915 5.565 8.58762 6.31787 7.6935 7.42725C6.79939 8.53664 6.33171 9.92932 6.37502 11.3535V15C6.37502 15.2984 6.25649 15.5845 6.04552 15.7955C5.83454 16.0065 5.54839 16.125 5.25002 16.125C5.05111 16.125 4.86034 16.204 4.71969 16.3447C4.57904 16.4853 4.50002 16.6761 4.50002 16.875C4.50002 17.0739 4.5 17.5 4.5 17.625C4.64113 17.625 5.05111 17.625 5.25002 17.625H18.75C18.9489 17.625 19.2803 17.625 19.5 17.625C19.5 17.5 19.5 17.0739 19.5 16.875C19.5 16.6761 19.421 16.4853 19.2803 16.3447C19.1397 16.204 18.9489 16.125 18.75 16.125Z"
      fill={color}
    />
    <Path
      d="M13.677 18.75H10.323C10.277 18.7501 10.1951 18.7492 10.1251 18.7497C10.125 18.8707 10.1247 19.0048 10.125 19.125C10.125 19.6223 10.3226 20.0992 10.6742 20.4508C11.0258 20.8025 11.5027 21 12 21C12.4973 21 12.9742 20.8025 13.3258 20.4508C13.6775 20.0992 13.875 19.6223 13.875 19.125C13.8753 19.0207 13.875 18.863 13.875 18.751C13.8282 18.7496 13.7231 18.7498 13.677 18.75Z"
      fill={color}
    />
  </SVG>
)

BellIcon.propTypes = defaultIconPropTypes

BellIcon.defaultProps = defaultPictographIconProps
