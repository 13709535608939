import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const ChevronUpIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M12 12.1213L6.06065 18.0607L3.93933 15.9393L12 7.87869L20.0607 15.9393L17.9393 18.0607L12 12.1213Z"
      fill={color}
    />
  </SVG>
)

ChevronUpIcon.propTypes = defaultIconPropTypes

ChevronUpIcon.defaultProps = defaultPictographIconProps
