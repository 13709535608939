import styled, { css } from 'styled-components'

/**
 * Applies correct borders for ToolButton and ToolDropdown components
 */
export const Toolbar = styled.div(
  ({
    theme: {
      xyz: { borderRadius },
    },
  }) => css`
    display: flex;
    flex-wrap: nowrap;

    & > button:first-child,
    & > div:first-child > button {
      border-radius: ${borderRadius.xs}rem 0 0 ${borderRadius.xs}rem;
    }

    & > button:last-child,
    & > div:last-child > button {
      border-radius: 0 ${borderRadius.xs}rem ${borderRadius.xs}rem 0;
    }

    & > button,
    & > div > button {
      border-radius: 0;
    }

    & > button:not(:last-child),
    & > div:not(:last-child) > button {
      border-right-width: 1px;
    }
  `
)
