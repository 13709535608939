import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const AdminIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M14.27 12.6a3.6 3.6 0 003.65-3.55 3.6 3.6 0 00-3.65-3.55 3.6 3.6 0 00-3.66 3.55 3.6 3.6 0 003.66 3.55zM14.32 13.44c-1.77 0-3.46.51-4.72 1.41-1.25.9-1.95 2.12-1.95 3.4v.25H21v-.25c0-1.28-.7-2.5-1.96-3.4a8.19 8.19 0 00-4.72-1.4zM7.02 6.78h.56V6.4c0-.15.06-.3.18-.41a.61.61 0 01.85 0c.11.1.18.26.18.41v4.76c.42.14.78.43 1.02.8a1.8 1.8 0 01-.4 2.36 1.92 1.92 0 01-2.45 0 1.8 1.8 0 01-.4-2.36c.23-.37.6-.66 1.02-.8V9.54h-.34a.56.56 0 01-.39-.15A.54.54 0 016.7 9a.52.52 0 01.16-.38.55.55 0 01.4-.16h.33v-.61h-.56a.57.57 0 01-.39-.16.54.54 0 01-.16-.38c0-.14.06-.28.16-.38.1-.1.24-.16.39-.16zm1.83 5.48a.95.95 0 00-1.03-.2.94.94 0 00-.42.34.9.9 0 00.42 1.36.97.97 0 001.03-.2.9.9 0 000-1.3z"
      fill={color}
    />
  </SVG>
)

AdminIcon.propTypes = defaultIconPropTypes

AdminIcon.defaultProps = defaultPictographIconProps

export { AdminIcon }
