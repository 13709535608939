import React, { ButtonHTMLAttributes, HTMLAttributes } from 'react'
import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { StyledGroupWrapper, StyledFeedbackButton, StyledIcon, StyledBody } from './CSATButtonGroup.styles'
import { BodySize } from '../../design-tokens/typography/Body/Body'

export interface FeedbackButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onSelect'> {
  label: string
  icon: React.ComponentType<DefaultIconProps>
  disabled?: boolean
  selected: boolean
  value?: string | number
  onSelect?(event: React.MouseEvent, value: string | number): void
}

export interface CSATButtonGroupProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'> {
  disabled?: boolean
  name: string
  selectedValue: string | number
  onSelect?(event: React.MouseEvent, value: string | number): void
  children: React.ReactElement[]
}

export const FeedbackButton = ({
  icon: Icon,
  label,
  disabled,
  value,
  selected,
  onSelect,
  ...rest
}: FeedbackButtonProps): JSX.Element => {
  const handleSelect = (event: React.MouseEvent) => {
    onSelect(event, value)
  }

  return (
    <StyledFeedbackButton disabled={disabled} onClick={handleSelect} aria-pressed={selected} {...rest}>
      {!!Icon && <StyledIcon as={Icon} aria-hidden={true} disabled={disabled} />}
      <StyledBody size={BodySize.Five}>{label}</StyledBody>
    </StyledFeedbackButton>
  )
}

export const CSATButtonGroup = ({
  disabled,
  selectedValue,
  onSelect,
  name,
  children,
  ...rest
}: CSATButtonGroupProps): JSX.Element => {
  const handleOnSelect = (event: React.MouseEvent, value: string) => {
    onSelect(event, value)
  }

  return (
    <StyledGroupWrapper aria-label={name} role="group" {...rest}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ...child.props,
          disabled: disabled,
          onSelect: handleOnSelect,
          selected: selectedValue === child.props.value,
        })
      )}
    </StyledGroupWrapper>
  )
}

FeedbackButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

FeedbackButton.defaultProps = {
  disabled: false,
  selected: false,
}

CSATButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func,
}

CSATButtonGroup.defaultProps = {
  disabled: false,
  name: 'Button Group',
}
