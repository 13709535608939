import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Animate from '../../basic-components/Animate'

export interface AllProps extends HTMLAttributes<HTMLDivElement> {
  id: string
  selected: boolean
}

const StyledBody = styled.div<{ selected: boolean }>(
  ({ theme, selected }) => css`
    padding: ${selected
      ? `${theme.spacing.xs}rem ${theme.spacing.xs}rem ${theme.spacing.xs}rem ${theme.spacing.xs}rem`
      : `0 ${theme.spacing.xs}rem`};
  `
)

/**
 *
 * @deprecated Expander component should be used instead
 */
const SelectionBody: React.FC<AllProps> = (props) => {
  const { selected, children, ...rest } = props

  return (
    <StyledBody selected={selected} aria-hidden={!selected} role="region" {...rest}>
      <Animate open={selected}>{children}</Animate>
    </StyledBody>
  )
}

SelectionBody.propTypes = {
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
}

SelectionBody.defaultProps = {
  selected: false,
}

export default SelectionBody
