import React, { AnchorHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { ArrowRightIcon } from '../../design-tokens/icons'
import { Theme } from '@postidigital/posti-theme'
import { ThemeColor } from '../../utils/helpers'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'

export interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  color?: ThemeColor | string
  iconProps?: DefaultIconProps
  hasIcon?: boolean
  icon?: React.ReactNode
  isReversed?: boolean
}

const LinkElement = styled.a<Required<Pick<Props, 'color'>>>`
  display: flex;
  align-items: center;
  text-decoration: none;
  letter-spacing: 0.0125rem;
  cursor: pointer;

  ${({ theme, color }) => css`
    /* Force the color because the default style will override it in some cases */
    color: ${theme.color[color] || color} !important;
    font-weight: ${theme.fontWeight.semiBold};
    font-family: ${theme.fontFamily.primary};
    font-size: ${theme.fontSize.text.md}rem;
    margin-bottom: ${theme.spacing.xxs}rem;
  `}

  &:hover,
    &:focus {
    outline-offset: 0;
    text-decoration: underline !important;
  }
`

const Icon = styled.span<Pick<Props, 'isReversed'>>`
  display: flex;
  flex-shrink: 0;

  ${({ theme, isReversed }) =>
    isReversed
      ? css`
          margin-right: ${theme.spacing.xxs}rem;
          margin-left: 0;
          order: -1;
        `
      : css`
          margin-left: ${theme.spacing.xxs}rem;
          margin-right: 0;
        `}
`
/**
 * ExternalLink Component
 * @deprecated Please use Link component instead
 */

const ExternalLink: React.FC<Props> = ({ children, color, icon, iconProps, hasIcon, isReversed, ...props }) => (
  <LinkElement color={color} {...props}>
    {children}
    {hasIcon && (
      <Icon isReversed={isReversed}>{icon || <ArrowRightIcon color={color} strokeWidth={'1.5'} {...iconProps} />}</Icon>
    )}
  </LinkElement>
)

ExternalLink.propTypes = {
  color: PropTypes.string,
  iconProps: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  icon: PropTypes.node,
  hasIcon: PropTypes.bool,
  isReversed: PropTypes.bool,
}

ExternalLink.defaultProps = {
  color: Theme.color.signalBlue,
  iconProps: {
    width: `${Theme.size.icon.xs}em`,
    height: `${Theme.size.icon.xs}em`,
  },
  hasIcon: true,
  isReversed: false,
}

export default ExternalLink
