import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import TableContext from './TableContext'
import { mediaQuery } from '../../utils/mediaQuery'
import { TableProps, TableSize } from './Table.types'

const StyledTable = styled.table`
  width: 100%;
  display: block;
  overflow-x: auto;
  white-space: nowrap;

  ${mediaQuery.sm`
    display: table;
    overflow-x: auto;
    white-space: normal;
  `}
`

export const Table: React.FC<TableProps> = ({ size, stripes, children, ...rest }) => {
  const table = React.useMemo(() => ({ size, stripes }), [size, stripes])

  return (
    <TableContext.Provider value={table}>
      <StyledTable {...rest}>{children}</StyledTable>
    </TableContext.Provider>
  )
}

Table.propTypes = {
  size: PropTypes.oneOf(Object.values(TableSize)),
  stripes: PropTypes.bool,
}

Table.defaultProps = {
  size: TableSize.lg,
  stripes: true,
}
