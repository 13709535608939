import React, { ComponentPropsWithRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { useTheme } from '../../utils/useTheme'
import { CheckmarkCircledIcon } from '../../design-tokens/icons'

export interface ToggleButtonProps extends ComponentPropsWithRef<'button'> {
  as?: keyof JSX.IntrinsicElements
  icon?: JSX.Element
  label: string
  selected: boolean
}

/**
 * ToggleButton Component - Toggleable button with icon indicator.
 * @deprecated custom implementation should be created instead.
 */
const ToggleButton = React.forwardRef((props: ToggleButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  const { selected, label, disabled, children, icon, ...rest } = props

  const theme = useTheme()

  const renderIcon = () => {
    if (icon) {
      return icon
    }
    return (
      <CheckmarkCircledIcon
        height={`${theme.size.icon.s}em`}
        width={`${theme.size.icon.s}em`}
        color={theme.color.signalBlue}
      />
    )
  }

  return (
    <StyledButton
      aria-pressed={selected}
      aria-label={label}
      disabled={disabled}
      selected={selected}
      tabIndex={disabled ? -1 : 0}
      type="button"
      ref={ref}
      {...rest}
    >
      {children}
      <SelectionIconContainer>{selected && renderIcon()}</SelectionIconContainer>
    </StyledButton>
  )
})

/**
 * TODO: fix typing
 *
 * without "any" here will affect: error TS2589: Type instantiation is excessively deep and possibly infinite
 * in TS version: 3.7.2
 */

const StyledButton = styled.button<any>(
  ({ theme, disabled, selected }) => css`
    align-content: center;
    align-items: center;
    appearance: none;
    background-color: ${theme.color.neutralWhite};
    border-color: ${selected ? theme.color.signalBlue : theme.color.neutralGray5};
    border-radius: 0.25rem;
    border-style: solid;
    border-width: 0.125rem;
    cursor: ${disabled ? 'default' : 'pointer'};
    display: flex;
    flex-direction: row;
    flex: 1 1 17rem;
    justify-content: space-between;
    min-width: 17rem;
    outline: none !important;
    padding: ${theme.spacing.xs}rem;
    transition: all 0.2s ease;

    &:focus:not(:disabled) {
      filter: brightness(92%);
    }

    &:disabled {
      background-color: ${theme.color.neutralPassiveGray};
    }
  `
)

const SelectionIconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
`

ToggleButton.displayName = 'ToggleButton'

ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
}

ToggleButton.defaultProps = {
  selected: false,
}

/** @component */
export default ToggleButton
