import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const BackSpaceIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M19.29 7H8.13a1.74 1.74 0 00-1.21.49L3.5 10.82a1.65 1.65 0 000 2.36l3.42 3.33a1.7 1.7 0 001.2.49H19.3c.45 0 .89-.18 1.2-.49.33-.31.51-.73.51-1.18V8.67c0-.45-.18-.87-.5-1.18A1.73 1.73 0 0019.29 7zm-8.01 3.92a.83.83 0 01-.26-.59.82.82 0 01.25-.6.86.86 0 01.61-.24.87.87 0 01.6.25l.91.88a.29.29 0 00.2.09.3.3 0 00.2-.09l.9-.88a.86.86 0 01.61-.25.87.87 0 01.62.24.83.83 0 01.25.6.82.82 0 01-.26.6l-.9.87a.28.28 0 00-.1.2.27.27 0 00.1.2l.9.88a.83.83 0 01.26.59.82.82 0 01-.25.6.86.86 0 01-.62.24.87.87 0 01-.6-.25l-.9-.89a.3.3 0 00-.4 0l-.91.89a.87.87 0 01-1.2-.01.82.82 0 01-.01-1.17l.9-.88a.28.28 0 00.09-.2.27.27 0 00-.09-.2l-.9-.88z"
      fill={color}
    />
  </SVG>
)

BackSpaceIcon.propTypes = defaultIconPropTypes

BackSpaceIcon.defaultProps = defaultPictographIconProps

export { BackSpaceIcon }
