import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, inputDefaultProps, inputPropTypes } from '../Input/Input'
import { PasswordProps } from './Password.types'
import { EyeIcon, HiddenEyeIcon } from '../../design-tokens/icons'
import { useTheme } from '../../utils/useTheme'
import { IconButton } from '../IconButton'

export const Password = React.forwardRef<HTMLInputElement, PasswordProps>((props, forwardRef) => {
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const type = props.type || (showPassword ? 'text' : 'password')
  const rightTemplate = props.rightTemplate || (
    <IconButton
      onClick={toggleShowPassword}
      disabled={props.disabled}
      icon={showPassword ? HiddenEyeIcon : EyeIcon}
      iconColor={theme.xyz.color.neutralIconGray}
      aria-label={showPassword ? props.hideIconAriaLabel : props.showIconAriaLabel}
    />
  )

  return <Input {...props} ref={forwardRef} type={type} rightTemplate={rightTemplate} />
})

Password.displayName = 'Password'

Password.defaultProps = inputDefaultProps

Password.propTypes = {
  ...inputPropTypes,
  showIconAriaLabel: PropTypes.string,
  hideIconAriaLabel: PropTypes.string,
}
