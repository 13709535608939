import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const FastTrackIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M8.4 12a6.4 6.4 0 016.3-6.5A6.4 6.4 0 0121 12a6.4 6.4 0 01-6.3 6.5A6.4 6.4 0 018.4 12zm5.67-3.25h.95v3.41l2.83 1.74-.47.8-3.31-2.05v-3.9zM4.96 9.68c0-.26.22-.47.49-.47H7c.27 0 .49.21.49.47 0 .25-.22.46-.49.46H5.45a.48.48 0 01-.5-.46zm-.98 2.78c0-.25.22-.46.49-.46H7c.27 0 .49.2.49.46s-.22.47-.49.47H4.47a.48.48 0 01-.5-.47zm3.52 2.79c0 .25-.22.46-.49.46H3.5a.48.48 0 01-.49-.46c0-.26.22-.46.49-.46H7c.27 0 .49.2.49.46z"
      fill={color}
    />
  </SVG>
)

FastTrackIcon.propTypes = defaultIconPropTypes

FastTrackIcon.defaultProps = defaultPictographIconProps

export { FastTrackIcon }
