import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSpring, useChain, animated, config, useSpringRef } from 'react-spring'

import R1a from './Rating1Active'
import R1i from './Rating1Inactive'
import R2a from './Rating2Active'
import R2i from './Rating2Inactive'
import R3a from './Rating3Active'
import R3i from './Rating3Inactive'
import R4a from './Rating4Active'
import R4i from './Rating4Inactive'
import R5a from './Rating5Active'
import R5i from './Rating5Inactive'
import ThankYou1 from './ThankYouRating1'
import ThankYou4 from './ThankYouRating4'
import ThankYou5 from './ThankYouRating5'

import {
  StyledHeadingText,
  StyledItemContainer,
  StyledItemImageContainer,
  StyledItemsContainer,
  StyledRatingText,
  StyledThankYouTitle,
  StyledImageContainer,
  StyledThankYouBody,
} from './FeedbackPanel.style'
import {
  FeedbackPanelSmilieProps,
  FeedbackPanelProps,
  ThankYouMessageProps,
  ANIMATION_TIME,
} from './FeedbackPanel.type'
import { KeyboardKeys } from '../../utils/keyboardNavigation'

// [[active, inactive], ... ]
const images = [
  [R1a, R1i],
  [R2a, R2i],
  [R3a, R3i],
  [R4a, R4i],
  [R5a, R5i],
]

const FeedbackPanelSmilie: React.FC<FeedbackPanelSmilieProps> = ({ number, rated, text, hideText, onClick }) => {
  const i = number - 1
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    if (!rated) {
      setClicked(true)
      onClick(number)
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const key = 'key' in e ? e.key : false

    if (key && key !== KeyboardKeys.Enter && key !== KeyboardKeys.Space) {
      return false
    }

    e.preventDefault()

    handleClick()
  }

  return (
    <StyledItemContainer>
      <StyledItemImageContainer
        role="button"
        aria-pressed={clicked}
        aria-describedby="rating-title"
        aria-label={text}
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        tabIndex={clicked ? -1 : 0}
        clicked={clicked}
      >
        {images[i].map((Image, index) => (
          <Image aria-hidden={true} key={index} />
        ))}
      </StyledItemImageContainer>
      {!hideText && (
        <StyledRatingText size="xs" clicked={clicked}>
          {text}
        </StyledRatingText>
      )}
    </StyledItemContainer>
  )
}

const ThankYouMessage: React.FC<ThankYouMessageProps> = ({ rating, title, body, image, centered }) => {
  const defaultImage = () => {
    if (Number(rating) === 5) {
      return <ThankYou5 aria-hidden={true} />
    } else if (Number(rating === 4)) {
      return <ThankYou4 aria-hidden={true} />
    } else {
      return <ThankYou1 aria-hidden={true} />
    }
  }

  return (
    <>
      <StyledImageContainer centered={centered}>{image || defaultImage()}</StyledImageContainer>
      <StyledThankYouTitle as="h2" size="sm" centered={centered}>
        {title}
      </StyledThankYouTitle>
      {body && <StyledThankYouBody centered={centered}>{body}</StyledThankYouBody>}
    </>
  )
}

/**
 * @deprecated Component has outdated design and old implementation, which wasn't properly tested.
 */

export const FeedbackPanel: React.FC<FeedbackPanelProps> = (props) => {
  const { texts, heading, delay, hideTexts, centered, thankYouMessage, feedbackGiven, onClick, ...rest } = props
  const [rating, setRating] = useState(feedbackGiven || null)
  const rankingOpacityRef = useSpringRef()
  const rankingHeightRef = useSpringRef()
  const thanksOpacityRef = useSpringRef()
  const thanksHeightRef = useSpringRef()
  const selectedTexts = rating ? texts[rating - 1] : null
  const ratingOptions = [1, 2, 3, 4, 5]

  useEffect(() => {
    setRating(feedbackGiven)
  }, [feedbackGiven])

  const handleClick = (givenRating: number) => {
    if (!rating) {
      setRating(givenRating)
      onClick(givenRating)
    }
  }

  /*
   * Ranking animations
   */
  const { opacity: rankingFadeOut } = useSpring({
    ref: rankingOpacityRef,
    config: {
      ...config.slow,
      duration: ANIMATION_TIME,
    },
    delay: !delay ? 0 : delay,
    from: { opacity: 1 },
    to: { opacity: !!rating ? 0 : 1 },
  })

  const { height: rankingHeight } = useSpring({
    ref: rankingHeightRef,
    config: {
      ...config.slow,
      duration: 0,
    },
    delay: !delay ? ANIMATION_TIME : delay + ANIMATION_TIME,
    from: { height: 'auto' },
    to: { height: !!rating ? 0 : 'auto' },
  })

  /*
   * Thank you message animations
   */
  const { opacity: thanksOpacity } = useSpring({
    ref: thanksOpacityRef,
    config: {
      ...config.slow,
      duration: ANIMATION_TIME,
    },
    delay: !delay ? ANIMATION_TIME : delay + ANIMATION_TIME,
    from: { opacity: 0, height: 0 },
    to: { opacity: !!rating ? 1 : 0, height: !!rating ? 'auto' : 0 },
  })

  const { height: thanksHeight } = useSpring({
    ref: thanksOpacityRef,
    config: {
      ...config.slow,
      duration: 0,
    },
    delay: !delay ? ANIMATION_TIME : delay + ANIMATION_TIME,
    from: { height: 0 },
    to: { height: !!rating ? 'auto' : 0 },
  })

  useChain([rankingOpacityRef, rankingHeightRef, thanksHeightRef, thanksOpacityRef], [0, 0.5])

  return (
    <div {...rest}>
      {selectedTexts?.thanksTitle && (
        <animated.div
          style={{
            opacity: thanksOpacity.to((o: number) => o),
            height: thanksHeight.to((h: number) => h),
          }}
        >
          {thankYouMessage && thankYouMessage}
          {!thankYouMessage && (
            <ThankYouMessage
              rating={rating}
              centered={centered}
              title={selectedTexts.thanksTitle}
              body={selectedTexts.thanksMessage}
              image={selectedTexts.thanksImage}
            />
          )}
        </animated.div>
      )}

      {
        <animated.div
          style={{
            opacity: rankingFadeOut.to((o: number) => o),
            height: rankingHeight.to((h: string) => h),
          }}
        >
          <StyledHeadingText as="h2" id="rating-title" centered={centered}>
            {heading}
          </StyledHeadingText>
          <StyledItemsContainer centered={centered}>
            {ratingOptions.map((i) => (
              <FeedbackPanelSmilie
                key={i}
                number={i}
                onClick={handleClick}
                rated={rating}
                hideText={hideTexts}
                text={texts[i - 1].mood}
              />
            ))}
          </StyledItemsContainer>
        </animated.div>
      }
    </div>
  )
}

FeedbackPanel.defaultProps = {
  heading: '',
  texts: [],
  delay: 500,
  hideTexts: false,
  centered: false,
}

FeedbackPanel.propTypes = {
  heading: PropTypes.string.isRequired,
  texts: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  feedbackGiven: PropTypes.oneOf([1, 2, 3, 4, 5]),
  delay: PropTypes.number,
  hideTexts: PropTypes.bool,
  centered: PropTypes.bool,
  thankYouMessage: PropTypes.element,
}

export default FeedbackPanel
