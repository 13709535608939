import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const SimpleThumbsUpIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M5.5716 10.3465H4.2858C3.57567 10.3465 3 10.7856 3 11.3272V17.2116C3 17.7532 3.57567 18.1923 4.2858 18.1923H5.5716C6.28172 18.1923 6.85739 17.7532 6.85739 17.2116V11.3272C6.85739 10.7856 6.28172 10.3465 5.5716 10.3465Z"
      fill={color}
    />
    <Path
      d="M20.6257 11.1311C20.4461 10.8875 20.2131 10.6898 19.9453 10.5536C19.6775 10.4174 19.3822 10.3465 19.0828 10.3465H15.9583C15.8619 10.3459 15.7669 10.3232 15.6804 10.2802C15.5938 10.2372 15.5178 10.1749 15.4581 10.0981C15.3342 9.86165 15.2978 9.58795 15.3552 9.32654L15.9094 6.67072C15.9791 6.40362 15.9857 6.12356 15.9287 5.85336C15.8717 5.58317 15.7528 5.33049 15.5815 5.11594C15.4053 4.91296 15.1868 4.75249 14.9419 4.64621C14.6971 4.53992 14.432 4.49049 14.166 4.50151C13.8999 4.51253 13.6397 4.58373 13.4041 4.70992C13.1685 4.83612 12.9636 5.01413 12.8042 5.23101L9.70286 10.0693C9.64481 10.1532 9.56779 10.2217 9.47829 10.269C9.38879 10.3163 9.28942 10.3411 9.18854 10.3413H8.78609C8.61558 10.3413 8.45206 10.4102 8.33149 10.5328C8.21092 10.6554 8.14319 10.8217 8.14319 10.9951V17.1462C8.14307 17.2669 8.1758 17.3853 8.23776 17.4882C8.29971 17.5912 8.38847 17.6747 8.49421 17.7295C11.44 19.2594 12.1022 19.5 13.6953 19.5C14.0617 19.5 15.3179 19.4634 16.0676 19.4634C17.8574 19.4634 19.0442 18.4173 19.6909 16.2963L20.9176 12.8533C21.0041 12.5629 21.0228 12.256 20.9722 11.957C20.9215 11.658 20.8029 11.3752 20.6257 11.1311Z"
      fill={color}
    />
  </SVG>
)

SimpleThumbsUpIcon.propTypes = defaultIconPropTypes

SimpleThumbsUpIcon.defaultProps = defaultPictographIconProps

export { SimpleThumbsUpIcon }
