import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { XyzTheme } from '@postidigital/posti-theme'
import { CommonCompanyLogoIconProps, IconSize } from '../icons.types'
import styled, { css } from 'styled-components'

const dynamicStyles = {
  [IconSize.lg]: `
    width: ${XyzTheme.iconSize.xl}rem;
    height: ${XyzTheme.iconSize.xl}rem;
    border-radius: 0.75rem;
  `,
  [IconSize.md]: `
    width: ${XyzTheme.iconSize.l}rem;
    height: ${XyzTheme.iconSize.l}rem;
    border-radius: 0.625rem;
  `,
  [IconSize.sm]: `
    width: ${XyzTheme.iconSize.m}rem;
    height: ${XyzTheme.iconSize.m}rem;
    border-radius: ${XyzTheme.borderRadius.xs}rem;
  `,
}

const StyledCompanyLogoIcon = styled.div<CommonCompanyLogoIconProps>(
  ({ backgroundColor, size }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: ${backgroundColor};
    ${dynamicStyles[size]};
  `
)

const CompanyLogoBase: React.FC<CommonCompanyLogoIconProps> = ({ backgroundColor, icon, ...props }) => (
  <StyledCompanyLogoIcon backgroundColor={backgroundColor} {...props}>
    {icon}
  </StyledCompanyLogoIcon>
)

CompanyLogoBase.defaultProps = {
  size: IconSize.lg,
  backgroundColor: XyzTheme.color.brandPink,
}

CompanyLogoBase.propTypes = {
  size: PropTypes.oneOf(Object.keys(IconSize)).isRequired as Validator<IconSize>,
  backgroundColor: PropTypes.string,
}

export { CompanyLogoBase }
