import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const ListIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M10.12 8h9.52c.36 0 .7-.14.96-.4a1.32 1.32 0 000-1.88c-.25-.25-.6-.39-.96-.39h-9.52c-.36 0-.7.14-.96.4a1.32 1.32 0 000 1.88c.25.25.6.39.96.39zM19.64 10.67h-9.52c-.36 0-.7.14-.96.39a1.32 1.32 0 000 1.88c.25.25.6.4.96.4h9.52c.36 0 .7-.15.96-.4a1.32 1.32 0 000-1.88c-.25-.25-.6-.4-.96-.4zM19.64 16h-9.52c-.36 0-.7.14-.96.39a1.32 1.32 0 000 1.89c.25.25.6.39.96.39h9.52c.36 0 .7-.14.96-.4a1.32 1.32 0 000-1.88c-.25-.25-.6-.39-.96-.39zM4.7 8.33c.94 0 1.7-.74 1.7-1.66C6.4 5.75 5.64 5 4.7 5S3 5.75 3 6.67c0 .92.76 1.66 1.7 1.66zM4.7 13.67c.94 0 1.7-.75 1.7-1.67 0-.92-.76-1.67-1.7-1.67S3 11.08 3 12c0 .92.76 1.67 1.7 1.67zM4.7 19c.94 0 1.7-.75 1.7-1.67 0-.92-.76-1.66-1.7-1.66S3 16.4 3 17.33c0 .92.76 1.67 1.7 1.67z"
      fill={color}
    />
  </SVG>
)

ListIcon.propTypes = defaultIconPropTypes

ListIcon.defaultProps = defaultPictographIconProps

export { ListIcon }
