import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const AsteriskIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M16.1912 12.0228L21 14.7114L18.8382 18.4481L13.9853 15.4861L14.0735 21H9.92647L9.97059 15.4405L5.11765 18.4481L3 14.7114L7.80882 12.0228L3 9.28861L5.11765 5.5519L9.97059 8.55949L9.92647 3H14.0735L13.9853 8.55949L18.8382 5.5519L21 9.28861L16.1912 12.0228Z"
      fill={color}
    />
  </SVG>
)

AsteriskIcon.propTypes = defaultIconPropTypes

AsteriskIcon.defaultProps = defaultPictographIconProps
