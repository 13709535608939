import React from 'react'

import { DefaultIconProps, defaultPictographIconProps, defaultIconPropTypes } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const HiddenEyeIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M20.13 10.83c-1.88-2.06-5-4.24-8.13-4.2-3.13-.04-6.25 2.14-8.13 4.2L3 11.88l.87 1.04c1.86 2.04 4.94 4.2 8.02 4.2h.21c3.1 0 6.17-2.16 8.03-4.2l.87-1.04-.87-1.05zm-11.4 1.05a3.25 3.25 0 013.9-3.2 3.27 3.27 0 012.39 4.44 3.26 3.26 0 01-5.33 1.06 3.25 3.25 0 01-.96-2.3z"
      fill={color}
    />
    <Path d="M12 13.3a1.42 1.42 0 100-2.85 1.42 1.42 0 000 2.84z" fill={color} />
    <Path
      d="M5.17 18.83a.96.96 0 010-1.35L17.9 4.68a.94.94 0 011.34 0c.37.37.37.97 0 1.34L6.51 18.82a.94.94 0 01-1.34 0z"
      fill={color}
    />
  </SVG>
)

HiddenEyeIcon.propTypes = defaultIconPropTypes

HiddenEyeIcon.defaultProps = defaultPictographIconProps
