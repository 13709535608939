import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const ScanCodeIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M6.6 20h2.7v-2.67H6.6v-2.66H3.9V20h2.7zM9.3 4H3.9v5.33h2.7V6.67h2.7V4zm5.4 0h5.4v5.33h-2.7V6.67h-2.7V4zm0 16h5.4v-5.33h-2.7v2.66h-2.7V20zM4.35 10.67C3.6 10.67 3 11.27 3 12c0 .74.6 1.33 1.35 1.33h15.3c.75 0 1.35-.6 1.35-1.33 0-.74-.6-1.33-1.35-1.33H4.35z"
      fill={color}
    />
  </SVG>
)

ScanCodeIcon.propTypes = defaultIconPropTypes

ScanCodeIcon.defaultProps = defaultPictographIconProps

export { ScanCodeIcon }
