import { PreciseTimeSlot, PreparedTimePickerProps } from '../TimePicker/TimePicker.types'
import { TimePickerGroupDay, TimeSlotsForDate } from './TImePickerGroup.types'

export const getHoursDefault = (timestamp?: Date): number => {
  if (timestamp) {
    return timestamp.getHours()
  }
  return 0
}

export const getPreparedTimeSlots = (
  timeSlots: TimeSlotsForDate,
  earliestHour: number,
  latestHour: number,
  getHourFromDate: (date: Date) => number = getHoursDefault
): TimePickerGroupDay => {
  let currentHour = earliestHour
  const slots: PreparedTimePickerProps[] = []
  timeSlots.slots.map((slot) => {
    const from = getHourFromDate(slot.from)
    const to = getHourFromDate(slot.to)
    // add disabled slot when there is a timetable gap
    if (from !== currentHour) {
      slots.push({
        from: currentHour,
        to: from,
        disabled: true,
        fromDate: slot.from,
        toDate: slot.to,
      })
    }

    slots.push({
      from,
      to,
      disabled: slot.disabled,
      fromDate: slot.from,
      toDate: slot.to,
    })

    currentHour = to
  })

  if (slots.length !== 0) {
    const latestSlotEnds = slots[slots.length - 1].to
    if (latestSlotEnds !== latestHour) {
      const from = latestSlotEnds
      const to = latestHour
      slots.push({
        from,
        to,
        disabled: true,
        fromDate: undefined,
        toDate: undefined,
      })
    }
  }

  return {
    slots,
    timeSlots,
    earliestHour,
    latestHour,
    date: timeSlots.date,
    hasTimeSlots: slots.length > 0,
  }
}

export const isSlotSelected = (fromDate: Date, toDate: Date, selectedTimeSlot: PreciseTimeSlot | null): boolean => {
  if (selectedTimeSlot === null || !fromDate || !toDate) return false

  return selectedTimeSlot.from.valueOf() === fromDate.valueOf() && selectedTimeSlot.to.valueOf() === toDate.valueOf()
}
