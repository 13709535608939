import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

const AddInvoiceIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path d="M17.4 19.88v-1.8h-1.8v-1.8h1.8v-1.8h1.8v1.8H21v1.8h-1.8v1.8h-1.8z" fill={color} />
    <Path d="M4 3a1 1 0 00-1 1v17h10.87a5.4 5.4 0 015.33-8.84V6.6L15.6 3H4z" fill={color} />
  </SVG>
)

AddInvoiceIcon.propTypes = defaultIconPropTypes
AddInvoiceIcon.defaultProps = defaultPictographIconProps

export { AddInvoiceIcon }
