import React, { useRef } from 'react'

/**
 *
 */
const useFirstRender = (): boolean => {
  const ref = useRef(true)
  const firstRender = ref.current
  ref.current = false
  return firstRender
}

export default useFirstRender
