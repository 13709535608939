/* eslint-disable import/named */
import styled, { css, DefaultTheme } from 'styled-components'
import { TrackFillProps } from './Slider.types'
import { StyledTooltip } from '../Tooltip/Tooltip.style'
import { THUMB_WIDTH } from './utils'

export const SliderContainer = styled.div(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    width: 100%;
    height: ${spacing.space5}rem;
    display: flex;
    align-items: center;
    position: relative;
  `
)

export const Track = styled.div(
  ({
    theme: {
      xyz: { color },
    },
  }) => css`
    background-color: ${color.neutralPassiveGray};
    position: relative;
    height: 6px;
    width: 100%;
    border-radius: 3px;
  `
)

export const TrackFill = styled.div.attrs<TrackFillProps>(({ thumbX }) => ({
  style: {
    width: thumbX + THUMB_WIDTH * 0.5,
  },
}))<TrackFillProps>(
  ({
    theme: {
      xyz: { color },
    },
  }) => {
    return css`
        left: 0;
        height: 6px;
        border-radius: 3px;
        background-color: ${color.signalBlue};
        position: absolute;
        top: 0;
      }
    `
  }
)

const thumbStyle = ({
  theme: {
    xyz: { spacing, color, borderRadius },
  },
}: {
  theme: DefaultTheme
}) => css`
  appearance: none;
  padding: 0;
  margin: 0;
  width: ${spacing.space5}rem;
  height: ${spacing.space5}rem;
  background: ${color.neutralWhite};
  border: 3px solid ${color.signalBlue};
  box-sizing: border-box;
  border-radius: ${borderRadius.sm}rem;
  cursor: pointer;

  :hover {
    border-color: ${color.signalHoverBlue};
  }
`

const thumbFocusedStyle = ({
  theme: {
    xyz: { color },
  },
}: {
  theme: DefaultTheme
}) => css`
  box-shadow: 0px 0px 7px ${color.signalBlue};
`

const thumbDisabledStyle = ({
  theme: {
    xyz: { color },
  },
}: {
  theme: DefaultTheme
}) => css`
  background: ${color.neutralPassiveGray};
  border-color: ${color.neutralPassiveGray};
  cursor: initial;
`

export const Thumb = styled.input(
  ({
    theme: {
      xyz: { zIndex },
    },
  }) => css`
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    width: 100%;
    height: 100%;
    background: none;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
      border: none;
      z-index: ${zIndex.zIndexDefault};

      ::-webkit-slider-thumb {
        ${thumbFocusedStyle}
      }

      ::-moz-range-thumb {
        ${thumbFocusedStyle}
      }

      ::-ms-thumb {
        ${thumbFocusedStyle}
      }
    }

    &:focus:not(:focus-visible) {
      outline: none;
      ::-webkit-slider-thumb {
        box-shadow: none;
      }

      ::-moz-range-thumb {
        box-shadow: none;
      }

      ::-ms-thumb {
        box-shadow: none;
      }
    }

    ::-webkit-slider-thumb {
      ${thumbStyle}
    }

    ::-moz-range-thumb {
      ${thumbStyle}
    }

    ::-ms-thumb {
      ${thumbStyle}
    }

    ::-webkit-slider-runnable-track {
      background: none;
    }

    ::-moz-range-track {
      background: none;
    }

    ::-ms-track {
      background: none;
      border: none;
      color: transparent;
    }

    ::-ms-fill-lower {
      background: none;
    }

    ::-ms-fill-upper {
      background: none;
    }

    ::-ms-tooltip {
      display: none;
    }

    :disabled {
      ::-webkit-slider-thumb {
        ${thumbDisabledStyle}
      }

      ::-moz-range-thumb {
        ${thumbDisabledStyle}
      }

      ::-ms-thumb {
        ${thumbDisabledStyle}
      }
    }
  `
)

export const LabelContainer = styled.div(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: ${spacing.space3}rem;
  `
)

export const Tooltip = styled(StyledTooltip)(
  ({
    theme: {
      xyz: { spacing },
    },
  }) => css`
    :before {
      width: ${spacing.space2}rem;
      height: ${spacing.space2}rem;
      bottom: -${spacing.space1 * 0.5}rem;
    }
  `
)
