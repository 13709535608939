import styled, { css } from 'styled-components'
import { Size } from './index'

interface InputProps {
  themeSize?: keyof typeof Size | undefined
}

interface InputFieldProps {
  isFocused: boolean
  themeSize?: keyof typeof Size | undefined
  isInvalid: boolean
}

// in rems
const minHeights = {
  lg: '4',
  md: '3',
  sm: '2',
}

export const InputWrapper = styled.div<InputFieldProps>(
  ({ theme, isFocused, themeSize, isInvalid }) => css`
    background-color: ${isFocused ? theme.color.neutralWhite : theme.color.neutralGray2};
    display: flex;
    align-items: center;
    min-height: ${minHeights[themeSize]}rem;
    height: 2em;
    width: 100%;
    padding: 0 ${theme.spacing.xs}rem;
    border-style: solid;
    border-color: transparent;
    border-width: 3px;
    border-radius: ${themeSize === Size.sm ? '1rem' : '4px'};
    transition: all 0.2s ease;
    will-change: border-color;

    ${isFocused &&
    `
    border-color: ${theme.color.signalBlue};
  `}

    ${!isFocused &&
    `
    :hover, :active {
      filter: brightness(92%);
    }
  `}

    ${isInvalid &&
    !isFocused &&
    `
    border-width: 0;
    border-bottom-color: ${isInvalid ? theme.color.signalRed : theme.color.signalGreen} !important;
    border-bottom-width: 6px;
  `}

  ${!isInvalid &&
    themeSize !== Size.sm &&
    `
    box-shadow: 0 2px 0 ${theme.color.neutralPassiveGray};
  `}
  `
)

export const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    margin-left: ${theme.spacing.xs}rem;
  `
)

export const InputField = styled.input<InputProps>(
  ({ theme, themeSize }) => css`
    background: none;
    border: 0;
    margin: 0;
    letter-spacing: 0.02rem;
    padding: 0 0.125rem 0 0;
    width: 100%;
    color: ${theme.color.neutralNetworkGray};
    font-family: ${theme.fontFamily.primary};
    font-weight: ${theme.fontWeight.semiBold};
    font-size: ${theme.fontSize.text[themeSize]}rem;

    /* Force the caret color to be darker than the actual typed text for clarity */
    caret-color: ${theme.color.neutralBlack};

    /* Remove search input default styling in webkit browsers */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    /* Placeholder text could be a bit 'grayer' than the actual text */
    ::placeholder {
      color: ${theme.color.neutralNetworkGray};
      font-size: ${theme.fontSize.text[themeSize]};
      font-weight: ${theme.fontWeight.normal};
    }

    /* Hide the clear/cross button that appears in MS browsers. Instead we should add our own clear button if needed */
    ::-ms-clear {
      display: none;
    }
    /* Clear native input appearance from webkit browsers, so that it doesn't conflict our own */
    -webkit-appearance: none;

    :focus {
      outline: none;
    }

    /* Clear the red box shadow firefox adds around :invalid inputs */
    :not(output):-moz-ui-invalid {
      box-shadow: none;
    }
  `
)
