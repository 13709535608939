import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledWrapper,
  StyledTitle,
  StyledSubTitle,
  StyledBody,
  StyledRow,
  StyledDeliveryTime,
  StyledIconContainer,
  StyledMainBox,
  StyledInnerLines,
  StyledBoxTitle,
  StyledBoxLines,
} from './ProductCard.style'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { HeartIcon } from '../../design-tokens/icons'
import { useTheme } from '../../utils/useTheme'

export enum ColorScheme {
  white = 'white',
  gray = 'gray',
}

export interface AllProps {
  selected: boolean
  disabled?: boolean
  title: string
  subTitle?: string
  body?: string
  deliveryTime?: string
  scheme: keyof typeof ColorScheme
  onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
}

/**
 * @deprecated Component is unrelated to posti-web-components and should be moved to its own application.
 */
const ProductCard: React.FC<AllProps> = (props) => {
  const { selected, disabled, title, body, subTitle, deliveryTime, onClick, ...rest } = props
  const theme = useTheme()

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      onClick(e)
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (disabled || (e.key !== KeyboardKeys.Enter && e.key !== KeyboardKeys.Space)) {
      return null
    }

    e.preventDefault()
    onClick(e)
  }

  return (
    <StyledWrapper
      role="button"
      selected={selected}
      disabled={disabled}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      tabIndex={disabled ? -1 : 0}
      aria-disabled={disabled}
      aria-pressed={selected}
      {...rest}
    >
      <StyledTitle as="div" size="xs">
        {title}
      </StyledTitle>
      {subTitle && <StyledSubTitle size="xs">{subTitle}</StyledSubTitle>}
      {body && <StyledBody size="sm">{body}</StyledBody>}
      <StyledRow>
        <StyledIconContainer aria-hidden={true} disabled={disabled}>
          <StyledBoxLines selected={selected} />
          <StyledMainBox selected={selected}>
            <StyledBoxTitle>{subTitle}</StyledBoxTitle>
            <StyledInnerLines />
          </StyledMainBox>
        </StyledIconContainer>
        <StyledDeliveryTime size="xs">
          <HeartIcon color={disabled ? theme.color.neutralPassiveGray : theme.color.neutralIconGray} />
          {deliveryTime}
        </StyledDeliveryTime>
      </StyledRow>
    </StyledWrapper>
  )
}

ProductCard.propTypes = {
  scheme: PropTypes.oneOf(Object.values(ColorScheme)),
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  body: PropTypes.string,
  deliveryTime: PropTypes.string,
  onClick: PropTypes.func,
}

ProductCard.defaultProps = {
  selected: false,
  disabled: false,
  scheme: ColorScheme.white,
}

export default ProductCard
