import { debounce } from 'debounce'
import PropTypes, { Validator } from 'prop-types'
import React, { useEffect, useRef, useState, isValidElement, cloneElement } from 'react'
import styled, { css } from 'styled-components'
import { ArrowRightIcon, CloseIcon, SearchIcon } from '../../design-tokens/icons'
import { KeyboardKeys } from '../../utils/keyboardNavigation'
import { useTheme } from '../../utils/useTheme'
import Button from '../../basic-components/Button'
import { Container, InputField, InputWrapper } from './SearchBar.styles'
import FormHelperText from '../FormHelperText'

export enum Size {
  lg = 'lg',
  md = 'md',
  sm = 'sm',
}

export interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  inputRef?: React.RefObject<HTMLInputElement>
  hasSubmitButton?: boolean
  minCharAmount?: number
  onSubmitButtonClick?: (searchStr: string) => void
  onEnter?: (searchStr: string) => void
  size?: keyof typeof Size | undefined
  isInvalid?: boolean
  message?: string
  className?: string
  onChangeDebounceTime?: number
  onChangeWithDebounce?: (searchStr: string) => void
  /**
   * Custom icon for the left-hand side. If not given, defaults to SearchIcon
   */
  leftContent?: JSX.Element
  /**
   * Custom content for the right-hand side. If provided, this element is visible only when the field is empty
   */
  rightContent?: JSX.Element
}

const StyledCloseIcon = styled(CloseIcon)(
  ({ theme }) => css`
    border-radius: 50%;
    background: ${theme.color.neutralGray2};
    height: 1.25rem;
    flex: 0 0 1.25rem;
    padding: 3px;
    margin-right: 1rem;
    &:hover {
      cursor: pointer;
    }
  `
)

const ClearInput = (props) => {
  const theme = useTheme()
  const handleOnMouseDown = (e) => {
    e.preventDefault()
  }

  return (
    props.hasValue && (
      <StyledCloseIcon
        onMouseDown={handleOnMouseDown}
        onClick={props.onClick}
        width="0.625em"
        height="0.625em"
        color={theme.color.neutralIconGray}
      />
    )
  )
}

const LeftIconElem = styled(SearchIcon)`
  flex: 0 0 1.5em;
`
const LeftIcon = (props) => {
  const theme = useTheme()

  if (isValidElement(props.leftContent)) {
    return cloneElement(props.leftContent, {
      'aria-hidden': 'true',
      width: '1.5em',
      height: '1.5em',
      color: `${props.isFocused ? theme.color.signalBlue : theme.color.neutralIconGray}`,
      style: { flex: '0 0 1.5em' },
    })
  }

  return (
    <LeftIconElem
      width="1.5em"
      height="1.5em"
      color={props.isFocused ? theme.color.signalBlue : theme.color.neutralIconGray}
      aria-hidden="true"
    />
  )
}

const SubmitButton: any = (props) => {
  const onClick = (e) => {
    e.stopPropagation()
    props.onClick()
  }

  return <Button disabled={!props.isActive} onClick={onClick} type="submit" aria-label="Search" icon={ArrowRightIcon} />
}

let debouncedOnChange = undefined

/**
 * @deprecated please use Search component instead
 */
const SearchBar: React.FC<Props> = ({
  inputRef,
  minCharAmount,
  onSubmitButtonClick,
  onEnter,
  hasSubmitButton,
  size,
  isInvalid,
  message,
  className,
  onChangeDebounceTime,
  onChangeWithDebounce,
  leftContent,
  rightContent,
  ...inputElementProps
}) => {
  const localRef = useRef<HTMLInputElement>()
  const ref = inputRef || localRef
  const [isFocused, setFocus] = useState(false)
  const [hasValue, setHasValue] = useState(false)
  const [hasMinCharAmount, setHasMinCharAmount] = useState(false)

  useEffect(() => {
    setHasValue(inputElementProps?.value !== '' && inputElementProps?.value != undefined)
  }, [inputElementProps.value])

  const initOnChangeDebounce = () => {
    debouncedOnChange = debounce((e: React.ChangeEvent<HTMLInputElement>, callBack) => {
      callBack(e.target.value)
    }, onChangeDebounceTime || 0)
  }

  // Initialize debounce only on initial render. Clearing input causes re-render, which would break debounce.
  useEffect(initOnChangeDebounce, [onChangeDebounceTime])

  const onFocusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = inputElementProps

    setFocus(true)

    if (onFocus) {
      onFocus(e)
    }
  }
  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = inputElementProps

    setFocus(false)

    if (onBlur) {
      onBlur(e)
    }
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!ref || !ref.current) {
      return
    }

    if ('persist' in e) {
      e.persist()
    }

    const { onChange } = inputElementProps

    if (onChange) {
      onChange(e)
      onChangeWithDebounce ? debouncedOnChange(e, onChangeWithDebounce) : null
    }

    setHasMinCharAmount(ref.current.value && ref.current.value.length >= minCharAmount)
  }

  const onClearHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeHandler({
      ...e,
      currentTarget: {
        ...e.currentTarget,
        id: inputElementProps.id,
        value: '',
      },
    })
  }

  const focusToInput = () => {
    if (ref && ref.current) {
      ref.current.select()
    }
  }

  const onInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    // prevent click event to propagate to the container, causing double focus.
    e.stopPropagation()

    const { onClick } = inputElementProps

    if (onClick) {
      onClick(e)
    }
  }

  const handleSubmitButtonClick = () => {
    if (onSubmitButtonClick && hasMinCharAmount) {
      onSubmitButtonClick(ref.current.value)
    }
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (onEnter && e.key === KeyboardKeys.Enter && hasMinCharAmount) {
      onEnter(ref.current.value)
    }
  }

  /**
   * Prevent default when clicking on anything else except input-tag
   * Input element would otherwise lose focus
   * @param e
   */
  const onInputContainerMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
    const targetElem = e.target as HTMLElement
    if (targetElem.tagName && targetElem.tagName !== 'INPUT') {
      e.preventDefault()
    }
  }

  const showRightContent = !hasSubmitButton ? !!rightContent : !!rightContent && !hasValue

  return (
    <div onClick={focusToInput} onMouseDown={onInputContainerMouseDown} className={className} role="none">
      <InputWrapper isFocused={isFocused} themeSize={size} isInvalid={isInvalid}>
        <LeftIcon isFocused={isFocused} leftContent={leftContent} />
        <Container>
          <InputField
            {...inputElementProps}
            type="search"
            ref={ref}
            onFocus={onFocusHandler}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            onClick={onInputClick}
            onKeyPress={onKeyPress}
            themeSize={size}
            aria-label="Search"
            minLength={minCharAmount ? minCharAmount : undefined}
          />
        </Container>
        <ClearInput onClick={onClearHandler} hasValue={hasValue} />
        {showRightContent && rightContent}
        {!showRightContent && hasSubmitButton && (
          <SubmitButton onClick={handleSubmitButtonClick} isActive={hasMinCharAmount && !isInvalid} />
        )}
      </InputWrapper>
      {message && <FormHelperText isInvalid={isInvalid} text={message} />}
    </div>
  )
}

SearchBar.propTypes = {
  // In SSR builds defining the proptype as PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }) doesn't work,
  // also we don't really need to verify that the input ref is an instance of HTMLInputElement here
  inputRef: PropTypes.shape({ current: PropTypes.any }) as Validator<React.RefObject<HTMLInputElement>>,
  hasSubmitButton: PropTypes.bool,
  minCharAmount: PropTypes.number,
  onSubmitButtonClick: PropTypes.func,
  onEnter: PropTypes.func,
  size: PropTypes.oneOf(Object.keys(Size)) as Validator<Size>,
  isInvalid: PropTypes.bool,
  message: PropTypes.string,
  className: PropTypes.string,
  onChangeDebounceTime: PropTypes.number,
  onChangeWithDebounce: PropTypes.func,
  leftContent: PropTypes.element,
  rightContent: PropTypes.element,
}

SearchBar.defaultProps = {
  hasSubmitButton: true,
  minCharAmount: 0,
  size: Size.lg,
  isInvalid: false,
  onChangeDebounceTime: 300,
}

export default SearchBar
