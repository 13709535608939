import React from 'react'

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onOutsideClick: (e: Event) => any
  className?: string
  /* List ids for elements that are excluded from triggering the callback in addition to the children of this component */
  exclusions?: string[]
}

class OutsideClick extends React.Component<Props> {
  ref = React.createRef<HTMLDivElement>()
  clickEventRef: Event

  onComponentClick = (event: Event): void => {
    this.clickEventRef = event
  }

  onContextMenuClick = (event: Event): void => {
    this.clickEventRef = event
  }

  onDocumentClick = (event: Event): void => {
    const clickEventTarget = this.clickEventRef && this.clickEventRef.target
    if (event.target !== clickEventTarget) {
      this.props.onOutsideClick(event)
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    this.unbindExclusions(prevProps.exclusions)
    this.bindExclusions(this.props.exclusions)
  }

  bindExclusions = (ids: string[] = []): void => {
    ids.forEach((id) => {
      const e = document.getElementById(id)

      if (e) {
        e.addEventListener('click', this.onComponentClick)
        e.addEventListener('contextmenu', this.onContextMenuClick)
      }
    })
  }

  unbindExclusions = (ids: string[] = []): void => {
    ids.forEach((id) => {
      const e = document.getElementById(id)
      if (e) {
        e.removeEventListener('click', this.onComponentClick)
        e.removeEventListener('contextmenu', this.onContextMenuClick)
      }
    })
  }

  componentDidMount(): void {
    const element = this.ref.current
    if (element) {
      element.addEventListener('click', this.onComponentClick)
      element.addEventListener('contextmenu', this.onContextMenuClick)
      document.addEventListener('click', this.onDocumentClick)

      this.bindExclusions(this.props.exclusions)
    }
  }

  componentWillUnmount(): void {
    const element = this.ref.current
    if (element) {
      element.removeEventListener('click', this.onComponentClick)
      element.removeEventListener('contextmenu', this.onContextMenuClick)
      document.removeEventListener('click', this.onDocumentClick)
      this.unbindExclusions(this.props.exclusions)
    }
  }

  render(): JSX.Element {
    const { className, children, onOutsideClick, ...rest } = this.props
    return (
      <div ref={this.ref} className={className} {...rest}>
        {children}
      </div>
    )
  }
}

export default OutsideClick
