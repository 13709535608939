import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const ChevronRightIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M13.8787 12L7.93933 6.06065L10.0607 3.93933L18.1213 12L10.0607 20.0607L7.93933 17.9393L13.8787 12Z"
      fill={color}
    />
  </SVG>
)

ChevronRightIcon.propTypes = defaultIconPropTypes

ChevronRightIcon.defaultProps = defaultPictographIconProps
