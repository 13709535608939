import React from 'react'
import { Path, SVG, Rect } from '../icons/icons.styled'
import { DefaultIconProps } from '../icons/icons.types'

export interface DesignSystemLogoWithBackgroundProps extends DefaultIconProps {
  backgroundColor: string
  primaryColor: string
  secondaryColor: string
}

const DesignSystemLogoWithBackground: React.FC<DesignSystemLogoWithBackgroundProps> = ({
  backgroundColor,
  primaryColor,
  secondaryColor,
  width = '166',
  height = '85',
  viewBox = '0 0 166 85',
  ...props
}) => (
  <SVG width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Rect width={width} height={height} fill={backgroundColor} />
    <Path fillRule="evenodd" clipRule="evenodd" d="M33 18H19V32V58H33V32L59 32V18H33Z" fill={primaryColor} />
    <Path fillRule="evenodd" clipRule="evenodd" d="M59 72H33V58L59 58L59 32H73L73 58V72H59Z" fill={secondaryColor} />
    <Path
      d="M89.6385 20C90.8722 20 91.7834 20.3477 92.372 21.043C92.9719 21.7384 93.2718 22.6446 93.2718 23.7618C93.2718 24.9359 92.9719 25.882 92.372 26.6002C91.7834 27.3069 90.8722 27.6603 89.6385 27.6603H86.3447V31.3708H83.3226V20H89.6385ZM88.6368 25.3349C89.1574 25.3349 89.5366 25.1981 89.7743 24.9245C90.0233 24.6395 90.1478 24.2576 90.1478 23.7789C90.1478 22.7985 89.6555 22.3084 88.6707 22.3084H86.3447V25.3349H88.6368Z"
      fill={primaryColor}
    />
    <Path
      d="M99.0135 31.6444C97.8703 31.6444 96.9365 31.4335 96.2121 31.0117C95.4877 30.5899 94.9614 30.0371 94.6331 29.3531C94.3162 28.6691 94.1577 27.9168 94.1577 27.096C94.1577 26.2753 94.3162 25.5229 94.6331 24.839C94.9614 24.155 95.4877 23.6022 96.2121 23.1804C96.9365 22.7586 97.8703 22.5477 99.0135 22.5477C100.157 22.5477 101.091 22.7586 101.815 23.1804C102.539 23.6022 103.066 24.155 103.394 24.839C103.722 25.5229 103.886 26.2753 103.886 27.096C103.886 27.9168 103.722 28.6691 103.394 29.3531C103.066 30.0371 102.539 30.5899 101.815 31.0117C101.091 31.4335 100.157 31.6444 99.0135 31.6444ZM97.078 27.096C97.078 27.9396 97.2251 28.5267 97.5194 28.8572C97.8137 29.1878 98.3117 29.3531 99.0135 29.3531C99.7153 29.3531 100.213 29.1878 100.508 28.8572C100.802 28.5267 100.949 27.9396 100.949 27.096C100.949 26.2525 100.802 25.6654 100.508 25.3349C100.213 25.0043 99.7153 24.839 99.0135 24.839C98.3117 24.839 97.8137 25.0043 97.5194 25.3349C97.2251 25.6654 97.078 26.2525 97.078 27.096Z"
      fill={primaryColor}
    />
    <Path
      d="M107.397 28.6178C107.431 28.9826 107.595 29.2847 107.889 29.5241C108.195 29.7635 108.608 29.8832 109.129 29.8832C110.011 29.8832 110.453 29.6153 110.453 29.0795C110.453 28.8287 110.374 28.6292 110.215 28.4811C110.068 28.3329 109.768 28.2303 109.315 28.1733L107.516 27.9681C106.689 27.8769 106.061 27.6147 105.631 27.1815C105.201 26.737 104.986 26.1442 104.986 25.4033C104.986 24.4343 105.348 23.7162 106.072 23.2488C106.808 22.7814 107.782 22.5477 108.993 22.5477C110.226 22.5477 111.206 22.7757 111.93 23.2317C112.654 23.6877 113.056 24.3944 113.135 25.352H110.487C110.396 24.6452 109.904 24.2918 109.01 24.2918C108.546 24.2918 108.2 24.3716 107.974 24.5312C107.759 24.6794 107.651 24.8789 107.651 25.1297C107.651 25.5742 107.985 25.8307 108.653 25.8991L110.402 26.0701C111.398 26.1727 112.117 26.4406 112.558 26.8738C113.011 27.2955 113.237 27.9168 113.237 28.7375C113.237 29.6267 112.915 30.3334 112.27 30.8578C111.624 31.3822 110.56 31.6444 109.078 31.6444C107.629 31.6444 106.554 31.3594 105.852 30.7894C105.15 30.2194 104.782 29.4956 104.748 28.6178H107.397Z"
      fill={primaryColor}
    />
    <Path
      d="M118.47 31.3708C117.214 31.3708 116.331 31.0972 115.821 30.55C115.312 30.0029 115.057 29.1536 115.057 28.0023V25.0271H113.563V23.6592L115.295 22.8897L116.501 20.7524H117.978V22.8213H120.287V25.0271H117.978V29.0453H120.015V31.3708H118.47Z"
      fill={primaryColor}
    />
    <Path
      d="M124.575 22.8213V31.3708H121.655V22.8213H124.575ZM124.626 20V21.7783H121.604V20H124.626Z"
      fill={primaryColor}
    />
    <Path
      d="M89.0273 38.1047C90.2044 38.1047 91.1892 38.3669 91.9815 38.8913C92.7851 39.4042 93.385 40.0882 93.7812 40.9431C94.1773 41.7981 94.3754 42.7385 94.3754 43.7645C94.3754 44.7904 94.1773 45.7365 93.7812 46.6029C93.385 47.4692 92.7851 48.1646 91.9815 48.6889C91.1778 49.2133 90.1931 49.4755 89.0273 49.4755H83.3226V38.1047H89.0273ZM88.3481 47.0987C89.3555 47.0987 90.0912 46.8252 90.5553 46.278C91.0194 45.7194 91.2514 44.8816 91.2514 43.7645C91.2514 42.6473 91.0194 41.8209 90.5553 41.2851C90.0912 40.7494 89.3555 40.4815 88.3481 40.4815H86.3107V47.0987H88.3481Z"
      fill={primaryColor}
    />
    <Path
      d="M100.072 47.7827C100.491 47.7827 100.836 47.7029 101.108 47.5433C101.38 47.3837 101.578 47.1671 101.702 46.8936H104.538C104.368 47.7257 103.926 48.4097 103.213 48.9454C102.5 49.4812 101.431 49.7491 100.004 49.7491C98.3858 49.7491 97.1973 49.3159 96.4389 48.4496C95.6919 47.5832 95.3184 46.4946 95.3184 45.1837C95.3184 44.3287 95.4881 43.5593 95.8277 42.8753C96.1673 42.1914 96.6879 41.6499 97.3897 41.2509C98.0915 40.8519 98.963 40.6525 100.004 40.6525C101.612 40.6525 102.772 41.0856 103.485 41.952C104.209 42.8069 104.571 43.9013 104.571 45.235C104.571 45.5199 104.555 45.7593 104.521 45.9531H98.1537C98.2329 46.6143 98.431 47.0873 98.748 47.3723C99.0649 47.6459 99.5063 47.7827 100.072 47.7827ZM100.038 42.5846C99.495 42.5846 99.0705 42.7157 98.7649 42.9779C98.4706 43.2287 98.2782 43.6505 98.1877 44.2432H101.804C101.747 43.6961 101.578 43.2857 101.295 43.0121C101.012 42.7271 100.593 42.5846 100.038 42.5846Z"
      fill={primaryColor}
    />
    <Path
      d="M108.077 46.7226C108.111 47.0873 108.275 47.3894 108.569 47.6288C108.875 47.8682 109.288 47.9879 109.808 47.9879C110.691 47.9879 111.133 47.72 111.133 47.1842C111.133 46.9335 111.053 46.734 110.895 46.5858C110.748 46.4376 110.448 46.335 109.995 46.278L108.195 46.0728C107.369 45.9816 106.741 45.7194 106.311 45.2863C105.881 44.8417 105.666 44.2489 105.666 43.508C105.666 42.539 106.028 41.8209 106.752 41.3535C107.488 40.8861 108.461 40.6525 109.673 40.6525C110.906 40.6525 111.885 40.8804 112.61 41.3364C113.334 41.7924 113.736 42.4991 113.815 43.4567H111.167C111.076 42.7499 110.584 42.3965 109.689 42.3965C109.225 42.3965 108.88 42.4763 108.654 42.6359C108.439 42.7841 108.331 42.9836 108.331 43.2344C108.331 43.679 108.665 43.9354 109.333 44.0038L111.082 44.1748C112.078 44.2774 112.796 44.5453 113.238 44.9785C113.691 45.4003 113.917 46.0215 113.917 46.8423C113.917 47.7314 113.594 48.4382 112.949 48.9625C112.304 49.4869 111.24 49.7491 109.757 49.7491C108.309 49.7491 107.233 49.4641 106.532 48.8941C105.83 48.3242 105.462 47.6003 105.428 46.7226H108.077Z"
      fill={primaryColor}
    />
    <Path
      d="M118.142 40.926V49.4755H115.222V40.926H118.142ZM118.193 38.1047V39.883H115.171V38.1047H118.193Z"
      fill={primaryColor}
    />
    <Path
      d="M129.273 40.926V48.9796C129.273 50.3931 128.888 51.4305 128.119 52.0916C127.349 52.7528 126.081 53.0834 124.315 53.0834C122.855 53.0834 121.74 52.7813 120.971 52.1771C120.212 51.573 119.799 50.7807 119.731 49.8004H122.567C122.68 50.6439 123.314 51.0657 124.468 51.0657C125.113 51.0657 125.589 50.9346 125.894 50.6724C126.2 50.4216 126.353 50.0113 126.353 49.4413V47.9195C125.742 48.7858 124.774 49.219 123.45 49.219C122.125 49.219 121.135 48.8143 120.478 48.005C119.833 47.1956 119.511 46.1754 119.511 44.9443C119.511 43.6904 119.833 42.6644 120.478 41.8665C121.135 41.0685 122.125 40.6696 123.45 40.6696C124.774 40.6696 125.742 41.097 126.353 41.952V40.926H129.273ZM124.451 47.0645C125.142 47.0645 125.628 46.8993 125.911 46.5687C126.206 46.2381 126.353 45.7137 126.353 44.9956C126.353 44.2888 126.206 43.7702 125.911 43.4396C125.628 43.109 125.142 42.9437 124.451 42.9437C123.727 42.9437 123.206 43.1033 122.889 43.4225C122.584 43.7303 122.431 44.2546 122.431 44.9956C122.431 45.7365 122.584 46.2666 122.889 46.5858C123.206 46.905 123.727 47.0645 124.451 47.0645Z"
      fill={primaryColor}
    />
    <Path
      d="M136.933 40.6525C138.019 40.6525 138.823 40.9716 139.344 41.61C139.864 42.237 140.125 43.1261 140.125 44.2774V49.4755H137.205V44.7733C137.205 43.6106 136.712 43.0292 135.727 43.0292C134.494 43.0292 133.877 43.8386 133.877 45.4573V49.4755H130.957V40.926H133.877V42.5162C134.171 41.9235 134.573 41.4675 135.082 41.1483C135.592 40.8177 136.208 40.6525 136.933 40.6525Z"
      fill={primaryColor}
    />
    <Path
      d="M85.9033 63.8869C85.9485 64.4112 86.1523 64.8444 86.5145 65.1864C86.888 65.5169 87.4426 65.6822 88.1784 65.6822C89.5027 65.6822 90.1648 65.2491 90.1648 64.3827C90.1648 64.0179 90.0573 63.733 89.8422 63.5278C89.6272 63.3226 89.3046 63.1915 88.8745 63.1345L86.4126 62.8609C85.3826 62.7355 84.5903 62.3879 84.0357 61.8179C83.481 61.2479 83.2037 60.4842 83.2037 59.5266C83.2037 58.4209 83.6169 57.5432 84.4431 56.8934C85.2694 56.2436 86.4749 55.9188 88.0595 55.9188C89.7007 55.9188 90.9288 56.2607 91.7438 56.9447C92.5587 57.6287 93.0058 58.5178 93.0851 59.6121H90.1308C90.0743 59.1448 89.8705 58.78 89.5196 58.5178C89.1687 58.2442 88.682 58.1074 88.0595 58.1074C87.4822 58.1074 87.0295 58.2157 86.7012 58.4323C86.3843 58.6489 86.2259 58.9453 86.2259 59.3214C86.2259 59.9712 86.6277 60.3417 87.4313 60.4329L89.5875 60.6552C90.81 60.7692 91.7155 61.1168 92.3041 61.6982C92.8926 62.2796 93.1869 63.0889 93.1869 64.1262C93.1869 65.3118 92.7794 66.2351 91.9645 66.8963C91.1609 67.546 89.8818 67.8709 88.1274 67.8709C86.407 67.8709 85.1393 67.4947 84.3243 66.7424C83.5093 65.99 83.0679 65.0382 83 63.8869H85.9033Z"
      fill={primaryColor}
    />
    <Path
      d="M103.415 59.0308L100.715 67.5973C100.466 68.3953 100.183 69.0336 99.8664 69.5124C99.5495 70.0026 99.1194 70.373 98.5761 70.6238C98.0441 70.8746 97.348 71 96.4877 71H94.0429V68.6745H97.6932L98.1346 67.3408H96.284L93.5335 59.0308H96.5047L98.1516 63.9894L98.644 65.5796L99.0854 63.9894L100.647 59.0308H103.415Z"
      fill={primaryColor}
    />
    <Path
      d="M106.17 64.8273C106.204 65.1921 106.368 65.4942 106.662 65.7335C106.968 65.9729 107.381 66.0926 107.902 66.0926C108.784 66.0926 109.226 65.8247 109.226 65.289C109.226 65.0382 109.147 64.8387 108.988 64.6905C108.841 64.5423 108.541 64.4397 108.088 64.3827L106.289 64.1775C105.462 64.0863 104.834 63.8242 104.404 63.391C103.974 62.9464 103.759 62.3536 103.759 61.6127C103.759 60.6438 104.121 59.9256 104.846 59.4582C105.581 58.9909 106.555 58.7572 107.766 58.7572C109 58.7572 109.979 58.9852 110.703 59.4411C111.427 59.8971 111.829 60.6039 111.908 61.5614H109.26C109.169 60.8546 108.677 60.5013 107.783 60.5013C107.319 60.5013 106.973 60.5811 106.747 60.7407C106.532 60.8888 106.424 61.0883 106.424 61.3391C106.424 61.7837 106.758 62.0402 107.426 62.1086L109.175 62.2796C110.171 62.3821 110.89 62.65 111.331 63.0832C111.784 63.505 112.01 64.1262 112.01 64.947C112.01 65.8361 111.688 66.5429 111.043 67.0672C110.397 67.5916 109.333 67.8538 107.851 67.8538C106.402 67.8538 105.327 67.5688 104.625 66.9989C103.923 66.4289 103.555 65.705 103.521 64.8273H106.17Z"
      fill={primaryColor}
    />
    <Path
      d="M117.243 67.5802C115.987 67.5802 115.104 67.3066 114.594 66.7595C114.085 66.2123 113.83 65.3631 113.83 64.2117V61.2365H112.336V59.8686L114.068 59.0992L115.274 56.9618H116.751V59.0308H119.06V61.2365H116.751V65.2548H118.788V67.5802H117.243Z"
      fill={primaryColor}
    />
    <Path
      d="M124.429 65.8874C124.847 65.8874 125.193 65.8076 125.464 65.648C125.736 65.4884 125.934 65.2719 126.059 64.9983H128.894C128.724 65.8304 128.283 66.5144 127.57 67.0501C126.857 67.5859 125.787 67.8538 124.361 67.8538C122.742 67.8538 121.554 67.4206 120.795 66.5543C120.048 65.6879 119.675 64.5993 119.675 63.2884C119.675 62.4334 119.845 61.664 120.184 60.98C120.524 60.2961 121.044 59.7546 121.746 59.3556C122.448 58.9567 123.319 58.7572 124.361 58.7572C125.968 58.7572 127.128 59.1904 127.841 60.0567C128.566 60.9116 128.928 62.006 128.928 63.3397C128.928 63.6247 128.911 63.8641 128.877 64.0578H122.51C122.589 64.719 122.787 65.1921 123.104 65.4771C123.421 65.7506 123.863 65.8874 124.429 65.8874ZM124.395 60.6894C123.851 60.6894 123.427 60.8204 123.121 61.0826C122.827 61.3334 122.635 61.7552 122.544 62.3479H126.16C126.104 61.8008 125.934 61.3904 125.651 61.1168C125.368 60.8319 124.949 60.6894 124.395 60.6894Z"
      fill={primaryColor}
    />
    <Path
      d="M141.757 58.7572C142.844 58.7572 143.653 59.0764 144.185 59.7147C144.728 60.3417 145 61.2308 145 62.3821V67.5802H142.08V62.8267C142.08 61.6982 141.616 61.1339 140.688 61.1339C140.11 61.1339 139.686 61.3334 139.414 61.7324C139.143 62.12 139.007 62.7298 139.007 63.562V67.5802H136.137V62.8267C136.137 61.6982 135.673 61.1339 134.745 61.1339C134.168 61.1339 133.743 61.3334 133.472 61.7324C133.2 62.12 133.064 62.7298 133.064 63.562V67.5802H130.144V59.0308H133.064V60.4842C133.642 59.3328 134.604 58.7572 135.951 58.7572C136.664 58.7572 137.252 58.9225 137.716 59.2531C138.192 59.5722 138.537 60.0396 138.752 60.6552C139.329 59.3898 140.331 58.7572 141.757 58.7572Z"
      fill={primaryColor}
    />
  </SVG>
)

export { DesignSystemLogoWithBackground }
