import React, { ComponentType, FC } from 'react'

import { TimePicker } from '../TimePicker/TimePicker'
import { getPreparedTimeSlots, isSlotSelected } from './TimePickerGroup.util'
import { Container, TableCol, TableHeader, DefaultTableItem, TableItemEmpty } from './TimePickerGroup.styles'
import { TimePickerGroupDay, TimePickerGroupHeaderProps, TimePickerGroupProps } from './TImePickerGroup.types'

export const DefaultDayHeaderContent: FC<TimePickerGroupHeaderProps> = ({ day }) => {
  if (!day || !day.date) return null

  return <>{day.date.toLocaleString('fi-FI', { month: 'numeric', day: 'numeric' })}</>
}

const TimePickerGroupDayHeader: FC<{
  day: TimePickerGroupDay
  shouldDrawGap: boolean
  DayHeaderContent?: ComponentType<TimePickerGroupHeaderProps>
}> = ({ day, shouldDrawGap, DayHeaderContent = DefaultDayHeaderContent }) => {
  if (day.date && day.hasTimeSlots) {
    return (
      <TableHeader size="Four">
        <DayHeaderContent day={day} />
      </TableHeader>
    )
  }

  // Need to show user that there was a day skipped
  // Only one gap for several skipped days
  // No gap rendered for empty days in start or end of schedule
  if (shouldDrawGap) {
    return (
      <React.Fragment>
        <TableHeader size="Five">&nbsp;</TableHeader>
        <TableItemEmpty />
      </React.Fragment>
    )
  }

  return null
}

export const TimePickerGroup: React.FC<TimePickerGroupProps> = (props) => {
  const {
    earliestHour,
    latestHour,
    timeSlots,
    selected,
    onSelect,
    getHourFromDate,
    DayHeaderContent,
    TableItemComponent = DefaultTableItem,
    className,
  } = props

  const days = timeSlots.map((timeslot) => {
    return getPreparedTimeSlots(timeslot, earliestHour, latestHour, getHourFromDate)
  })

  return (
    <Container className={className}>
      {days.map((day, i) => {
        const hasDayBefore = i !== 0 || days.slice(0, i).some((d) => !!d.hasTimeSlots)
        const nextDayEmpty = days.length - 1 === i || days[i + 1].hasTimeSlots === false

        return (
          <TableCol key={'Col' + day.date}>
            <TimePickerGroupDayHeader
              day={day}
              shouldDrawGap={hasDayBefore && nextDayEmpty === false}
              DayHeaderContent={DayHeaderContent}
            />
            {day.slots.map((slot) => {
              const slotSelected = !slot.disabled && isSlotSelected(slot.fromDate, slot.toDate, selected)

              return (
                <TableItemComponent
                  key={slot.from + '-' + slot.to}
                  hourSpan={slot.to - slot.from}
                  selected={slotSelected}
                  disabled={slot.disabled}
                >
                  <TimePicker
                    from={slot.from}
                    to={slot.to}
                    fromDate={slot.fromDate}
                    toDate={slot.toDate}
                    disabled={slot.disabled}
                    selected={slotSelected}
                    onSelect={onSelect}
                  />
                </TableItemComponent>
              )
            })}
          </TableCol>
        )
      })}
    </Container>
  )
}
