/* eslint-disable import/named */
import React, { HTMLAttributes } from 'react'
import styled, { css, CSSObject } from 'styled-components'
import { Theme } from '@postidigital/posti-theme'
import PropTypes, { Validator } from 'prop-types'

interface CommonProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  size: keyof typeof Size
}

interface LeftColumnProps extends CommonProps {
  leftContent?: JSX.Element | string
  alignLeftContentWithTitle?: boolean
  subtitle?: string | JSX.Element
  body?: JSX.Element | string
}

interface TopProps extends CommonProps {
  subtitle?: string | JSX.Element
  topRightContent?: JSX.Element | string
}

interface MainProps extends CommonProps {
  boldTitle?: boolean
  title: string | JSX.Element
  rightContent?: JSX.Element | string
  topRightContent?: JSX.Element | string
  body?: JSX.Element | string
  subtitle?: string | JSX.Element
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void
}

interface BottomProps extends CommonProps {
  body?: JSX.Element | string
}

interface ContainerProps extends CommonProps {
  isClickable: boolean
}

export type ListItemProps = TopProps & MainProps & BottomProps & LeftColumnProps

export enum Size {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

const titleConfig: { [key in Size]: CSSObject } = {
  [Size.sm]: {
    fontSize: `${Theme.fontSize.text.xs}rem`,
    padding: `${Theme.spacing.xxs}rem 0`,
  },
  [Size.md]: {
    fontSize: `${Theme.fontSize.text.md}rem`,
    padding: `${Theme.spacing.xs}rem 0`,
  },
  [Size.lg]: {
    fontSize: `${Theme.fontSize.text.lg}rem`,
    padding: `${Theme.spacing.sm}rem 0`,
  },
}

const bodyConfig: { [key in Size]: CSSObject } = {
  [Size.sm]: {
    fontSize: `${Theme.fontSize.text.xs}rem`,
  },
  [Size.md]: {
    fontSize: `${Theme.fontSize.text.sm}rem`,
  },
  [Size.lg]: {
    fontSize: `${Theme.fontSize.text.md}rem`,
  },
}

const Container = styled.div<ContainerProps>(
  ({ size }) => titleConfig[size],
  ({ theme, isClickable }) => css`
    display: flex;
    position: relative;
    flex-direction: row;

    ${isClickable &&
    `
      cursor: pointer;
      outline: none;
      
      &:hover,
      &:focus:not(:hover) {
        background-color: ${theme.color.neutralOnPressGray};
      }
    `}
  `
)

const MiddleColumn = styled.div<{
  body: JSX.Element | string
  subtitle: string | JSX.Element
  leftContent: JSX.Element | string
}>(
  ({ theme, body, subtitle, leftContent }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding-left: ${theme.spacing.xs}rem;

    ${leftContent
      ? `
      width: 90%;
      @media screen and (max-width: ${theme.breakpoint.md}) {
        padding-left: ${theme.spacing.xs / 2}rem;
      }
      `
      : `
      width: 100%;
      `}

    ${!body &&
    !subtitle &&
    `
      justify-content: center;
  `}
  `
)

const SubtitleContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-grow: 2;
    font-size: ${theme.fontSize.text.xs}rem;
    line-height: ${theme.lineHeight.text.xs}rem;
  `
)

const RightContent = styled.div(
  ({ theme }) => css`
    display: flex;
    font-size: ${theme.fontSize.text.xs}rem;
    padding-right: ${theme.spacing.xs}rem;
    @media screen and (max-width: ${theme.breakpoint.md}) {
      padding-right: ${theme.spacing.sm}rem;
    }
  `
)

const TitleContainer = styled.div<{ size: keyof typeof Size; boldTitle: boolean; rightContent: JSX.Element | string }>(
  ({ size }) => titleConfig[size],
  ({ theme, boldTitle, rightContent }) => css`
    display: inline;
    font-size: ${theme.lineHeight.text.sm}rem;
    line-height: ${theme.lineHeight.text.lg}rem;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(90% - 1.5rem);
    ${rightContent &&
    `
      padding-right: ${theme.spacing.md}rem;
  `}
    ${boldTitle &&
    `
      font-weight: ${theme.fontWeight.semiBold};
  `}
  `
)

const RightContentHolder = styled.div<{ topRightContent: JSX.Element | string; body: any }>(
  ({ theme, topRightContent, body }) => css`
    padding-right: ${theme.spacing.xs}rem;
    position: absolute;
    right: 0;
    ${topRightContent &&
    `
      top: ${body ? theme.spacing.xxs : 0}rem;
    `}
  `
)

const SimpleRow = styled.div(
  () => css`
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    position: relative;
  `
)

const CenterRow = styled.div<{ subtitle: string | JSX.Element; body: JSX.Element | string }>(
  ({ subtitle, body }) => css`
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    position: relative;

    ${!body &&
    !subtitle &&
    `
    top: 0;
  `}
  `
)

const BodyContainer = styled.div<{ size: keyof typeof Size }>(
  ({ size }) => bodyConfig[size],
  () => css`
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
    max-width: calc(90% - 1.5rem);
  `
)

const LeftContainer = styled.div<LeftColumnProps>(
  ({ size }) => titleConfig[size],
  ({ theme, alignLeftContentWithTitle, subtitle, size, body }) => css`
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    padding: 0;
    padding-left: ${theme.spacing.sm}rem;
    @media screen and (max-width: ${theme.breakpoint.md}) {
      padding-left: ${theme.spacing.xs / 2}rem !important;
    }
    position: relative;
    top: ${alignLeftContentWithTitle && subtitle && !body ? 1.1875 : 0}rem;

    ${alignLeftContentWithTitle &&
    subtitle &&
    body &&
    `
      justify-content: center;
      padding-bottom: ${theme.spacing[Size[size]] / 2}rem;
      top: ${theme.spacing[Size[size]] / 8}rem;
  `}
  `
)

/**
 * @deprecated Current version of component is deprecated, new version is coming soon
 */

const ListItem: React.FC<ListItemProps> = (props) => {
  const { leftContent, rightContent, body, boldTitle, topRightContent, title, subtitle, size, ...rest } = props
  const isClickable = rest.onClick !== undefined || rest.onKeyPress !== undefined

  return (
    <Container size={size} {...rest} isClickable={isClickable} tabIndex={isClickable ? 0 : undefined}>
      {leftContent && (
        <LeftContainer subtitle={subtitle} body={body} size={size}>
          {leftContent}
        </LeftContainer>
      )}

      <MiddleColumn body={body} subtitle={subtitle} leftContent={leftContent}>
        {(topRightContent || subtitle) && (
          <SimpleRow>
            <SubtitleContainer>{subtitle}</SubtitleContainer>
            {topRightContent && <RightContent>{topRightContent}</RightContent>}
          </SimpleRow>
        )}

        <CenterRow subtitle={subtitle} body={body}>
          <TitleContainer boldTitle={boldTitle} size={size} rightContent={rightContent}>
            {title}
          </TitleContainer>
          {rightContent && (
            <RightContentHolder topRightContent={topRightContent} body={body}>
              {rightContent}
            </RightContentHolder>
          )}
        </CenterRow>

        {body && <BodyContainer size={size}>{body}</BodyContainer>}
      </MiddleColumn>
    </Container>
  )
}

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(Size)) as Validator<Size>,
  leftContent: PropTypes.element,
  body: PropTypes.element,
  subtitle: PropTypes.string,
  boldTitle: PropTypes.bool,
  topRightContent: PropTypes.element,
  alignLeftContentWithTitle: PropTypes.bool,
  rightContent: PropTypes.element,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
}

ListItem.defaultProps = {
  size: Size.md,
  boldTitle: true,
  alignLeftContentWithTitle: true,
}

export default ListItem
