import React from 'react'

import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

const PostiPrivateLockersIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props} fill="none">
    <Path
      d="M12 4a5.37 5.37 0 00-5.4 5.33v1.56c0 .35.31.63.67.58l1.13-.14V9.21A3.58 3.58 0 0112 5.66c1.99 0 3.6 1.59 3.6 3.55v2.12l1.13.14a.6.6 0 00.67-.58V9.33A5.37 5.37 0 0012 4z"
      fill={color}
    />
    <Path
      d="M13.8 10.22H3v4.45h10.8v-4.45zm.9 0v7.11H21v-7.1h-6.3zm6.3 8h-6.3V20H21v-1.78zM3 20h10.8v-4.44H3V20zm13.5-8v3.56h.9v-.9h.9c.5 0 .9-.4.9-.88v-.9a.9.9 0 00-.9-.88h-1.8zm1.8.89v.89h-.9v-.9h.9z"
      fill={color}
    />
  </SVG>
)

PostiPrivateLockersIcon.propTypes = defaultIconPropTypes

PostiPrivateLockersIcon.defaultProps = defaultPictographIconProps

export { PostiPrivateLockersIcon }
