import React from 'react'

const Rating5Inactive: React.FC = () => (
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 10.3131C0 5.27304 2.79089 0 11.6353 0C20.4796 0 23.2705 5.27304 23.2705 10.3131C23.2705 15.3535 20.4796 20.6261 11.6353 20.6261C2.79089 20.6265 0 15.3922 0 10.3131Z"
      fill="#C2C9D1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0277 7.54363C15.6094 7.35945 15.121 7.54926 14.9368 7.96759C14.7526 8.38591 14.2642 8.57573 13.8459 8.39155C13.4275 8.20737 13.2377 7.71895 13.4219 7.30062C13.9744 6.04565 15.4397 5.47621 16.6947 6.02874C17.3677 6.32507 17.844 6.88524 18.0548 7.53162C18.1966 7.96616 17.9592 8.43334 17.5247 8.57509C17.0901 8.71684 16.623 8.47948 16.4812 8.04494C16.4102 7.82723 16.252 7.64236 16.0277 7.54363ZM7.92733 7.51325C7.48583 7.39496 7.03202 7.65696 6.91372 8.09846C6.79542 8.53996 6.34161 8.80197 5.90011 8.68367C5.45861 8.56537 5.1966 8.11156 5.3149 7.67006C5.6698 6.34556 7.03123 5.55954 8.35573 5.91444C9.06608 6.10478 9.62209 6.58586 9.92895 7.19257C10.1352 7.60045 9.97183 8.09833 9.56396 8.30462C9.15609 8.51092 8.65821 8.3475 8.45191 7.93963C8.34856 7.73528 8.16401 7.57667 7.92733 7.51325Z"
      fill="white"
    />
    <path
      d="M16.8615 12.1472C16.1255 13.9197 14.0496 15.7463 11.6324 15.7463C9.21516 15.7463 7.13928 13.9197 6.40326 12.1472C6.37311 12.0746 6.37675 12.0249 6.38623 11.9939C6.39616 11.9614 6.41843 11.9301 6.45577 11.905C6.53233 11.8537 6.66591 11.8357 6.79864 11.9176C7.80723 12.5405 9.46753 13.2869 11.6324 13.2869C13.7972 13.2869 15.4575 12.5405 16.4661 11.9176C16.5988 11.8357 16.7324 11.8537 16.809 11.905C16.8463 11.9301 16.8686 11.9614 16.8785 11.9939C16.888 12.0249 16.8916 12.0746 16.8615 12.1472Z"
      fill="white"
      stroke="white"
      strokeWidth="0.528875"
    />
  </svg>
)

export default Rating5Inactive
